import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";

import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";

import { Card } from "@material-ui/core";
import * as adminAction from "../../../actions/authAction";
import "./style.css";

const ImportOrders = (props) => {
  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
    importFailOrders: state.adminReducer.importFailOrders,
    outOfStockOrder: state.adminReducer.outOfStockOrder,
  }));

  const [order, setOrder] = useState(null);
  const [itemMismatchError, setItemMismatchError] = useState(null);
  const [outOfStockOrder, setOutOfStockOrder] = useState(null);

  //Todo error list orders
  useEffect(() => {
    setItemMismatchError(adminReducers.importFailOrders);
  }, [adminReducers.importFailOrders]);

  useEffect(() => {
    setOutOfStockOrder(adminReducers.outOfStockOrder);
  }, [adminReducers.outOfStockOrder]);

  const handleForce = (data, fileInfo) => {
    setOrder(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const ImportOrders = () => {
    props.actions.adminAction.importData(
      order,
      adminReducers.selectedPage.FbPageId
    );
  };

  return (
    <Card className="d-flex p-2 align-items-center gap-20">
      <CSVReader
        cssClass="react-csv-input"
        onFileLoaded={handleForce}
        parserOptions={papaparseOptions}
      />
      {order != null && (
        <Button variant="contained" color="primary" onClick={ImportOrders}>
          {" "}
          Import keywords{" "}
        </Button>
      )}
      {itemMismatchError !== null && itemMismatchError.length > 0 && (
        <div>
          <CSVLink data={itemMismatchError} className="my-5 mt-2">
            {" "}
            Fail to Import{" "}
          </CSVLink>
        </div>
      )}
      {outOfStockOrder !== null && outOfStockOrder.length > 0 && (
        <div>
          <CSVLink data={outOfStockOrder} className="my-5 mt-2">
            Download Out of Stock{" "}
          </CSVLink>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ImportOrders);
