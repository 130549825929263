import React, {Component} from 'react';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <h2>PRIVACY POLICY</h2>
                <p><strong> thevelocitee </strong> collects business and personal information for the purpose of serving
                    the needs of the subscribers and users of its suite of IT solutions. This is the Privacy Policy of
                    thevelocitee that sets out how we collect, use, disclose and protect any Personal Data (as defined
                    below) that you give to us. This Privacy Policy applies to all subscribers and users of
                    thevelocitee, and is designed to meet the requirements of the Personal Data Protection Act (Act 26
                    of 2012) (the “PDPA“).</p>
                <p>Please read this Privacy Policy before submitting any information requested by us, or signing up for
                    any goods, services or activities offered by us, so that you are aware of and understand the
                    purposes for which we collect, use and/or disclose your Personal Data.</p>
                <p>This Privacy Policy complements and supplements, but does not limit or replace, the purposes for
                    which you provide thevelocitee with your Personal Data which may be expressly stated in any form for
                    submission of Personal Data to thevelocitee.</p>
                <p>thevelocitee may from time to time review and update this Privacy Policy to ensure that it is
                    consistent with our future developments and/or any changes in legal or regulatory requirements under
                    the laws of Singapore. Subject to your rights at law, you agree to be bound by the prevailing terms
                    of this Privacy Policy as updated from time to time on our website.</p>

                <h2>1. Personal Data</h2>
                <p>In this Privacy Policy, “Personal Data” refers to any data, whether true or not, about an individual
                    and/or company, who can be identified (a) from the data; or (b) from that data and other information
                    to which we have or are likely to have access, including data in our records as may be updated from
                    time to time.</p>

                <h2>2. Collection of Personal Data</h2>
                <p>It is a continuing condition of your use of thevelocitee’s solutions as well as your access to and
                    use of thevelocitee’s website that your consent to the collection, storing, accessing, reviewing,
                    use, disclosure and/or processing of your Personal Data about you, whether obtained from your or
                    other sources, by us and all other authorised persons and entities for the purposes listed in
                    paragraph 3 below.</p>
                <h5>We may collect your Personal Data in the following ways:</h5>
                <ul>
                    <li>when you sign up for the solution</li>
                    <li>when you use the solution</li>
                    <li>when you contact our support team</li>
                    <li>when you register to attend events organised by thevelocitee</li>
                    <li>when you attend a thevelocitee event and you provide us with your business card or
                        identification card for verification purposes; and
                    </li>
                    <li>when you visit our website</li>
                </ul>

                <p>Where applicable, express written consent will be sought from you regarding collecting your Personal
                    Data (for example, by signing a form or checking against a box under the registration form, when you
                    sign up for a thevelocitee solution).</p>
                <p>If you disclose the Personal Data of third parties to us, you represent and warrant that you are
                    authorised to provide their Personal Data to us and that you have obtained their consent to the
                    collection, use, disclosure and/or processing of their Personal Data for the below-stated purposes
                    in accordance with this Privacy Policy.</p>
                <p>Examples of your Personal Data which may be so collected, used, disclosed and/or processed for
                    thevelocitee’s purposes include, but is not limited to, the following</p>
                <p>your name, identification number, telephone number, mobile number, email address, mailing address,
                    financial details, and any other information relating to you which you have provided to us in any
                    forms you may have submitted to us or in other forms of interaction with you</p>
                <p>your company name, company registration number, telephone number, financial details</p>
                <p>information about your usage of and interaction with thevelocitee’s website, location data,
                    originating domain name of a user’s internet service provider, statistics on page views, cookies, IP
                    addresses and browser, which we use for administration purposes and statistical analysis.</p>
                <p>Some of this Personal Data will be aggregated, so thevelocitee is not able to identify you
                    individually.</p>
                <p>We may also collect data about you from other sources, to help us improve and further personalise our
                    services to you. We may use this non-personally identifiable aggregated data for the following
                    purposes</p>
                <p>to enable the provision of analytical information regarding the uses/benefits of the solution</p>
                <p>to assist us to analyse and better understand how our subscribers are using the solution</p>
                <p>to develop strategy to further improve the solution.</p>
                <p>Whilst we will take reasonable steps to accurately record your Personal Data, we require that you
                    provide us with accurate and complete Personal Data, and update such Personal Data provide to us
                    from time to time. Failure on your part to do so may result in our inability to provide you
                    information regarding our enhanced solutions.</p>

                <h2>3. Purposes for Collection and Use of Your Personal Data</h2>
                <p>thevelocitee may collect and use your personal data for the following purposes and/or any other
                    administrative or operational purposes</p>
                <ul>
                    <li><strong>thevelocitee may collect and use your personal data for the following purposes and/or
                        any other administrative or operational purposes</strong></li>
                </ul>
                <ul>
                    <li>to validate and verify your identity</li>
                    <li>to manage and administer the solution</li>
                    <li>to keep you updated about new or upgraded features and functionalities in the solution</li>
                    <li>to provide you with marketing material that you have agreed to receive</li>
                    <li>to plan for any marketing activities related to the solution</li>
                    <li>to schedule and/or plan training for/of the solution</li>
                    <li>to work on the resolution of support/technical issues or any other issues related to the
                        solution
                    </li>
                    <li>to collaborate and/or communicate with you</li>
                    <li>to customise the website based on your feedback; and</li>
                    <li>managing your relationship as a subscriber or user of thevelocitee’s solution.</li>
                </ul>
                <p>The financial transactions on our website are handled through our payment services provider,
                    Mastercard. You can review Mastercard Privacy Policy at
                    https://www.mastercard.com/gateway/about.html. We will share information with Mastercard only to the
                    extent necessary for the purposes of processing payments you make via our website, refunding such
                    payments and dealing with complaints and queries relating to such payments and refunds.</p>

                <h2>4. Disclosure of Personal Data</h2>
                <p>Subject to the provisions of any applicable law, your Personal Data may be disclosed, for the
                    purposes listed in paragraph 3 above.</p>
                <p>thevelocitee will not keep Personal Data for longer than is necessary and will take reasonable steps
                    to destroy or permanently deidentify your Personal Data if it is no longer needed.</p>

                <h2>5. Security of Personal Data</h2>
                <p>thevelocitee is committed to ensure that your information is secure, and will take reasonable steps
                    (including putting in place suitable physical, electronic and operational procedures) to safeguard
                    your Personal Data we hold from misuse, loss, and unauthorised access, modification or disclosure.
                    Access to your Personal Data will be granted on a need-to-know basis.</p>

                <h2>6. Use of Cookies</h2>
                <p>Cookies are small files which are stored on your computer. It will get your permission to be placed
                    on your computer’s hard drive. Once you agreed, the file is added and the cookie helps analyse web
                    traffic or lets you know when you visit a particular site. The cookie holds a modest amount of data,
                    which can be accessed either by the web server or your computer.</p>
                <p>Traffic log cookies are used to identify which pages are being used. This helps us analyse data about
                    web page traffic and improve our website in order to tailor it to your needs. We only use this
                    information for statistical analysis purposes and will then be removed from the system.</p>
                <p>Cookies enable us to provide you with a better website, allowing us to monitor which pages you find
                    useful and which you do not. The cookie will not give us access to your computer or any information
                    about you, other than the data you choose to share with us.</p>
                <p>You have the option to accept or decline cookies. Most web browsers automatically accept cookies. You
                    can usually modify your browser setting to decline cookies if you prefer, but it may prevent you
                    from taking full advantage of the website.</p>

                <h2>7. Contacting Us for Withdrawal of Consent, Access and Correction of your Personal Data</h2>
                <p>If you:</p>

                <ul>
                    <li>have any questions or feedback relating to your Personal Data or this Privacy Policy</li>
                    <li>would like to withdraw your consent to any collection, use, or disclosure of your Personal Data
                        as set out in this Privacy Policy; and/or
                    </li>
                    <li>would like to obtain access and make corrections to your Personal Data records</li>
                </ul>

                <p>please do so by contacting thevelocitee at hello@thevelocitee.com</p>
                <p>thevelocitee reserves the right to charge a fee (representing its costs in administering your
                    request) for supplying such information and to refuse requests which, in our opinion, occur with
                    unreasonable frequency.</p>
                <p>thevelocitee will also, where you have requested that thevelocitee correct an error or omission in
                    the Personal Data about you that is kept with thevelocitee, correct such data as soon as practicable
                    and send the corrected Personal Data to every organisation to which the Personal Data was sent
                    before it had been corrected, unless that organisation does not need the corrected Personal Data for
                    any legal or business purpose.</p>
                <p>thevelocitee may however choose not to provide you with access to or correct such information, in
                    accordance with the exceptions under the PDPA. This would include cases where</p>


                <ul>
                    <li>thevelocitee is satisfied on reasonable grounds that the correction should not be made</li>
                    <li>the request for access is frivolous or vexatious or the information requested is trivial</li>
                    <li>the Personal Data is related to a prosecution and all the proceedings related to the prosecution
                        have not been completed
                    </li>
                    <li>the Personal Data, if disclosed, would reveal confidential commercial information that could, in
                        the opinion of a reasonable person, harm the competitive position of the organisation; and
                    </li>
                    <li>the Personal Data was collected, used or disclosed for the purpose of an investigation and
                        associated proceedings and appeals have not been completed.
                    </li>
                </ul>

                <h2>8. Transborder Data Flows</h2>
                <p>If your Personal Data is transferred to a country or territory outside Singapore, we will ensure that
                    the recipients thereof provide a standard of protection to your Personal Data so transferred that is
                    comparable to that which is provided therein.</p>

                <h2>9. Enquiries and Complaints</h2>
                <p>If you have any questions or complaints relating to your Personal Data, please contact our designated
                    Data Protection Officer at dpo@thevelocitee.com, with the Subject:Privacy Policy.</p>
                <p>If your Personal Data is provided to thevelocitee by a third party (e.g. a referrer), please contact
                    that organisation or individual to make such query, complaint, access and correction request.</p>

                <h2>10. Governing Law</h2>
                <p>This Privacy Policy and your use of thevelocitee‘s website shall be governed in all respects by the
                    laws of Singapore. For more information on the PDPA, please visit
                    https://www.pdpc.gov.sg/Overview-of-PDPA/The-Legislation</p>
            </div>
        );
    }
}

export default PrivacyPolicy;
