import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import * as adminAction from "../../../actions/authAction";
import "./style.css";
import { TextField } from "@material-ui/core";

let moment = require("moment-timezone");

const SearchOrder = (props) => {
  const startDayDateTime = moment()
    .tz("Asia/Singapore")
    .startOf("day")
    .format();
  const endDayDateTime = moment().tz("Asia/Singapore").endOf("day").format();
  const [search, setSearch] = useState({
    name: "",
    fbid: "",
    email: "",
    fromDate: new Date(startDayDateTime),
    toDate: new Date(endDayDateTime),
  });
  const [timeOut, setTimeOut] = useState(null);

  const adminReducers = useSelector((state) => ({
    search: state.adminReducer.search,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (
      adminReducers.selectedPage &&
      adminReducers.selectedPage !== null &&
      adminReducers.selectedPage !== undefined
    )
      props.actions.adminAction.getUserPointSummaryReport(
        {
          name: "",
          fbid: "",
          email: "",
          fromDate: "",
          toDate: "",
        },
        adminReducers.selectedPage.FbPageId
      );
  }, [adminReducers.selectedPage]);

  const handleChange = (event) => {
    clearTimeout(timeOut);
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    setTimeOut(
      setTimeout(() => {
        props.actions.adminAction.getUserPointSummaryReport(
          commonData,
          adminReducers.selectedPage.FbPageId.trim()
        );
      }, 1000)
    );
    return setSearch(commonData);
  };

  const onChangeStartTime = (date) => {
    const commonData = { ...search };
    commonData.fromDate = date;
    return setSearch(commonData);
  };

  const onChangeEndTime = (date) => {
    const commonData = { ...search };
    commonData.toDate = date;
    return setSearch(commonData);
  };

  const searchButton = () => {
    props.actions.adminAction.getUserPointSummaryReport(
      {
        name: "",
        fbid: "",
        email: "",
        fromDate: search.fromDate,
        toDate: search.toDate,
      },
      adminReducers.selectedPage.FbPageId.trim()
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="d-flex align-items-baseline justify-content-between filter">
        <KeyboardDatePicker
          className="filter-box"
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
          format="dd/MM/yyyy"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardTimePicker
          className="filter-box"
          margin="normal"
          id="time-picker"
          label="Start Time"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />

        <KeyboardDatePicker
          className="filter-box"
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
          format="dd/MM/yyyy"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardTimePicker
          className="filter-box"
          margin="normal"
          id="time-picker"
          label="End Time"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />

        <Button
          className="but_width"
          variant="contained"
          color="primary"
          onClick={searchButton}
        >
          <SearchIcon />
        </Button>

        <TextField
          className="filter-box"
          variant="outlined"
          size="small"
          label="Name"
          name="name"
          onChange={handleChange}
          value={search.name}
        />

        <TextField
          className="filter-box"
          variant="outlined"
          size="small"
          label="FbId"
          name="fbid"
          onChange={handleChange}
          value={search.fbid}
        />

        <TextField
          className="filter-box"
          variant="outlined"
          size="small"
          label="Email"
          name="email"
          onChange={handleChange}
          value={search.email}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SearchOrder);
