import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from "redux";
import * as adminAction from "../../actions/authAction";
import SuccessLoader from '../SuccessLoader/index';

const CheckoutCallBack = (props) => {

    return (
        <div>
            <center><h1 className="mt-5">your payment is successful</h1></center>
            <SuccessLoader/>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(CheckoutCallBack);
