import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export const EditGroup = (props) => {
  const [keyword, setKeyword] = useState(props.editGroup);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newKeywordDetail = { ...keyword };
    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleSubmitClose = (keyword) => {
    props.handleEditSubmitClose(keyword);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle id="form-dialog-title">Edit Campaign</DialogTitle>
          <DialogContent style={{ width: "350px" }}>
            {/* <div className="d-flex flex-row"> */}
            <TextValidator
              label="Name"
              name="name"
              value={keyword.name}
              validators={["required"]}
              onChange={handleChange}
              errorMessages={["this field is required"]}
              variant="outlined"
              fullWidth
              size="small"
            />
            {/* <span className="m-2" /> */}
            {/* </div> */}
            {/* <br /> */}
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default EditGroup;
