import React, {useState, useEffect} from 'react';
import Iframe from 'react-iframe';
import './style.css'

const Support = () => {

    const [width, setWidth] = useState('0px');

    useEffect(() => {
        setWidth((window.innerHeight - 160) + "px");
    }, []);

    return (
        <div className="IFrameDisplay">
            <Iframe
                url="https://ordrstartr-frontend.vercel.app/"
                id="myId"
                width="100%"
                height={width}
                display="initial"
                position="relative"/>
        </div>
    )

};

export default Support;
