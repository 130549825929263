import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Loader from "../Loader";
import Header from "./header";
import Footer from "./footer";
import { connect, useSelector } from "react-redux";
import * as customerAction from "../../actions/customerAction";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import SecurityIcon from "@material-ui/icons/Security";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    marginTop: "28px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProSubscription = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({
    FullName: "",
    CompanyName: "",
    Email: "",
    Phone: "",
    Address: "",
    CompanyURL: "",
    FacebookURL: "",
    FacebookID: "",
    DateOfBirth: new Date(),
    CEODateOfBirth: new Date(),
    CFODateOfBirth: new Date(),
    IdType: "National ID",
    IdNumber: "",
    IDIssuingCountry: "Singapore",
    CountryOfRegistration: "Singapore",
    OfficialBusinessName: "",
    StreetAddress: "",
    City: "",
    PostalCode: "",
    YearsInBusiness: "",
    BusinessIncorporationNumber: "",
    TaxNumber: "",
    BusinessPhoneNumber: "",
    DescriptionOfBusiness: "",
    AnnualRevenue: "",
    AverageTransaction: "",
    CEOFullName: "",
    CEOResidentialAddress: "",
    CEOIDIssuingCountry: "Singapore",
    CFOFullName: "",
    CFOResidentialAddress: "",
    CFOIDIssuingCountry: "Singapore",
    Type: "pro",
    RapydType: "Collections",
    EntityType: "Private",
    Industry: "E-Commerce - Digital Goods",
    ServicesProvided: "Veterinary services 742 | Veterinary Services",
    CompanyInfo1: false,
    CompanyInfo2: false,
    CryptoCurrencyActivities: "true",
    GamblingActivities: "true",
    StakeInTheCompany: "0",
    PEPs: "true",
    PastFive: "true",
    ProofOfIdentity: null,
    PrimaryAuthorizedUserProofOfAddress: null,
    CompanyIncorporationDocuments: null,
    CapitalTableOrganizationalChart: null,
    PleaseUploadYourBankStatement: null,
    ProofOfCompanyAddress: null,
    CEODetails: null,
    CFODetails: null,
    RapydGrabPay: false,
    RapydCreditCard: false,
    RapydPaynow: false,
  });

  const [countries, setCountries] = useState([
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ]);

  const [entityType, setEntityType] = useState([
    "Private",
    "Public-Listed",
    "Trust",
    "Government",
    "Association",
    "Charity or Non-profit",
    "Sole proprietor",
    "Partnership",
  ]);

  const [industry, setIndustry] = useState([
    "E-Commerce - Digital Goods",
    "E-Commerce - Marketplace",
    "E-Commerce - Retail, Education, Engineering",
    "Food & Beverage",
    "Financial Services (including Insurance, Fintech, FX)",
    "Food Delivery",
    "Network Marketing",
    "Online Travel Agencies (OTA)",
    "Others, Real estate",
    "Ride-hailing",
    "Technology",
    "Telco",
    "Video Streaming",
  ]);

  const [serviceProvided, setServiceProvided] = useState([
    "Veterinary services 742 | Veterinary Services",
    "Landscaping services 780 | Horticultural Services, Landscaping Services",
    "General Contractors 1520 | General Contractors-Residential and Commercial",
    "A/C and heating contractors 1711 | Air Conditioning Contractors - Sales and Installation, Heating Contractors - Sales, Service, Installation",
    "Printing and publishing 2741 | Miscellaneous Publishing and Printing",
    "Transportation 4789 | Transportation Services, Not elsewhere classified)",
    "Telecom equipment 4812 | Telecommunications Equipment including telephones sales",
    "Telecom services 4814 | Fax services, Telecommunication Services",
    "Money orders 4829 | Money Orders - Wire Transfer",
    "Utilities 4900 | Electric, Gas, Sanitary and Water Utilities",
    "Retail 5045 | Computers, Computer Peripheral Equipment, Software",
    "Retail 5399 | Misc. General Merchandise",
    "Retail 5611 | Men’s and Boy’s Clothing and Accessories Stores",
    "Retail 5712 | Furniture, Home Furnishings, and Equipment Stores, Except Appliances",
    "Retail 5734 | Computer Software Stores",
    "Retail 5965 | Direct Marketing - Catalog and Catalog and Retail Merchant",
    "Retail 7298 | Health and Beauty Shops",
    "Medical devices 5047 | Medical, Dental Ophthaimic, Hospital Equipment and Supplies",
    "Precious Stones and Metals, Watches and Jewelry 5094 | Precious Stones and Metals, Watches and Jewelry",
    "Supplements or nutraceuticals 5122 | Drugs, Drug Proprietors, and Druggist’s Sundries",
    "Auto parts and accessories 5533 | Automotive Parts, Accessories Stores",
    "Car sales 5599 | Miscellaneous Auto Dealers",
    "Home electronics 5732 | Electronic Sales",
    "Caterers 5811 | Caterers",
    "Other food and dining 5812 | Eating places and Restaurants",
    "Food and drink 5814 | Fast Food Restaurants",
    "Books 5815 | Media, Books, Movies, Music",
    "Games 5816 | Games",
    "Apps 5817 | Applications (Exclude Games)",
    "Digital Products 5818 | Large Digital Goods",
    "Pharmacies or pharmaceuticals 5912 | Drug Stores and Pharmacies",
    "Jewelry Stores, Watches, Clocks, and Silverware Stores 5944 | Watch, Clock, Jewelry, and Silverware Stores",
    "Telemarketing 5966 | Direct Marketing - Outbound Telemarketing Merchant",
    "Lead Generation 5969 | Direct Marketing - Not Elsewhere Classified",
    "Tobacco or cigars 5993 | Cigar Stores and Strands",
    "Grocery stores 5999 | Miscellaneous and Specialty Retail Stores",
    "Virtual currencies 6051 | Non-Financial Institutions - Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
    "Security brokers or dealers 6211 | Security Brokers/Dealers",
    "Insurance 6300 | Insurance Sales, Underwriting, and Premiums",
    "Travel and lodging 7011 | Lodging - Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
    "Laundry or cleaning services 7210 | Laundry, Cleaning, and Garment Services",
    "Salons or barbers 7230 | Barber and Beauty Shops",
    "Dating services 7273 | Dating and Escort Services",
    "Debt reduction services 7277 | Counselling Service - Debt, Marriage, Personal",
    "Massage Parlors 7297 | Massage Parlors",
    "Credit counselling or credit repair 7321 | Consumers Credit Reporting Agencies",
    "Photography studios 7333 | Commercial Photography, Art and Graphics",
    "Assisted living 7361 | Employment Agencies, Temporary Help Services",
    "Computer repair 7379 | Computer Maintenance and Repair Services, Not Elsewhere Classified",
    "Consulting 7392 | Management, Consulting, and Public Relations Services",
    "Car rentals 7512 | Car Rental Companies (Not Listed Below)",
    "Auto services 7538 | Automotive Service Shops",
    "Musicians, bands, or orchestras 7929 | Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)",
    "Event ticketing 7991 | Tourists Attractions and Exhibits",
    "Betting or fantasy sports 7995 | Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)",
    "Recreational camps 7996 | Amusement Parks, Carnivals, Circuses, Fortune Tellers",
    "Country clubs 7997 | Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses",
    "Entertainment and recreation 7999 | Recreation Services (Not Elsewhere Classified)",
    "Doctors and physicians 8011 | Doctors and Physicians (Not Elsewhere Classified)",
    "Dentists and orthodontists 8043 | Opticians, Opticians Goods and Eyeglasses",
    "Nursing or personal care facilities 8050 | Nursing or personal care facilities",
    "Hospitals 8062 | Hospitals",
    "Medical Organizations 8071 | Medical and Dental Laboratories",
    "Medical services 8099 | Medical Services and Health Practitioners (Not Elsewhere Classified)",
    "Attorneys and lawyers 8111 | Legal Services and Attorneys",
    "Elementary or secondary schools 8211 | Elementary and Secondary Schools",
    "Colleges or universities 8220 | Colleges, Junior Colleges, Universities, and Professional Schoold",
    "Vocational schools or trade schools 8249 | Vocational Schools and Trade Schools",
    "Education 8299 | School and Educational Services (Not Elsewhere Classified)",
    "Child care services 8351 | Child Care Services",
    "Charities or social service organizations 8398 | Charitable and Social Service Organizations",
    "Civic, fraternal, or social associations 8641 | Civic, Fraternal, and Social Associations",
    "Political organizations 8651 | Political Organizations",
    "Religious organizations 8661 | Religious Organizations",
    "Membership organizations 8699 | Membership Organizations (Not Elsewhere Classified)",
    "Testing laboratories 8734 | Testing Laboratories (non-medical)",
    "Accounting, auditing or tax prep 8931 | Accounting, Auditing, and Bookkeeping Services",
    "Professional services 8999 | Professional Services (Not Elsewhere Classified)",
    "Bankruptcy services 9223 | Bail and Bond Payments",
    "Government services 9399 | Government Services (Not Elsewhere Classified)",
  ]);

  const customerReducers = useSelector((state) => ({
    loading: state.customerReducer.loading,
    error_msg: state.customerReducer.error_msg,
    success_msg: state.customerReducer.success_msg,
  }));

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newDetail = { ...data };
    newDetail[name] = value;
    setData(newDetail);
  };

  const handleSubmit = (data) => {
    props.actions.customerAction.NewProRegistration(data);
  };

  const handleChangeIdType = (event, entity) => {
    let newDetail = { ...data };
    newDetail[entity] = event.target.value;
    setData(newDetail);
  };

  const handleCheck = (event, entity) => {
    let newDetail = { ...data };
    newDetail[entity] = event.target.checked;
    setData(newDetail);
  };

  useEffect(() => {
    if (customerReducers.success_msg !== null) {
      setData({
        FullName: "",
        CompanyName: "",
        Email: "",
        Phone: "",
        Address: "",
        CompanyURL: "",
        FacebookURL: "",
        FacebookID: "",
        DateOfBirth: new Date(),
        CEODateOfBirth: new Date(),
        CFODateOfBirth: new Date(),
        IdType: "National ID",
        IdNumber: "",
        IDIssuingCountry: "Singapore",
        CountryOfRegistration: "Singapore",
        OfficialBusinessName: "",
        StreetAddress: "",
        City: "",
        PostalCode: "",
        YearsInBusiness: "",
        BusinessIncorporationNumber: "",
        TaxNumber: "",
        BusinessPhoneNumber: "",
        DescriptionOfBusiness: "",
        AnnualRevenue: "",
        AverageTransaction: "",
        CEOFullName: "",
        CEOResidentialAddress: "",
        CEOIDIssuingCountry: "Singapore",
        CFOFullName: "",
        CFOResidentialAddress: "",
        CFOIDIssuingCountry: "Singapore",
        Type: "pro",
        RapydType: "Collections",
        EntityType: "Private",
        Industry: "Private",
        ServicesProvided: "Private",
        CompanyInfo1: false,
        CompanyInfo2: false,
        CryptoCurrencyActivities: "true",
        GamblingActivities: "true",
        StakeInTheCompany: "0",
        PEPs: "true",
        PastFive: "true",
        ProofOfIdentity: null,
        PrimaryAuthorizedUserProofOfAddress: null,
        CompanyIncorporationDocuments: null,
        CapitalTableOrganizationalChart: null,
        PleaseUploadYourBankStatement: null,
        ProofOfCompanyAddress: null,
        CEODetails: null,
        CFODetails: null,
        RapydGrabPay: false,
        RapydCreditCard: false,
        RapydPaynow: false,
      });
      swal("", customerReducers.success_msg, "success");
    }
  }, [customerReducers.success_msg]);

  useEffect(() => {
    if (customerReducers.error_msg !== null) {
      swal("Oops...", customerReducers.error_msg.toString(), "error");
    }
  }, [customerReducers.error_msg]);

  const handleSelectedFile = (event, entity) => {
    let newDetail = { ...data };
    newDetail[entity] = event.target.files[0];
    setData(newDetail);
  };

  const onChangeStartTime = (date, entity) => {
    let newDetail = { ...data };
    newDetail[entity] = date;
    setData(newDetail);
  };

  return (
    <div>
      <div className="mt-3 layout">
        <Header />
        <div className="container main_layout">
          <div className="row col-12">
            <div className="responsive_inner_div d-flex col-6 flex-column justify-content-start align-items-start p-2">
              <h2 className="h_2_1"> Become a Pro member now</h2>
              <p className="main_text">
                Simply fill up the form below for us to create an account with
                your payment gateway before you kick start your awesome journey.
              </p>
              <div className="alert alert-primary" role="alert">
                Payment Gateway will take 2-4 weeks to set up, meanwhile please
                enjoy 2-weeks of free starter access.
              </div>

              <ValidatorForm
                onSubmit={() => handleSubmit(data)}
                autoComplete="off"
                className="w-100"
              >
                <div className="form-group mb-2 pt-2">
                  <TextValidator
                    label="Full Name (as per IC)"
                    name="FullName"
                    value={data.FullName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Phone Number"
                    name="Phone"
                    value={data.Phone}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Your Email"
                    name="Email"
                    value={data.Email}
                    type="email"
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Residential Address"
                    name="Address"
                    value={data.Address}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of Birth"
                    format="dd/MM/yyyy"
                    value={data.DateOfBirth}
                    className="mt-4 datePicker"
                    onChange={(date) => onChangeStartTime(date, "DateOfBirth")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Facebook Page URL"
                    name="FacebookURL"
                    value={data.FacebookURL}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="FacebookID"
                    name="FacebookID"
                    value={data.FacebookID}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">ID Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.IdType}
                    onChange={(event) => handleChangeIdType(event, "IdType")}
                  >
                    <MenuItem value={"National ID"}>National ID</MenuItem>
                    <MenuItem value={"Passport"}>Passport</MenuItem>
                    <MenuItem value={"Driver's License"}>
                      Driver's License
                    </MenuItem>
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="ID Number"
                    name="IdNumber"
                    value={data.IdNumber}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    ID Issuing Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.IDIssuingCountry}
                    onChange={(event) =>
                      handleChangeIdType(event, "IDIssuingCountry")
                    }
                  >
                    {countries.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">Proof of Identity</label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "ProofOfIdentity")
                    }
                  />
                </div>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Primary Authorized User Proof of Address
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(
                        event,
                        "PrimaryAuthorizedUserProofOfAddress"
                      )
                    }
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    Below details are required for Payment Gateway registration.
                  </label>
                  <label className="cus_label">Company Information</label>
                  <FormControlLabel
                    label={`I am a Financial institution registered outside of the US (requires additional compliance information)`}
                    control={
                      <Checkbox
                        checked={data.CompanyInfo1}
                        onChange={(event) => handleCheck(event, "CompanyInfo1")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />

                  <FormControlLabel
                    label={`I plan to collect or disburse funds in the United States and am a registered entity in the United States`}
                    control={
                      <Checkbox
                        checked={data.CompanyInfo2}
                        onChange={(event) => handleCheck(event, "CompanyInfo2")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    I would like to use Rapyd for
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.RapydType}
                    onChange={(event) => handleChangeIdType(event, "RapydType")}
                  >
                    <MenuItem value={"Collections"}>Collections</MenuItem>
                    <MenuItem value={"Disbursement"}>Disbursement</MenuItem>
                    <MenuItem value={"Both"}>Both</MenuItem>
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">Rapyd Payment Methods</label>
                  <br />
                  <FormControlLabel
                    label={`GrabPay`}
                    control={
                      <Checkbox
                        checked={data.RapydGrabPay}
                        onChange={(event) => handleCheck(event, "RapydGrabPay")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />

                  <FormControlLabel
                    label={`CreditCard`}
                    control={
                      <Checkbox
                        checked={data.RapydCreditCard}
                        onChange={(event) =>
                          handleCheck(event, "RapydCreditCard")
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />

                  <FormControlLabel
                    label={`PayNow`}
                    control={
                      <Checkbox
                        checked={data.RapydPaynow}
                        onChange={(event) => handleCheck(event, "RapydPaynow")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <TextValidator
                    label="Official Business Name"
                    name="OfficialBusinessName"
                    value={data.OfficialBusinessName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Entity Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.EntityType}
                    onChange={(event) =>
                      handleChangeIdType(event, "EntityType")
                    }
                  >
                    {entityType.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Street Address"
                    name="StreetAddress"
                    value={data.StreetAddress}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="City"
                    name="City"
                    value={data.City}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Postal Code"
                    name="PostalCode"
                    value={data.PostalCode}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="years in Business"
                    name="YearsInBusiness"
                    value={data.YearsInBusiness}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Country of Registration
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.CountryOfRegistration}
                    onChange={(event) =>
                      handleChangeIdType(event, "CountryOfRegistration")
                    }
                  >
                    {countries.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Business Incorporation Number"
                    name="BusinessIncorporationNumber"
                    value={data.BusinessIncorporationNumber}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Tax Number (TAX ID/VAT/IVA)"
                    name="TaxNumber"
                    value={data.TaxNumber}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Business Phone Number"
                    name="BusinessPhoneNumber"
                    value={data.BusinessPhoneNumber}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Description of Business"
                    name="DescriptionOfBusiness"
                    value={data.DescriptionOfBusiness}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Industry
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.Industry}
                    onChange={(event) => handleChangeIdType(event, "Industry")}
                  >
                    {industry.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Services Provided
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.ServicesProvided}
                    onChange={(event) =>
                      handleChangeIdType(event, "ServicesProvided")
                    }
                  >
                    {serviceProvided.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Company Name"
                    name="CompanyName"
                    validators={["required"]}
                    value={data.CompanyName}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  {data.RapydGrabPay === true ||
                  data.RapydCreditCard === true ? (
                    <TextValidator
                      label="Company URL"
                      name="CompanyURL"
                      value={data.CompanyURL}
                      validators={["required"]}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                      className="form-control"
                    />
                  ) : (
                    <TextValidator
                      label="Company URL"
                      name="CompanyURL"
                      value={data.CompanyURL}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                      className="form-control"
                    />
                  )}
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Approx. Annual Revenue in USD"
                    name="AnnualRevenue"
                    value={data.AnnualRevenue}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Average Transaction Value"
                    name="AverageTransaction"
                    value={data.AverageTransaction}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    Is the company engaged directly or indirectly in any crypto
                    currency activities?
                  </label>
                  <FormControl component="fieldset" className="">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      value={data.CryptoCurrencyActivities}
                      onChange={(event) =>
                        handleChangeIdType(event, "CryptoCurrencyActivities")
                      }
                      row
                    >
                      <FormControlLabel
                        value={"true"}
                        key={0}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={"false"}
                        key={1}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    Is the company engaged directly or indirectly in any
                    Gambling activities?
                  </label>
                  <FormControl component="fieldset" className="">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      value={data.GamblingActivities}
                      onChange={(event) =>
                        handleChangeIdType(event, "GamblingActivities")
                      }
                      row
                    >
                      <FormControlLabel
                        value={"true"}
                        key={0}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={"false"}
                        key={1}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Company Incorporation Documents (of the last 3 months
                    Bizfile)
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "CompanyIncorporationDocuments")
                    }
                  />
                </div>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Capital Table/Organizational Chart (Please ignore if you are
                    a sole proprietor.)
                  </label>
                  <a
                    href="https://www.ordrstartr.com/wp-content/uploads/2021/01/CapitalOrganisational-Chart-Template-Ordr-Startr.xlsx"
                    download
                  >
                    {" "}
                    Template Download{" "}
                  </a>
                  <input
                    type="file"
                    className="form-control-file mt-2"
                    onChange={(event) =>
                      handleSelectedFile(
                        event,
                        "CapitalTableOrganizationalChart"
                      )
                    }
                  />
                </div>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Please upload your Bank Statement* (within last 6 months of
                    Bank Statement)
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "PleaseUploadYourBankStatement")
                    }
                  />
                </div>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Proof of Company Address* (Rental agreement, Certificate of
                    Incorporation, or a utility bill under the company’s name)
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "ProofOfCompanyAddress")
                    }
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">Shareholders Information</label>
                  <label className="cus_label">
                    The number of individuals or companies with at least a 25%
                    stake in the company
                  </label>
                  <FormControl component="fieldset" className="">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      value={data.StakeInTheCompany}
                      onChange={(event) =>
                        handleChangeIdType(event, "StakeInTheCompany")
                      }
                      row
                    >
                      <FormControlLabel
                        value={"0"}
                        key={0}
                        control={<Radio />}
                        label={0}
                      />
                      <FormControlLabel
                        value={"1"}
                        key={1}
                        control={<Radio />}
                        label={1}
                      />
                      <FormControlLabel
                        value={"2"}
                        key={2}
                        control={<Radio />}
                        label={2}
                      />
                      <FormControlLabel
                        value={"3"}
                        key={3}
                        control={<Radio />}
                        label={3}
                      />
                      <FormControlLabel
                        value={"4"}
                        key={4}
                        control={<Radio />}
                        label={4}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <h4>CEO Details</h4>
                <div className="form-group mb-2 pt-2">
                  <TextValidator
                    label="CEO Full Name (Have a minimum of 25% shareholding)"
                    name="CEOFullName"
                    value={data.CEOFullName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="CEO Date of Birth"
                    format="dd/MM/yyyy"
                    value={data.CEODateOfBirth}
                    className="mt-4 datePicker"
                    onChange={(date) =>
                      onChangeStartTime(date, "CEODateOfBirth")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="CEO Residential Address"
                    name="CEOResidentialAddress"
                    value={data.CEOResidentialAddress}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    ID Issuing Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.CEOIDIssuingCountry}
                    onChange={(event) =>
                      handleChangeIdType(event, "CEOIDIssuingCountry")
                    }
                  >
                    {countries.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    Add CEO details (A copy of front and back IC to prove name
                    and address.)
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "CEODetails")
                    }
                  />
                </div>

                <h4 className="mt-4">CFO Details</h4>
                <div className="form-group mb-2 pt-3">
                  <TextValidator
                    label="CFO Full Name (Have a minimum of 25% shareholding)"
                    name="CFOFullName"
                    value={data.CFOFullName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="CEO Date of Birth"
                    format="dd/MM/yyyy"
                    value={data.CFODateOfBirth}
                    className="mt-4 datePicker"
                    onChange={(date) =>
                      onChangeStartTime(date, "CFODateOfBirth")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="CFO Residential Address"
                    name="CFOResidentialAddress"
                    value={data.CFOResidentialAddress}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    ID Issuing Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.CFOIDIssuingCountry}
                    onChange={(event) =>
                      handleChangeIdType(event, "CFOIDIssuingCountry")
                    }
                  >
                    {countries.map((singleData) => (
                      <MenuItem value={singleData}>{singleData}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-group mb-2 mt-4">
                  <label className="cus_label">
                    Add CFO details (A copy of front and back IC to prove name
                    and address.)
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(event) =>
                      handleSelectedFile(event, "CFODetails")
                    }
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    1) Are any of the shareholders, partners, directors,
                    officers or legal representatives Politically Exposed
                    Persons (PEPs)?
                  </label>
                  <FormControl component="fieldset" className="">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      value={data.PEPs}
                      onChange={(event) => handleChangeIdType(event, "PEPs")}
                      row
                    >
                      <FormControlLabel
                        value={"true"}
                        key={0}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={"false"}
                        key={1}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="form-group mb-2 pt-4">
                  <label className="cus_label">
                    2) Has the Company or any of its principals been subject to
                    any criminal investigations, law enforcement proceedings,
                    regulatory inquiries, convictions, consent decrees or
                    similar actions by any government agencies within the past
                    five (5) years?
                  </label>
                  <FormControl component="fieldset" className="">
                    <RadioGroup
                      aria-label="payment"
                      name="payment"
                      value={data.PastFive}
                      onChange={(event) =>
                        handleChangeIdType(event, "PastFive")
                      }
                      row
                    >
                      <FormControlLabel
                        value={"true"}
                        key={0}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={"false"}
                        key={1}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <br />
                <button type="submit" className="form_but">
                  SUBMIT
                </button>
              </ValidatorForm>
            </div>
            <div className="responsive_inner_div d-flex col-6 flex-column justify-content-start align-items-center p-2">
              <div className="row">
                <SecurityIcon className="mr-4" />
                <div className="left_div">
                  <p>
                    <strong>Security &amp; Privacy are #1</strong>
                    <br />
                    We strictly protect your data.
                  </p>
                  <p>
                    <strong>Note:</strong>
                  </p>
                  <p>
                    By submitting this form, you agreed that your information
                    will be sent, as per our
                    <a
                      className="Policy"
                      href="https://www.ordrstartr.com/privacy-policy/"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    , and
                    <a
                      className="Policy"
                      href="https://www.ordrstartr.com/terms-of-service/"
                    >
                      {" "}
                      Terms of Services{" "}
                    </a>{" "}
                    to our system for creating Ordr Startr account and payment
                    gateway.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < Footer/> */}
      {customerReducers.loading && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    customerAction: bindActionCreators(customerAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ProSubscription);
