import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import customerReducer from './customerReducer';
import adminReducer from './authReducer';

const rootReducer = combineReducers({
    customerReducer,
    adminReducer,
    routing: routerReducer
});

export default rootReducer;
