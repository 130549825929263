import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import * as adminAction from "../../../actions/authAction";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import "./style.css";
import {
  Card,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Table } from "react-bootstrap";

const EarnPointManagement = (props) => {
  const [is_EarnPointMinimumOrder, setIs_EarnPointMinimumOrder] =
    useState(false);
  const [data, setData] = useState({
    OrderValue: "",
    Point: "",
  });
  const [earnPointManagement, setEarnPointManagement] = useState([]);

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (
      adminReducers.selectedPage !== null &&
      adminReducers.selectedPage.EarnPointManagement &&
      adminReducers.selectedPage.EarnPointManagement !== null
    ) {
      setEarnPointManagement([
        ...adminReducers.selectedPage.EarnPointManagement,
      ]);
      setIs_EarnPointMinimumOrder(
        adminReducers.selectedPage.Is_EarnPointMinimumOrder
      );
    }
  }, [adminReducers.selectedPage]);

  const updatePageDetail = () => {
    props.actions.adminAction.editPageDetail(
      {
        Is_EarnPointMinimumOrder: is_EarnPointMinimumOrder,
        EarnPointManagement: earnPointManagement,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const addRecords = () => {
    if (Number(data.OrderValue) > 0 && Number(data.Point) > 0) {
      let earnPointManagements = [...earnPointManagement];
      earnPointManagements.push({
        OrderValue: data.OrderValue,
        Point: data.Point,
      });
      setData({
        OrderValue: "",
        Point: "",
      });
      setEarnPointManagement([...earnPointManagements]);
    }
  };

  const deleteRecords = (remove) => {
    let earnPointManagements = [...earnPointManagement];
    let index = earnPointManagements.indexOf(remove);
    earnPointManagements.splice(index, 1);
    setEarnPointManagement([...earnPointManagements]);
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  const handleChangeStatus = () => {
    setIs_EarnPointMinimumOrder(!is_EarnPointMinimumOrder);
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine flex-column">
        <div className="d-flex align-items-center">
          <label> Status </label> &nbsp; &nbsp;
          <Switch
            checked={is_EarnPointMinimumOrder}
            onChange={handleChangeStatus}
            name="PageStatus"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>

        <TextField
          className="filter-box"
          label="Minimum Order Amount - ($100 or more, $1 = 1 point)"
          variant="outlined"
          size="small"
          name="OrderValue"
          type="number"
          value={data.OrderValue}
          onChange={handleChange}
        />

        <TextField
          className="filter-box"
          label="Point"
          variant="outlined"
          size="small"
          name="Point"
          type="number"
          value={data.Point}
          onChange={handleChange}
        />
        <div className="d-flex align-items-center justify-content-end w-25">
          <Button variant="contained" color="secondary" onClick={addRecords}>
            Add
          </Button>{" "}
          &nbsp; &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={updatePageDetail}
          >
            Save
          </Button>
        </div>
      </Card>

      {/* <br />

      <div className="d-flex flex-column w-25">
        <label> Minimum Order Amount - ($100 or more, $1 = 1 point)</label>
        <input
          name="OrderValue"
          type="number"
          className="form-control"
          value={data.OrderValue}
          onChange={handleChange}
          placeholder={"Minimum Order Amount"}
        />
      </div>
      <br />

      <div className="d-flex flex-column w-25">
        <label> Point </label>
        <input
          name="Point"
          type="number"
          className="form-control"
          value={data.Point}
          onChange={handleChange}
          placeholder={"Point"}
        />
      </div> */}

      {/* <div className="d-flex flex-row mt-4 w-25 justify-content-end">
        <Button variant="contained" color="secondary" onClick={addRecords}>
          Add
        </Button>{" "}
        &nbsp; &nbsp;
        <Button variant="contained" color="primary" onClick={updatePageDetail}>
          Save
        </Button>
      </div>

      <br /> */}
      {/* <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Minimum Order Amount</th>
            <th scope="col">Point</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {earnPointManagement.map((singleRecords, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{singleRecords.OrderValue}</td>
              <td>{singleRecords.Point}</td>
              <td>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteRecords(singleRecords)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      <TableContainer className="management-table-body" component={Paper}>
        <Table size="small" stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="right">Minimum Order Amount</TableCell>
              <TableCell align="right">Point</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {earnPointManagement.map((singleRecords, index) => (
              <TableRow key={index}>
                <TableCell align="center" component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{singleRecords.OrderValue}</TableCell>
                <TableCell align="right">{singleRecords.Point}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteRecords(singleRecords)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(EarnPointManagement);
