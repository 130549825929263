import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import ImageLoader from "react-load-image";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactExport from "react-export-excel";
import Button from "@material-ui/core/Button";

import ENVIRONMENT_VARIABLES from "../../../environment.config";
import SearchBar from "./searchBar";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const columns = [
  { id: "Profile", label: "Profile", minWidth: 120, align: "center" },
  { id: "FbId", label: "FbId", minWidth: 120 },
  { id: "Name", label: "Name", minWidth: 120 },
  { id: "Descriptions", label: "Descriptions", minWidth: 120 },
  { id: "OrderId", label: "OrderId", minWidth: 120 },
  { id: "Total", label: "Total", minWidth: 120, align: "right" },
  { id: "Date", label: "Date", minWidth: 120, align: "center" },
  { id: "Points", label: "Points", minWidth: 120, align: "right" },
];

function createData(
  FbId,
  Name,
  Descriptions,
  Points,
  Date,
  OrderId,
  Total,
  FbSPID
) {
  return {
    FbId,
    Name,
    Descriptions,
    Points,
    Date,
    OrderId,
    FbSPID,
    Total: Total.toFixed(2),
  };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 251px)",
  },
});

const Transactions = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    transactions: state.adminReducer.transactions,
  }));

  const changeFilter = () => {
    setFilter(!filter);
    commonDataFill(!filter);
  };

  useEffect(() => {
    let exceldata = [];
    let earningPoints = 0;
    let spandPoints = 0;
    adminReducers.transactions.map((transaction) => {
      const data = [
        {
          value: transaction.FbId
            ? transaction.FbId.toString()
            : transaction.FbSPID.toString(),
        },
        {
          value: transaction.Name.toString(),
        },
        {
          value: transaction.Descriptions.toString(),
        },
        {
          value: transaction.orderId.toString(),
        },
        {
          value: transaction.Total.toString(),
        },
        {
          value: moment
            .tz(transaction.createdAt, "Asia/Singapore")
            .format("DD-MM-YYYY hh:mm A")
            .toString(),
        },
        {
          value: transaction.Points.toString(),
        },
      ];

      if (transaction.Points > 0) {
        earningPoints += transaction.Points;
      } else {
        spandPoints += transaction.Points;
      }

      exceldata.push(data);
    });

    exceldata.push([
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "Earning Points",
        style: { fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } } },
      },
      {
        value: earningPoints.toString(),
        style: { fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } } },
      },
    ]);

    exceldata.push([
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "Spand Points",
        style: { fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } } },
      },
      {
        value: spandPoints.toString(),
        style: { fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } } },
      },
    ]);

    let data = [
      {
        columns: [
          "FbId",
          "Name",
          "Description",
          "OrderNo",
          "Total Price",
          "Transaction Date",
          "Points",
        ],
        data: exceldata,
      },
    ];

    setExcelData([...data]);
    commonDataFill(filter);
  }, [adminReducers.transactions]);

  const commonDataFill = (filter = false) => {
    if (
      adminReducers.transactions !== null &&
      adminReducers.transactions !== undefined
    ) {
      let orders = [];
      adminReducers.transactions.map((singleItem) => {
        if (filter) {
          if (
            singleItem.Descriptions.toString()
              .toLowerCase()
              .startsWith("referral")
          ) {
            orders.push(
              createData(
                singleItem.FbId ? singleItem.FbId : singleItem.FbSPID,
                singleItem.Name,
                singleItem.Descriptions,
                singleItem.Points,
                moment
                  .tz(singleItem.Date, "Asia/Singapore")
                  .format("DD-MM-YYYY hh:mm A"),
                singleItem.orderId,
                singleItem.Total
              )
            );
          }
        } else {
          orders.push(
            createData(
              singleItem.FbId ? singleItem.FbId : singleItem.FbSPID,
              singleItem.Name,
              singleItem.Descriptions,
              singleItem.Points,
              moment
                .tz(singleItem.Date, "Asia/Singapore")
                .format("DD-MM-YYYY hh:mm A"),
              singleItem.orderId,
              singleItem.Total
            )
          );
        }
      });
      setRows(orders);
      setPage(0);
    }
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchBar filter={filter} changeFilter={changeFilter} />
        <div className="but_width">
          <ExcelFile
            element={
              <Button variant="contained" className="" color="primary">
                <GetAppIcon />
              </Button>
            }
            filename="Transaction"
          >
            <ExcelSheet dataSet={excelData} name="Organization" />
          </ExcelFile>
        </div>
      </Card>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.id === "Profile") {
                          return (
                            <TableCell key={column.id} align="center">
                              <ImageLoader
                                src={
                                  ENVIRONMENT_VARIABLES.IMAGE_URL +
                                  row.FbId +
                                  ".jpg"
                                }
                              >
                                <img
                                  className="img-fluid"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/NoImages.png"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/imageLoader.png"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                              </ImageLoader>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Transactions;
