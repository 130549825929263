import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const RapydCredentials = (props) => {

    const [data, setData] = useState({
        RapydDev_access_key: props.data.RapydDev_access_key,
        RapydDev_secret_key: props.data.RapydDev_secret_key,
        RapydProd_access_key: props.data.RapydProd_access_key,
        RapydProd_secret_key: props.data.RapydProd_secret_key,
    });

    const handleChange = (event) => {
        const field = event.target.name;
        const commonData = {...data};
        commonData[field] = event.target.value;
        return setData(commonData);
    };

    const submitDev = () => {
        props.saveDetail({
            RapydDev_access_key: data.RapydDev_access_key,
            RapydDev_secret_key: data.RapydDev_secret_key,
        });
    };

    const submitProd = () => {
        props.saveDetail({
            RapydProd_access_key: data.RapydProd_access_key,
            RapydProd_secret_key: data.RapydProd_secret_key,
        });
    };

    return (
        <div className="ml-4">
            <h4 className="mt-4">Development</h4>
            <div className="d-flex flex-column mt-4 w-50">
                <label>Rapyd access_key</label>
                <input name="RapydDev_access_key"
                       className="form-control"
                       value={data.RapydDev_access_key} onChange={handleChange}
                       placeholder={"Development Rapyd access_key"}/>
            </div>
            <div className="d-flex flex-column mt-4 w-50">
                <label>Rapyd secret_key</label>
                <input name="RapydDev_secret_key"
                       className="form-control"
                       value={data.RapydDev_secret_key} onChange={handleChange}
                       placeholder={"Development Rapyd secret_key"}/>
            </div>
            <div className="d-flex flex-row mt-4 w-50 justify-content-end">
                <Button variant="contained" color="primary" onClick={submitDev}>Save</Button>
            </div>
            <br/>
            <Divider/>
            <h4 className="mt-4">Production</h4>
            <div className="d-flex flex-column mt-4 w-50">
                <label>Rapyd access_key</label>
                <input name="RapydProd_access_key"
                       className="form-control"
                       value={data.RapydProd_access_key} onChange={handleChange}
                       placeholder={"Production Rapyd access_key"}/>
            </div>
            <div className="d-flex flex-column mt-4 w-50">
                <label>Rapyd secret_key</label>
                <input name="RapydProd_secret_key"
                       className="form-control"
                       value={data.RapydProd_secret_key} onChange={handleChange}
                       placeholder={"Production Rapyd secret_key"}/>
            </div>
            <div className="d-flex flex-row mt-4 w-50 justify-content-end">
                <Button variant="contained" color="primary" onClick={submitProd}>Save</Button>
            </div>
        </div>
    );
};

export default RapydCredentials;
