import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import * as adminAction from "../../../actions/authAction";
import "./style.css";

let moment = require("moment-timezone");

const SearchOrder = (props) => {
  const startDayDateTime = moment()
    .tz("Asia/Singapore")
    .startOf("day")
    .format();
  const endDayDateTime = moment().tz("Asia/Singapore").endOf("day").format();
  const [search, setSearch] = useState({
    orderName: "",
    orderNumber: "",
    fromDate: new Date(startDayDateTime),
    toDate: new Date(endDayDateTime),
    paymentId: "",
    postId: 0,
  });
  const [timeOut, setTimeOut] = useState(null);
  const [group, setGroup] = useState([{ message: "All", postId: 0 }]);

  const adminReducers = useSelector((state) => ({
    orderSearch: state.adminReducer.orderSearch,
    liveOrderSearch: state.adminReducer.liveOrderSearch,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.orderSearch !== null) {
      const commonData = { ...adminReducers.orderSearch };
      setSearch(commonData);
    }
  }, [adminReducers.orderSearch]);

  useEffect(() => {
    if (adminReducers.liveOrderSearch) {
      // setSearch({ ...search, postId: adminReducers.liveOrderSearch[0] });
      setGroup([
        ...adminReducers.liveOrderSearch,
        { message: "All", postId: 0 },
      ]);
    }
  }, [adminReducers.liveOrderSearch]);

  const handleChange = (event) => {
    clearTimeout(timeOut);
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    setTimeOut(
      setTimeout(() => {
        props.actions.adminAction.getSearchOrderFilter(
          commonData,
          adminReducers.selectedPage.FbPageId.trim()
        );
      }, 1000)
    );
    return setSearch(commonData);
  };

  const handleChangeFilter = (event) => {
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    setTimeOut(
      setTimeout(() => {
        props.actions.adminAction.getSearchOrderFilter(
          commonData,
          adminReducers.selectedPage.FbPageId.trim()
        );
      }, 1000)
    );
    return setSearch(commonData);
  };

  const onChangeStartTime = (date) => {
    const commonData = { ...search };
    commonData.fromDate = date;
    return setSearch(commonData);
  };

  const onChangeEndTime = (date) => {
    const commonData = { ...search };
    commonData.toDate = date;
    return setSearch(commonData);
  };

  const searchButton = () => {
    props.actions.adminAction.getSearchOrderFilter(
      search,
      adminReducers.selectedPage.FbPageId.trim()
    );

    props.actions.adminAction.getSearchLiveOrderFilter(
      search,
      adminReducers.selectedPage.FbPageId.trim()
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="d-flex align-items-baseline justify-content-between filter">
        <KeyboardDatePicker
          className="filter-box m-0"
          variant="outlined"
          size="small"
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
          format="dd/MM/yyyy"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardTimePicker
          className="filter-box m-0"
          margin="normal"
          id="time-picker"
          label="Start Time"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />

        <KeyboardDatePicker
          className="filter-box m-0"
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
          format="dd/MM/yyyy"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardTimePicker
          className="filter-box m-0"
          margin="normal"
          id="time-picker"
          label="End Time"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        <Button
          className="but_width"
          variant="contained"
          color="primary"
          onClick={searchButton}
        >
          <SearchIcon />
        </Button>

        <FormControl className="filter-box maxw-140" variant="outlined" size="small">
          <InputLabel id="demo-simple-select-label">Post</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Post"
            id="demo-simple-select"
            name="postId"
            value={search.postId}
            onChange={handleChangeFilter}
          >
            {group.map((singleSupplier) => (
              <MenuItem value={singleSupplier.postId}>
              <span style={{textOverflow: "ellipsis",display: "block",overflow: "hidden",width: "140px"}}>  {singleSupplier.message}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="filter-box"
          variant="outlined"
          size="small"
          label="Payment_Id"
          name="paymentId"
          onChange={handleChange}
          value={search.paymentId}
        />
        <TextField
          className="filter-box"
          label="Order Number"
          variant="outlined"
          size="small"
          name="orderNumber"
          onChange={handleChange}
          value={search.orderNumber}
        />
        <TextField
          variant="outlined"
          size="small"
          className="filter-box"
          label="Customer Name"
          name="orderName"
          onChange={handleChange}
          value={search.orderName}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SearchOrder);
