import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {connect, useSelector} from "react-redux";
import config from '../../../environment.config';
import Button from '@material-ui/core/Button';
import './style.css';
import * as adminAction from "../../../actions/authAction";
import {bindActionCreators} from "redux";

const useStyles = makeStyles({
    root: {
        maxWidth: 200,
        margin: "10px 22px",
    },
    media: {
        height: 200,
        width: 200
    },
});

const LuckyDrawWall = (props) => {

    const classes = useStyles();
    const [luckyDrawList, setLuckyDrawList] = useState([]);
    const [winnerList, setWinnerList] = useState([]);

    const adminReducers = useSelector((state) => ({
        winnerList: state.adminReducer.winnerList,
        luckyDraw: state.adminReducer.luckyDraw,
        selectedPage: state.adminReducer.selectedPage
    }));

    useEffect(() => {
        setLuckyDrawList(adminReducers.luckyDraw);
    }, [adminReducers.luckyDraw]);

    useEffect(() => {
        setWinnerList(adminReducers.winnerList);
    }, [adminReducers.winnerList]);

    const luckyDraw = () => {
        props.actions.adminAction.luckyDraw(adminReducers.selectedPage.FbPageId);
    };

    return (
        <div>
            <div className="displayList">
                {winnerList.length > 0 ? (winnerList.map((singleData) => (
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={config.IMAGE_URL + singleData.FbId + ".jpg"}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {singleData.Name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))) : (
                    (luckyDrawList.map((singleData) => (
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={config.IMAGE_URL + singleData.FbId + ".jpg"}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {singleData.Name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))))}
            </div>
            {(luckyDrawList.length > 0) &&
            <div className="d-flex flex-row justify-content-center my-3 mx-5">
                <Button variant="contained" color="primary" className="ml-3 LuckyDrawButton"
                        onClick={luckyDraw}>Lucky Draw</Button>
            </div>}
        </div>
    );

};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(LuckyDrawWall);
