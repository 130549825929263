import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";

import * as adminAction from "../../../actions/authAction";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const MessageDashBoard = (props) => {
  const [message, setMessage] = useState({
    ReplyMessage: "",
    OutOfStockMessage: "",
    PersonalMessage: "",
    MassMessage: "",
    TelegramMessage: "",
    WhatsAppMessage: "",
    EmailMessage: "",
    CodePrefix: "",
    RemarkMessage: "",
    TermsAndConditions: "",
    PrivacyPolicy: "",
    CreditCardMessage: "",
    FbAccessToken: "",
    FbUserAccessToken: "",
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setMessage({
        ReplyMessage: adminReducers.selectedPage.ReplyMessage
          ? adminReducers.selectedPage.ReplyMessage
          : "",
        OutOfStockMessage: adminReducers.selectedPage.OutOfStockMessage
          ? adminReducers.selectedPage.OutOfStockMessage
          : "",
        PersonalMessage: adminReducers.selectedPage.PersonalMessage
          ? adminReducers.selectedPage.PersonalMessage
          : "",
        MassMessage: adminReducers.selectedPage.MassMessage
          ? adminReducers.selectedPage.MassMessage
          : "",
        TelegramMessage: adminReducers.selectedPage.TelegramMessage
          ? adminReducers.selectedPage.TelegramMessage
          : "",
        WhatsAppMessage: adminReducers.selectedPage.WhatsAppMessage
          ? adminReducers.selectedPage.WhatsAppMessage
          : "",
        EmailMessage: adminReducers.selectedPage.EmailMessage
          ? adminReducers.selectedPage.EmailMessage
          : "",
        CodePrefix: adminReducers.selectedPage.CodePrefix
          ? adminReducers.selectedPage.CodePrefix
          : "",
        RemarkMessage: adminReducers.selectedPage.RemarkMessage
          ? adminReducers.selectedPage.RemarkMessage
          : "",
        TermsAndConditions: adminReducers.selectedPage.TermsAndConditions
          ? adminReducers.selectedPage.TermsAndConditions
          : "",
        PrivacyPolicy: adminReducers.selectedPage.PrivacyPolicy
          ? adminReducers.selectedPage.PrivacyPolicy
          : "",
        CreditCardMessage: adminReducers.selectedPage.CreditCardMessage
          ? adminReducers.selectedPage.CreditCardMessage
          : "",
        FbAccessToken: adminReducers.selectedPage.FbAccessToken
          ? adminReducers.selectedPage.FbAccessToken
          : "",
        FbUserAccessToken: adminReducers.selectedPage.FbUserAccessToken
          ? adminReducers.selectedPage.FbUserAccessToken
          : "",
      });
    }
  }, [adminReducers.selectedPage]);

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...message };
    commonData[field] = event.target.value;
    return setMessage(commonData);
  };

  const submit = () => {
    props.actions.adminAction.editPageDetail(
      message,
      adminReducers.selectedPage.FbPageId
    );
  };

  return (
    <Card className="p-3">
      <div className="token-page">
        <div className="d-flex flex-column w-50 pt-2">
          {/* <label>FB Page Token</label> */}
          <TextField
            label="FB Page Token"
            multiline
            maxRows={2}
            variant="outlined"
            name="FbAccessToken"
            // rows="2"
            cols="50"
            disabled={true}
            value={message.FbAccessToken.slice(
              message.FbAccessToken.length - 50
            )}
            // placeholder={"FbPageToken"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Reply Message</label> */}
          <TextField
            label="Reply Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="ReplyMessage"
            // rows="10"
            cols="50"
            value={message.ReplyMessage}
            onChange={handleChange}
            // placeholder={"Reply Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Out Of Stock Message</label> */}
          <TextField
            label="Out Of Stock Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="OutOfStockMessage"
            // rows="10"
            cols="50"
            value={message.OutOfStockMessage}
            onChange={handleChange}
            // placeholder={"Out Of Stock Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Personal Message</label> */}
          <TextField
            label="Personal Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="PersonalMessage"
            // rows="10"
            cols="50"
            value={message.PersonalMessage}
            onChange={handleChange}
            // placeholder={"Personal Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Mass Message</label> */}
          <TextField
            label="Mass Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="MassMessage"
            // rows="10"
            cols="50"
            value={message.MassMessage}
            onChange={handleChange}
            // placeholder={"Mass Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Telegram Message</label> */}
          <TextField
            label="Telegram Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="TelegramMessage"
            // rows="10"
            cols="50"
            value={message.TelegramMessage}
            onChange={handleChange}
            // placeholder={"Telegram Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>WhatsApp Message</label> */}
          <TextField
            label="WhatsApp Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="WhatsAppMessage"
            // rows="10"
            cols="50"
            value={message.WhatsAppMessage}
            onChange={handleChange}
            // placeholder={"WhatsApp Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Email Message</label> */}
          <TextField
            label="Email Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="EmailMessage"
            // rows="10"
            cols="50"
            value={message.EmailMessage}
            onChange={handleChange}
            // placeholder={"Email Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Remark Message</label> */}
          <TextField
            label="Remark Message"
            multiline
            maxRows={10}
            rows={5}
            variant="outlined"
            name="RemarkMessage"
            // rows="10"
            cols="50"
            value={message.RemarkMessage}
            onChange={handleChange}
            // placeholder={"Remark Message"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Terms And Conditions URL</label> */}
          <TextField
            label="Terms And Conditions URL"
            multiline
            maxRows={5}
            rows={3}
            variant="outlined"
            name="TermsAndConditions"
            // rows="5"
            cols="50"
            value={message.TermsAndConditions}
            onChange={handleChange}
            // placeholder={"Terms And Conditions URL"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Privacy Policy URL</label> */}
          <TextField
            label="Privacy Policy URL"
            multiline
            maxRows={5}
            rows={3}
            variant="outlined"
            name="PrivacyPolicy"
            // rows="5"
            cols="50"
            value={message.PrivacyPolicy}
            onChange={handleChange}
            // placeholder={"Privacy Policy URL"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Code Prefix</label> */}
          <TextField
            label="Code Prefix"
            multiline
            maxRows={5}
            rows={3}
            variant="outlined"
            name="CodePrefix"
            // rows="5"
            cols="50"
            value={message.CodePrefix}
            onChange={handleChange}
            // placeholder={"Code Prefix"}
          />
        </div>
        <div className="d-flex flex-column mt-4 w-50">
          {/* <label>Credit Card Message</label> */}
          <TextField
            label="Credit Card Message"
            multiline
            maxRows={5}
            rows={3}
            variant="outlined"
            name="CreditCardMessage"
            // rows="5"
            cols="50"
            value={message.CreditCardMessage}
            onChange={handleChange}
            // placeholder={"Credit Card Message"}
          />
        </div>
      </div>
      <div className="d-flex flex-row mt-4 w-50 justify-content-end">
        <Button variant="contained" color="primary" onClick={submit}>
          Save
        </Button>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MessageDashBoard);
