import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { connect, useSelector } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import { bindActionCreators } from "redux";
import SearchUserDetail from "./SearchUserDetail";
import ReactExport from "react-export-excel";
import ImageLoader from "react-load-image";
import WarningIcon from "@material-ui/icons/Warning";
import EditIcon from "@material-ui/icons/Edit";

import ENVIRONMENT_VARIABLES from "../../../environment.config";
import * as adminAction from "../../../actions/authAction";
import CustomerOrders from "./CustomerOrderDialog";
import "./style.css";
import { Card, IconButton } from "@material-ui/core";
import EditDialog from "./editDialog";

let moment = require("moment-timezone");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const columns = [
  { id: "Is_Communication", label: "", minWidth: 20 },
  { id: "Profile", label: "Profile", minWidth: 90, align: "center" },
  { id: "Name", label: "Name", minWidth: 60 },
  { id: "FbId", label: "FbId", minWidth: 50 },
  { id: "ShippingMobile", label: "Mobile No", minWidth: 50 },
  { id: "ShippingAddress1", label: "Address", minWidth: 60 },
  { id: "ShippingPostalCode", label: "PostalCode", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 10 },
  { id: "PointsEarned", label: "PointsEarned", minWidth: 50, align: "right" },
  { id: "PointsUsed", label: "PointsUsed", minWidth: 50, align: "right" },
  { id: "Date", label: "Date", minWidth: 100 },
  { id: "Opt_In", label: "Opt In", minWidth: 50 },
  { id: "Code", label: "Code", minWidth: 50, align: "left" },
  {
    id: "ReferralOrders",
    label: "ReferralOrders",
    minWidth: 40,
    align: "center",
  },
  { id: "Orders", label: "Orders", minWidth: 40, align: "center" },

  { id: "Edit", label: "Edit", minWidth: 40, align: "center" },
];

function createData(
  Name,
  FbId,
  ShippingMobile,
  ShippingAddress1,
  ShippingPostalCode,
  email,
  PointsEarned,
  PointsUsed,
  Opt_In,
  FbSPID,
  Date,
  _id,
  Code,
  pointCollections,
  Is_Communication,
  userProfile
) {
  return {
    Name,
    FbId,
    ShippingMobile,
    ShippingAddress1,
    ShippingPostalCode,
    email,
    PointsEarned,
    PointsUsed,
    Opt_In,
    FbSPID,
    Date,
    _id,
    Code,
    pointCollections,
    Is_Communication,
    userProfile,
  };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 278px)",
  },
});

const UsersDetail = (props) => {
  const { row } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [title, setTitle] = useState("User's Orders");

  const [editOpen, setEditOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    userDetail: state.adminReducer.userDetail,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    const allData = [];
    let exceldata = [];
    let colors = ["FF0000FF", "FF00FF00", "FFF86B00", "FFFFFF00", "FFCCEEFF"];
    let colorCount = 4;

    adminReducers.userDetail.map((singleData) => {
      const createItem = createData(
        singleData.Name,
        singleData.FbId,
        singleData.ShippingMobile,
        singleData.ShippingAddress1,
        singleData.ShippingPostalCode,
        singleData.email,
        Number(singleData.PointsEarned).toFixed(1),
        Number(singleData.PointsUsed).toFixed(1),
        singleData.Opt_In.toString(),
        singleData.FbSPID,
        moment
          .tz(singleData.Date, "Asia/Singapore")
          .format("DD-MM-YYYY hh:mm A")
          .toString(),
        singleData._id,
        singleData.UserCode,
        singleData.pointCollections,
        singleData.Is_Communication,
        singleData
      );
      if (editItem && editItem._id === singleData._id) {
        setEditItem(createItem);
      }
      allData.push(createItem);
      let FbId = "";
      let MobileNumber = "";
      let Address = "";
      let PostalCode = "";
      let Email = "";
      let Name = "";

      if (singleData.FbId && singleData.FbId !== null) {
        FbId = singleData.FbId;
      }
      if (singleData.ShippingMobile && singleData.ShippingMobile !== null) {
        MobileNumber = singleData.ShippingMobile;
      }
      if (singleData.ShippingAddress1 && singleData.ShippingAddress1 !== null) {
        Address = singleData.ShippingAddress1;
      }
      if (
        singleData.ShippingPostalCode &&
        singleData.ShippingPostalCode !== null
      ) {
        PostalCode = singleData.ShippingPostalCode;
      }
      if (singleData.email && singleData.email !== null) {
        Email = singleData.email;
      }
      if (singleData.Name && singleData.Name !== null) {
        Name = singleData.Name;
      }

      const data = [
        {
          value: FbId,
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Name,
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: MobileNumber.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Address.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: PostalCode.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Email.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Number(singleData.PointsEarned).toFixed(1).toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Number(singleData.PointsUsed).toFixed(1).toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: singleData.Opt_In.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
      ];
      exceldata.push(data);
    });
    let data = [
      {
        columns: [
          "FbId",
          "Name",
          "MobileNumber",
          "Address",
          "PostalCode",
          "Email",
          "PointsEarned",
          "PointsUsed",
          "Opt_In",
        ],
        data: exceldata,
      },
    ];
    setRows(allData);
    setExcelData([...data]);
    setPage(0);
  }, [adminReducers.userDetail]);

  const showOrders = (userId, FbSPID) => {
    props.actions.adminAction.usersOrders(
      userId,
      FbSPID,
      adminReducers.selectedPage.FbPageId
    );
    setTitle("User's Orders");
    setOpen(true);
  };

  const showReferralOrders = (userId, FbSPID) => {
    props.actions.adminAction.usersReferralOrders(
      userId,
      FbSPID,
      adminReducers.selectedPage.FbPageId
    );
    setTitle("User's Referral Orders");
    setOpen(true);
  };

  const closeOrders = () => {
    setOpen(false);
  };

  const handleEditSubmitClose = (editItems) => {
    const date = moment.tz(editItems.ExpiryDate, "Asia/Singapore");
    const data = {
      _id: editItems._id,
      PointsEarned: editItems.PointsToAdd,
      ExpiryDate: new Date(date),
    };
    props.actions.adminAction.updateUserDetail({ ...data });
  };

  const HandleEditOpen = (item) => {
    setEditItem({ ...item });
    setEditOpen(true);
  };

  const HandleEditClose = () => {
    setEditOpen(false);
  };

  return (
    <div className="orders-layout">
      {editOpen && (
        <EditDialog
          handleClose={HandleEditClose}
          open={editOpen}
          handleEditSubmitClose={handleEditSubmitClose}
          editItem={editItem}
        />
      )}
      <Card className="searchEngine">
        <SearchUserDetail />

        <ExcelFile
          element={
            <Button variant="contained" className="but_width" color="primary">
              <GetAppIcon />
            </Button>
          }
          filename="UsersDetail"
        >
          <ExcelSheet dataSet={excelData} name="Organization" />
        </ExcelFile>
      </Card>
      <CustomerOrders open={open} closeOrders={closeOrders} title={title} />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column._id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "Orders") {
                          return (
                            <TableCell
                              key={`${index}+${value}`}
                              align={"center"}
                            >
                              <Button
                                variant="contained"
                                key={row.id}
                                className="btn show_order"
                                color="primary"
                                onClick={() => showOrders(row.FbId, row.FbSPID)}
                              >
                                Orders
                              </Button>
                            </TableCell>
                          );
                        } else if (column.id === "ReferralOrders") {
                          return (
                            <TableCell key={index} align={"center"}>
                              <Button
                                variant="contained"
                                key={row.id}
                                className="btn show_order"
                                color="primary"
                                onClick={() =>
                                  showReferralOrders(row.FbId, row.FbSPID)
                                }
                              >
                                Show
                              </Button>
                            </TableCell>
                          );
                        } else if (column.id === "Is_Communication") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.Is_Communication === false && (
                                <WarningIcon
                                  style={{
                                    color: "#ffcc00",
                                  }}
                                />
                              )}
                            </TableCell>
                          );
                        } else if (column.id === "Edit") {
                          return (
                            <TableCell align="center">
                              <IconButton
                                aria-label="edit"
                                onClick={() => HandleEditOpen(row)}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          );
                        } else if (column.id === "Profile") {
                          return (
                            <TableCell key={column.id} align="center">
                              <ImageLoader
                                src={
                                  ENVIRONMENT_VARIABLES.IMAGE_URL +
                                  row.FbId +
                                  ".jpg"
                                }
                              >
                                <img
                                  className="img-fluid"
                                  style={{
                                    height: "60px",
                                    width: "60 px",
                                  }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/NoImages.png"
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                  }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/imageLoader.png"
                                  style={{
                                    height: "60px",
                                    width: "60px",
                                  }}
                                  alt={row.FbId}
                                />
                              </ImageLoader>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(UsersDetail);
