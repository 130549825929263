import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import * as adminAction from "../../../actions/authAction";
import Button from "@material-ui/core/Button";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const FirstSignUpCustomersIncentivePoints = (props) => {
  const [data, setData] = useState({
    FirstSignUpPoint: 0,
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setData({
        FirstSignUpPoint: adminReducers.selectedPage.FirstSignUpPoint,
      });
    }
  }, [adminReducers.selectedPage]);

  const updatePageDetail = () => {
    props.actions.adminAction.editPageDetail(
      data,
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  return (
    <Card className="p-3 gap-20 d-flex">
      {/* <div className="d-flex flex-column w-25">
        <label> First sign up customers incentive points </label>
        <input
          name="FirstSignUpPoint"
          type="Number"
          className="form-control"
          value={data.FirstSignUpPoint}
          onChange={handleChange}
          placeholder={"First SignUp Point"}
        />
      </div> */}
      <TextField
        name="FirstSignUpPoint"
        type="Number"
        value={data.FirstSignUpPoint}
        onChange={handleChange}
        label="First SignUp Point"
        variant="outlined"
        size="small"
        className="col-sm-3"
      />

      <Button variant="contained" color="primary" onClick={updatePageDetail}>
        Save
      </Button>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(FirstSignUpCustomersIncentivePoints);
