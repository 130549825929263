import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import ImageLoader from 'react-load-image';
import ENVIRONMENT_VARIABLES from "../../environment.config";
import './commentStyle.css'

const CommentsDisplay = () => {

    const [comments, setComments] = useState([]);
    const customerReducers = useSelector((state) => ({
        comments: state.customerReducer.comments
    }));

    useEffect(() => {
        if (customerReducers.comments.length > 0) {
            setComments([...customerReducers.comments]);
        }
    }, [customerReducers.comments]);

    return (
        <div className="container mt-3 mb-2">
            <div className="row d-flex justify-content-center">
                <div className="col-md-12">
                    <div className="card1 p-3">
                        {comments.map((singleComments) => (
                            <div className="comments-box p-3 mt-2 d-flex align-items-center justify-content-start">
                                <ImageLoader className="commentImage"
                                             src={ENVIRONMENT_VARIABLES.IMAGE_URL + singleComments.from.id + ".jpg"}>
                                    <img className="img-fluid"
                                         alt={singleComments.from.id}/>
                                    <img src="/assets/image/NoImages.png"
                                         alt={singleComments.from.id}/>
                                    <img src="/assets/image/imageLoader.png"
                                         alt={singleComments.from.id}/>
                                </ImageLoader>
                                <div className="d-flex flex-column justify-content-between align-items-start ml-4">
                                    <span className="text-muted">{singleComments.from.name}</span>
                                    <span>{singleComments.message}</span>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CommentsDisplay;
