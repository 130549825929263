import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import "react-medium-image-zoom/dist/styles.css";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";

import * as adminAction from "../../../actions/authAction";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  { id: "keyword", numeric: false, disablePadding: true, label: "Keyword" },
  {
    id: "groupId",
    numeric: false,
    disablePadding: true,
    label: "GroupId",
  },
  { id: "postId", numeric: false, disablePadding: true, label: "PostId" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Current Live Shows
        </Typography>
      )}

      {numSelected > 0 ? (
        <span className="d-flex">
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={props.DeleteRecords}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {numSelected === 1 && (
            <Tooltip title="Delete">
              <IconButton aria-label="edit" onClick={props.HandleEditOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </span>
      ) : (
        <Tooltip title="Add New Keywords">
          <IconButton
            aria-label="Add New Keywords"
            onClick={props.handleClickOpen}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const linkCampaign = (props) => {
  const classes = useStyles();

  const [isLive, setIsLive] = useState(false);
  const [offlinePurchase, setOfflinePurchase] = useState(false);
  const [search, setSearch] = useState({
    postId: "",
    groupId: "",
  });
  const [group, setGroup] = useState([]);
  const [livePost, setLivePost] = useState([]);
  const [liveShowGroup, setLiveShowGroup] = useState([]);

  const adminReducers = useSelector((state) => ({
    allKeyWords: state.adminReducer.allKeyWords,
    allGroups: state.adminReducer.allGroups,
    allLivePost: state.adminReducer.allLivePost,
    allLiveShowGroup: state.adminReducer.allLiveShowGroup,
    // allLiveShow: state.adminReducer.allLiveShow,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage) {
      // props.actions.adminAction.getCurrentShow(
      //   adminReducers.selectedPage.FbPageId
      // );
      props.actions.adminAction.getAllPost(
        adminReducers.selectedPage?.FbPageId
      );
      props.actions.adminAction.getHomeCallGroup(
        adminReducers.selectedPage?.FbPageId
      );
      props.actions.adminAction.getAllLiveShowGroup(
        adminReducers.selectedPage?.FbPageId
      );
    }
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.allGroups.length > 0) {
      setSearch({ ...search, groupId: adminReducers.allGroups[0]?._id });
      setGroup(adminReducers.allGroups);
    }
  }, [adminReducers.allGroups]);

  useEffect(() => {
    if (adminReducers.allLivePost.length > 0) {
      setSearch({ ...search, postId: adminReducers.allLivePost[0]?.postId });
      setLivePost(adminReducers.allLivePost);
    }
  }, [adminReducers.allLivePost]);

  useEffect(() => {
    if (adminReducers.allLiveShowGroup.length > 0) {
      setLiveShowGroup(adminReducers.allLiveShowGroup);
    }
  }, [adminReducers.allLiveShowGroup]);

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setIsLive(adminReducers.selectedPage.repeatedStatus);
      setOfflinePurchase(adminReducers.selectedPage.Is_OfflinePurchase);
    }
  }, [adminReducers.selectedPage]);

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    let newKeywordDetail = { ...search };
    newKeywordDetail[name] = value;
    setSearch(newKeywordDetail);
  };

  const handleSave = () => {
    search.FbPageId = adminReducers.selectedPage.FbPageId;
    props.actions.adminAction.addLiveShowGroup(search);
  };

  const deleteLiveGroup = (remove) => {
    props.actions.adminAction.deleteLiveShowGroups(remove._id);
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine justify-content-start">
        <FormControl variant="outlined" size="small" className="col-sm-2">
          <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            label="Campaign"
            id="demo-simple-select"
            name="groupId"
            value={search.groupId}
            onChange={handleChange1}
          >
            {group.map((singleSupplier) => (
              <MenuItem value={singleSupplier._id}>
                {singleSupplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" className="col-sm-2">
          <InputLabel id="demo-simple-select-label">LivePost</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            label="LivePost"
            id="demo-simple-select"
            name="postId"
            value={search.postId}
            onChange={handleChange1}
          >
            {livePost.map((singleSupplier) => (
              <MenuItem value={singleSupplier.postId}>
                {singleSupplier.message}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          className="ml-3"
          onClick={handleSave}
          disabled={search.postId === ""}
        >
          Save
        </Button>
      </Card>

      {/* <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Campaign Name</th>
            <th scope="col">Campaign Id</th>
            <th scope="col">Post Name</th>
            <th scope="col">Post Id</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {liveShowGroup.map((singleDate, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{singleDate.group?.name}</td>
                <td>{singleDate.groupId}</td>
                <td>{singleDate.post?.message}</td>
                <td>{singleDate.postId}</td>
                <td>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteLiveGroup(singleDate)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}

      <TableContainer className="table-body" component={Paper}>
        <Table
          size="small"
          stickyHeader
          className={classes.table}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="left">Campaign Name</TableCell>
              <TableCell align="left">Campaign Id</TableCell>
              <TableCell align="left">Post Name</TableCell>
              <TableCell align="left">Post Id</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liveShowGroup.map((singleDate, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center" component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">{singleDate.group?.name}</TableCell>
                  <TableCell align="left">{singleDate.groupId}</TableCell>
                  <TableCell align="left">{singleDate.post?.message}</TableCell>
                  <TableCell align="left">{singleDate.postId}</TableCell>
                  <TableCell align="center">
                    {" "}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteLiveGroup(singleDate)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(linkCampaign);
