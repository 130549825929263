import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

let moment = require("moment-timezone");

function TimeSlotsSelection(props) {
  const {
    onClose,
    availableTimeSlots,
    selectDeliveryTimeSlots,
    deliveryTimeSlots,
    open,
    ...other
  } = props;
  const radioGroupRef = React.useRef(null);
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState(deliveryTimeSlots);
  const [selectDeliveryTimeSlot, setSelectDeliveryTimeSlot] = useState(
    selectDeliveryTimeSlots
  );

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleOk = () => {
    onClose(selectDeliveryTimeSlot);
  };

  const handleCancel = () => {
    onClose(null);
  };

  const OnChange = (selectSlot) => {
    let checkDeliverySlot = [...availableTimeSlots];
    let selectedTimeSlot = [...selectDeliveryTimeSlot];

    //Todo check selectedTimeSlot
    let find = selectedTimeSlot.find(
      (data) => data.supplierName === selectSlot.supplierName
    );
    selectSlot.timeSlotId = selectSlot._id;
    if (!find) {
      selectedTimeSlot.push(selectSlot);
    } else {
      //replace
      const findIndex = selectedTimeSlot.indexOf(find);
      selectedTimeSlot[findIndex] = selectSlot;
    }

    checkDeliverySlot.map((singleSlots) => {
      singleSlots.checked = false;
      if (
        selectedTimeSlot.find((data) => data.timeSlotId === singleSlots._id)
      ) {
        singleSlots.checked = true;
      }
    });

    //Todo group by
    const uniqueDeliveryDate = _.groupBy(checkDeliverySlot, "supplierName");
    const convertObjectToArray = _.values(uniqueDeliveryDate);
    setDeliveryTimeSlot([...convertObjectToArray]);
    setSelectDeliveryTimeSlot([...selectedTimeSlot]);
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">
          Sorry, 15 minutes is up, please re-select your delivery slot.
        </DialogTitle>
        <DialogContent dividers>
          {deliveryTimeSlot.map(
            (singleSupplier, key) =>
              singleSupplier.length > 0 && (
                <span key={key}>
                  <div className="d-flex flex-row">
                    <label
                      className="mb-0 delivery_web_text"
                      style={{
                        color: "#51057C",
                        textTransform: "uppercase",
                      }}
                    >
                      <h6>{singleSupplier[0].supplierName}</h6>
                    </label>
                  </div>
                  {singleSupplier.map((singleTimeSlot, index) => (
                    <div
                      className="mb-2 ml-2 d-flex flex-row align-items-baseline mt-1"
                      key={index}
                    >
                      <input
                        type="radio"
                        name={singleTimeSlot.supplierName}
                        value={
                          singleTimeSlot.startDateTime +
                          "_" +
                          singleTimeSlot.endDateTime
                        }
                        key={index}
                        checked={singleTimeSlot.checked}
                        onChange={() => OnChange({ ...singleTimeSlot })}
                      />
                      <div className="d-flex flex-column">
                        <span className="mb-0 ml-2">
                          {moment
                            .tz(singleTimeSlot.startDateTime, "Asia/Singapore")
                            .format("DD-MM-YYYY hh:mm A")}{" "}
                          -{" "}
                          {moment
                            .tz(singleTimeSlot.endDateTime, "Asia/Singapore")
                            .format("DD-MM-YYYY hh:mm A")}{" "}
                          ({singleTimeSlot.type}){" "}
                          {singleTimeSlot.numberOfSlots -
                            singleTimeSlot.numberOfSlotsBooked <=
                            10 && "(Limited Slots!)"}{" "}
                        </span>
                      </div>
                    </div>
                  ))}
                </span>
              )
          )}
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button variant="contained" onClick={handleCancel} color="secondary">
            cancel
          </Button>
          <Button variant="contained" onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TimeSlotsSelection;
