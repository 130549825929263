import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import "./style.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

let moment = require("moment-timezone");

const MassMessage = (props) => {
  const startDayDateTime = moment()
    .tz("Asia/Singapore")
    .startOf("day")
    .format();
  const endDayDateTime = moment().tz("Asia/Singapore").endOf("day").format();
  const [search, setSearch] = useState({
    orderStatus: "all",
    fromDate: new Date(startDayDateTime),
    toDate: new Date(endDayDateTime),
  });
  const [options, setOptions] = useState([
    "All",
    "Active",
    "Confirmed",
    "Cancel",
    "Paid",
    "UnPaid",
  ]);
  const [defaultOption, setDefaultOption] = useState(options[0]);

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  const onChangeStartTime = (date) => {
    const commonData = { ...search };
    commonData.fromDate = date;
    return setSearch(commonData);
  };

  const onChangeEndTime = (date) => {
    const commonData = { ...search };
    commonData.toDate = date;
    return setSearch(commonData);
  };

  const submitMassMassage = () => {
    props.actions.adminAction.sendMessageToAll(
      adminReducers.selectedPage.FbPageId,
      search
    );
  };

  const onChangeDropDown = (value) => {
    const commonData = { ...search };
    commonData.orderStatus = value.value.toLowerCase();
    setDefaultOption(value);
    return setSearch(commonData);
  };

  return (
    <div className="d-flex flex-column" style={{ gap: "20px" }}>
      <Card
        className="p-3 gap-20 d-flex flex-column align-items-start"
        style={{ overflow: "unset" }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="d-flex align-items-baseline justify-content-start filter">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start Date"
              format="dd/MM/yyyy"
              value={search.fromDate}
              onChange={onChangeStartTime}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="m-0"
            />

            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Start Time"
              value={search.fromDate}
              onChange={onChangeStartTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              className="m-0"
            />

            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End Date"
              format="dd/MM/yyyy"
              value={search.toDate}
              onChange={onChangeEndTime}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="m-0"
            />

            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="End Time"
              value={search.toDate}
              onChange={onChangeEndTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              className="m-0"
            />
          </div>
        </MuiPickersUtilsProvider>
      </Card>
      <Card
        className="p-3 gap-20 d-flex flex-column align-items-start"
        style={{ overflow: "unset" }}
      >
        <Dropdown
          onChange={onChangeDropDown}
          options={options}
          value={defaultOption}
          className="orderStatusWidth"
          placeholder="Select an option"
        />

        <Button variant="contained" color="primary" onClick={submitMassMassage}>
          Send Mass Messages
        </Button>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MassMessage);
