import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import * as customerAction from "../../actions/customerAction";
import Loader from '../Loader/index';
import {browserHistory} from "react-router";
import ENVIRONMENT_VARIABLES from "../../environment.config";

const CustomerFbCallBack = (props) => {

    useEffect(() => {
        if (props.location.query.access_token) {
            props.actions.customerAction.loginUser(props.location.query.access_token);
        }
    }, []);

    const customerReducers = useSelector((state) => ({
        loading: state.customerReducer.loading,
        isAuthenticated: state.customerReducer.isAuthenticated
    }));

    useEffect(() => {
        if (customerReducers.isAuthenticated) {
            const token = localStorage.getItem('token').toString();
            if (token && token !== "" && token !== "null") {
                window.location.replace(ENVIRONMENT_VARIABLES.Base_API_URL + `/Oauths/verifyUser?UserToken=${props.location.query.access_token}&VerifyToken=${token}`);
            } else {
                browserHistory.push('/CustomerDashBoard');
            }
        }
    }, [customerReducers.isAuthenticated]);

    return (
        <div>
            {customerReducers.loading && <Loader/>}
        </div>
    );

};

const mapDispatchToProps = dispatch => ({
    actions: {
        customerAction: bindActionCreators(customerAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(CustomerFbCallBack);
