import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Box, Button, Card, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as adminAction from "../../../actions/authAction";

import EditDialog from "./editDialog";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import ENVIRONMENT_VARIABLES from "../../../environment.config";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#51057C",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditOrders = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const adminReducers = useSelector((state) => ({
    loading: state.adminReducer.loading,
    error_msg: state.adminReducer.error_msg,
    success_msg: state.adminReducer.success_msg,
    checkOutOrder: state.adminReducer.checkOutOrder,
    couponDetail: state.adminReducer.couponDetail,
    selectTimeSlot: state.adminReducer.selectTimeSlot,
    ShippingCharge: state.adminReducer.ShippingCharge,
    confirmDeliverySlot: state.adminReducer.confirmDeliverySlot,
  }));

  const [editOpen, setEditOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [order, setOrder] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(null);
  const [orderDetail, setOrderDetail] = useState(props.editItem);

  const handleEditSubmitClose = (editItems) => {
    setEditOpen(false);
    let order = _.cloneDeep(orderDetail);

    let subTotal = 0;

    order.Items.map((item) => {
      if (item.id !== editItems.id) {
        subTotal = subTotal + Number(item.price);
      }
    });

    let orderItemFind = order.Items.find((data) => data.id === editItems.id);
    orderItemFind.qty = editItems.qty;
    orderItemFind.total = editItems.total;
    subTotal = subTotal + Number(editItems.total);
    order.total = Number(subTotal);
    props.actions.adminAction.updateAdminOrderDetail({
      Order: order,
      OrderStatus: order.Status,
    });

    setOrderDetail(order);
  };

  const HandleEditOpen = (item) => {
    setEditItem(item);
    setEditOpen(true);
  };

  const HandleEditClose = () => {
    setEditOpen(false);
  };

  return (
    <div>
      {editItem && editOpen && (
        <EditDialog
          editItem={editItem}
          handleClose={HandleEditClose}
          open={editOpen}
          handleEditSubmitClose={handleEditSubmitClose}
        />
      )}

      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {orderDetail.name}
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="edit-order">
          <div className="order-table w-100">
            <div className="d-flex align-items-center justify-content-between w-100">
              <Button variant="outlined" className="upload-inners">
                <a
                  className="btn-link"
                  href={`${ENVIRONMENT_VARIABLES.Base_CHECKOUT_URL}${orderDetail._id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className=""> Link to Checkout</span>
                </a>
              </Button>
              <Button
                variant="contained"
                className="mt-2"
                color="primary"
                onClick={props.orderPaid}
                disabled={orderDetail?.Status !== "confirmed"}
              >
                Mark as Paid
              </Button>
            </div>

            {orderDetail && (
              <Card className="order-id row">
                <div className="card-row col-sm-6">
                  <span className="id-name">Order id:-</span>
                  <span>{orderDetail._id}</span>
                </div>
                <div className="card-row col-sm-6">
                  <span className="id-name">Order Status:-</span>
                  <span>{orderDetail.Status}</span>
                </div>
                <div className="card-row col-sm-6">
                  <span className="id-name">Name:-</span>
                  <span>{orderDetail.name}</span>
                </div>
              </Card>
            )}

            <Box margin={1} className="w-100">
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>ItemName</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Qty</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Price</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Total price ($)</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Edit</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetail?.Items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.itemName}</TableCell>
                        <TableCell align="center">{item.qty}</TableCell>
                        <TableCell align="right">
                          {Number(item.price).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {Number(item.total).toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="edit"
                            onClick={() => HandleEditOpen(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <div className="shipping-details">
              <div className="responsive_div">
                <h4>Shipping Details</h4>
                <div className="shipping-details-input">
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="ShippingName"
                        label="ShippingName"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.ShippingName}
                      />
                    </div>
                  </div>
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="ShippingMobile"
                        label="ShippingMobile"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.ShippingMobile}
                      />
                    </div>
                  </div>
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="ShippingAddress1"
                        label="ShippingAddress1"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.ShippingAddress1}
                      />
                    </div>
                  </div>
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="ShippingPostalCode"
                        label="ShippingPostalCode"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.ShippingPostalCode}
                      />
                    </div>
                  </div>
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="Residential_Type"
                        label="Residential_Type"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.Residential_Type}
                      />
                    </div>
                  </div>
                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="FeedBackMessage"
                        label="FeedBackMessage"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.FeedBackMessage}
                      />
                    </div>
                  </div>

                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="ShippingEmail"
                        label="ShippingEmail"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.ShippingEmail}
                      />
                    </div>
                  </div>

                  <div className="shipping-box">
                    <div className="box">
                      <TextField
                        fullWidth
                        id="RemarkMessage"
                        label="RemarkMessage"
                        variant="outlined"
                        size="small"
                        disabled
                        value={orderDetail.order.RemarkMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>

          <Card className="order-summary">
            <h5>Payment Summary</h5>
            <div className="card-row">
              <span>Sub Total</span>
              <span>${orderDetail.total.toFixed(2)}</span>
            </div>
            {orderDetail.ShippingSupplier &&
              orderDetail.ShippingSupplier.map((supplier, index) => (
                <tr key={index}>
                  <td colSpan={2}></td>
                  <td>
                    <h6> &#8226; {supplier.supplierName}</h6>
                  </td>
                  <td className="text-right">
                    ${Number(supplier.shippingCharge).toFixed(2)}
                  </td>
                </tr>
              ))}
            <div className="card-row">
              <span>TOTAL SHIPPING CHARGE </span>
              <span>${orderDetail.ShippingCharge.toFixed(2)}</span>
            </div>
            <div className="card-row">
              <span>Discount</span>
              <span>${orderDetail.DiscountAmount.toFixed(2)}</span>
            </div>
            <div className="card-row">
              <span>Point Discount</span>
              <span>${orderDetail.DiscountAmountPoint.toFixed(2)}</span>
            </div>
            <div className="card-row">
              <span>Grand Total</span>
              <span>
                $
                {orderDetail.total +
                  orderDetail.ShippingCharge -
                  (orderDetail.DiscountAmount +
                    orderDetail.DiscountAmountPoint) >=
                0
                  ? (
                      orderDetail.total +
                      orderDetail.ShippingCharge -
                      (orderDetail.DiscountAmount +
                        orderDetail.DiscountAmountPoint)
                    ).toFixed(2)
                  : 0}
              </span>
            </div>
          </Card>
        </div>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(EditOrders);
