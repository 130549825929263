import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SearchUserPointSummaryReport from "./SearchUserPointSummaryReport";
import ReactExport from "react-export-excel";
import GetAppIcon from "@material-ui/icons/GetApp";
import ImageLoader from "react-load-image";

import ENVIRONMENT_VARIABLES from "../../../environment.config";
import * as adminAction from "../../../actions/authAction";
import "./style.css";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const columns = [
  { id: "Profile", label: "Profile", minWidth: 90 },
  { id: "Name", label: "Name", minWidth: 40 },
  { id: "FbId", label: "FbId", minWidth: 50 },
  { id: "Email", label: "Email Address", minWidth: 10 },
  { id: "PointsUsed", label: "Points Used", minWidth: 10 },
  { id: "PointsEarned", label: "Points Earned", minWidth: 10 },
];

function createData(Name, FbId, Email, PointsUsed, PointsEarned) {
  return {
    Name,
    FbId,
    Email,
    PointsUsed: PointsUsed.toFixed(2),
    PointsEarned: PointsEarned.toFixed(2),
  };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 267px)",
  },
});

const UserPointSummaryReport = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState([]);
  const [excelData, setExcelData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    userPointSummaryReport: state.adminReducer.userPointSummaryReport,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    const allData = [];
    let exceldata = [];
    let colors = ["FF0000FF", "FF00FF00", "FFF86B00", "FFFFFF00", "FFCCEEFF"];
    let colorCount = 4;

    adminReducers.userPointSummaryReport.map((singleData) => {
      allData.push(
        createData(
          singleData.Name,
          singleData.FbId,
          singleData.email,
          singleData.PointsUsed,
          singleData.PointsEarned,
          Number(singleData.PointsEarned).toFixed(2),
          Number(singleData.PointsUsed).toFixed(2),
          singleData.Opt_In.toString(),
          singleData.FbSPID,
          moment
            .tz(singleData.Date, "Asia/Singapore")
            .format("DD-MM-YYYY hh:mm A")
            .toString(),
          singleData._id,
          singleData.UserCode
        )
      );
      let FbId = "";
      let Name = "";
      let Email = "";
      let PointsUsed = "";
      let PointsEarned = "";

      if (singleData.FbId && singleData.FbId !== null) {
        FbId = singleData.FbId;
      }
      if (singleData.Name && singleData.Name !== null) {
        Name = singleData.Name;
      }
      if (singleData.email && singleData.email !== null) {
        Email = singleData.email;
      }
      if (singleData.PointsUsed && singleData.PointsUsed !== null) {
        PointsUsed = singleData.PointsUsed.toFixed(2);
      }
      if (singleData.PointsEarned && singleData.PointsEarned !== null) {
        PointsEarned = singleData.PointsEarned.toFixed(2);
      }

      const data = [
        {
          value: FbId,
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Name,
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: Email.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: PointsUsed.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
        {
          value: PointsEarned.toString(),
          style: {
            fill: {
              patternType: "solid",
              fgColor: { rgb: colors[colorCount] },
            },
          },
        },
      ];
      exceldata.push(data);
    });

    let data = [
      {
        columns: [
          "FbId",
          "Name",
          "Email Address",
          "PointsUsed",
          "PointsEarned",
        ],
        data: exceldata.length > 0 ? exceldata : ["     "],
      },
    ];

    setRows(allData);
    setExcelData([...data]);
    setPage(0);
  }, [adminReducers.userPointSummaryReport]);

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchUserPointSummaryReport />

        <ExcelFile
          element={
            <Button variant="contained" className="but_width" color="primary">
              <GetAppIcon />
            </Button>
          }
          filename="UserPointSummaryReport"
        >
          <ExcelSheet dataSet={excelData} name="Organization" />
        </ExcelFile>
      </Card>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "Orders") {
                          return (
                            <TableCell
                              key={`${index}+${value}`}
                              align={"right"}
                            >
                              <Button
                                variant="contained"
                                key={row.id}
                                className="btn show_order"
                                color="primary"
                                onClick={() => showOrders(row.FbId, row.FbSPID)}
                              >
                                Orders
                              </Button>
                            </TableCell>
                          );
                        } else if (column.id === "ReferralOrders") {
                          return (
                            <TableCell key={index} align={"right"}>
                              <Button
                                variant="contained"
                                key={row.id}
                                className="btn show_order"
                                color="primary"
                                onClick={() =>
                                  showReferralOrders(row.FbId, row.FbSPID)
                                }
                              >
                                Show
                              </Button>
                            </TableCell>
                          );
                        } else if (column.id === "Profile") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <ImageLoader
                                src={
                                  ENVIRONMENT_VARIABLES.IMAGE_URL +
                                  row.FbId +
                                  ".jpg"
                                }
                              >
                                <img
                                  className="img-fluid"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/NoImages.png"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                                <img
                                  src="/assets/image/imageLoader.png"
                                  style={{ height: "60px", width: "60px" }}
                                  alt={row.FbId}
                                />
                              </ImageLoader>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(UserPointSummaryReport);
