import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import "date-fns";

import * as adminAction from "../../../actions/authAction";
import "./style.css";

let moment = require("moment-timezone");

const SearchBar = (props) => {
  const startDayDateTime = moment()
    .tz("Asia/Singapore")
    .startOf("day")
    .format();
  const endDayDateTime = moment().tz("Asia/Singapore").endOf("day").format();
  const [search, setSearch] = useState({
    fromDate: new Date(startDayDateTime),
    toDate: new Date(endDayDateTime),
  });

  useEffect(() => {
    props.actions.adminAction.getSearchNewRegistrationFilter(search);
  }, []);

  const onChangeStartTime = (date) => {
    const commonData = { ...search };
    commonData.fromDate = date;
    return setSearch(commonData);
  };

  const onChangeEndTime = (date) => {
    const commonData = { ...search };
    commonData.toDate = date;
    return setSearch(commonData);
  };

  const searchButton = () => {
    props.actions.adminAction.getSearchNewRegistrationFilter(search);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="d-flex align-items-baseline filter">
        <KeyboardDatePicker
          className="filter-box m-0"
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
          format="dd/MM/yyyy"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardDatePicker
          className="filter-box m-0"
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
          format="dd/MM/yyyy"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <Button variant="contained" color="primary" onClick={searchButton}>
          <SearchIcon />
        </Button>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SearchBar);
