import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import "./style.css";

export const AddDialog = (props) => {
  const [keyword, setKeyword] = useState({
    FbPageId: "",
    supplierName: "",
    country: props.country,
    minimum: 0,
    shippingCharge: 0,
    offline: false,
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newKeywordDetail = { ...keyword };
    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleSubmitClose = (keyword) => {
    props.handleSubmitClose(keyword);
    setKeyword({
      FbPageId: "",
      supplierName: "",
      country: props.country,
      minimum: 0,
      shippingCharge: 0,
      offline: false,
      message: "",
    });
  };

  const handleChangeSwitch = () => {
    let newKeywordDetail = { ...keyword };
    newKeywordDetail.offline = !newKeywordDetail.offline;
    setKeyword(newKeywordDetail);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle id="form-dialog-title">
            Add New Delivery Fees
          </DialogTitle>
          <DialogContent>
            <div className="row" style={{ gridRowGap: "20px" }}>
              <div className="col-6">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Supplier"
                  name="supplierName"
                  value={keyword.supplierName}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  // className="flex-grow-1"
                  label="Minimum Amount"
                  name="minimum"
                  type="number"
                  value={keyword.minimum}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Shipping Charge"
                  name="shippingCharge"
                  type="number"
                  value={keyword.shippingCharge}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <label> Offline </label> &nbsp; &nbsp;
                <Switch
                  checked={keyword.offline}
                  onChange={handleChangeSwitch}
                  name="DirectComment"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Message"
                  name="message"
                  type="text"
                  value={keyword.message}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Country"
                  name="country"
                  type="text"
                  validators={["required"]}
                  value={keyword.country}
                  errorMessages={["this field is required"]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default AddDialog;
