import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {bindActionCreators} from "redux";
import * as customerAction from "../../actions/customerAction";
import {connect, useSelector} from "react-redux";
import {browserHistory} from "react-router";
import Loader from '../Loader'
import swal from 'sweetalert';
import Orders from './orders';
import Button from '@material-ui/core/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Transactions from './transaction';
import {
    TelegramShareButton,
    WhatsappShareButton,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import CommentsDisplay from './commentsDisplay';
import {connection, newSocketMessage, disconnect} from "../../socket";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        backgroundColor: '#51057C'
    }
}));

const CustomerDashBoard = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [copyValue, setCopyValue] = useState("");
    const [copied, setCopied] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [fbUserPage, setFbUserPage] = useState(null);
    const [telegramMessage, setTelegramMessage] = useState("");
    const [whatsappMessage, setWhatsappMessage] = useState("");
    const [emailMessage, setEmailMessageMessage] = useState("");
    const [prefix, setPrefix] = useState("");
    const [data, setData] = useState({
        DirectComment: ""
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const customerReducers = useSelector((state) => ({
        loading: state.customerReducer.loading,
        error_msg: state.customerReducer.error_msg,
        success_msg: state.customerReducer.success_msg,
        logout: state.customerReducer.logout,
        fbUserProfile: state.customerReducer.fbUserProfile,
        fbUserPage: state.customerReducer.fbUserPage,
        orderNumber: state.customerReducer.orderNumber
    }));

    useEffect(() => {
        props.actions.customerAction.getHomeCall();
    }, []);

    useEffect(() => {
        if (customerReducers.logout === true) {
            if (userProfile.FbPageId === "669953996431156")
                browserHistory.push('/SHC/Login');
            else if (userProfile.FbPageId === "668035659907255")
                browserHistory.push('/AudioHouse/Login');
            else if (userProfile.FbPageId === "100111835796876")
                browserHistory.push('/ThePropertyLive/Login');
                else if (userProfile.FbPageId === "118919927856470")
                browserHistory.push('/JapanSHC/Login');
                else if (userProfile.FbPageId === "100447339700065")
                browserHistory.push('/CNSHC/Login');
            else
                browserHistory.push('/AllDeveloper/Login');
        }
    }, [customerReducers.logout]);

    useEffect(() => {
        if (customerReducers.orderNumber) {
            browserHistory.push("/checkout/" + customerReducers.orderNumber);
        }
    }, [customerReducers.orderNumber]);

    useEffect(() => {
        if (customerReducers.fbUserPage && customerReducers.fbUserPage !== null) {
            setFbUserPage(customerReducers.fbUserPage);
            setTelegramMessage(customerReducers.fbUserPage.TelegramMessage);
            setWhatsappMessage(customerReducers.fbUserPage.WhatsAppMessage);
            setEmailMessageMessage(customerReducers.fbUserPage.EmailMessage);
            setPrefix(customerReducers.fbUserPage.CodePrefix);
        }
    }, [customerReducers.fbUserPage]);

    useEffect(() => {
        if (customerReducers.fbUserProfile !== undefined && customerReducers.fbUserProfile !== null) {
            disconnect();
            setUserProfile(_.cloneDeep(customerReducers.fbUserProfile));
            const CopyData = `Hey There! 🙂 \n\n My Referral Code is [${customerReducers.fbUserProfile.UserCode}]!`;
            setCopyValue(CopyData);
            connection();
            const messageKey = customerReducers.fbUserProfile.FbPageId;
            newSocketMessage(messageKey, (err, data) => {
                switch (data.type) {
                    case 'NewComment':
                        props.actions.customerAction.addNewComment(data.data);
                        break;
                }
            });
        }
    }, [customerReducers.fbUserProfile]);

    useEffect(() => {
        if (customerReducers.success_msg !== null) {
            swal("", customerReducers.success_msg, "success");
            setData({
                DirectComment: ""
            });
        }
    }, [customerReducers.success_msg]);

    useEffect(() => {
        if (customerReducers.error_msg !== null) {
            if (customerReducers.error_msg === "Failed to authenticate token.") {
                localStorage.removeItem("fbAccessToken");
                localStorage.clear();
                browserHistory.push('/SHC/Login');
            } else {
                swal('Oops...', customerReducers.error_msg.toString(), 'error');
            }
        }
    }, [customerReducers.error_msg]);

    const updateDetail = () => {
        if (userProfile.ShippingMobile !== "" && userProfile.ShippingMobile !== null && userProfile.ShippingAddress1 !== "" && userProfile.ShippingAddress1 !== null && userProfile.ShippingPostalCode !== "" && userProfile.ShippingPostalCode !== null && userProfile.email !== "" && userProfile.email !== null) {
            props.actions.customerAction.updateProfileDetail({
                ShippingMobile: userProfile.ShippingMobile,
                ShippingAddress1: userProfile.ShippingAddress1,
                ShippingPostalCode: userProfile.ShippingPostalCode,
                email: userProfile.email,
            });
        } else {
            swal('Incorrect Information', "All Fields are required", 'error');
        }
    };

    const handleChangeDetail = (event) => {
        const field = event.target.name;
        const commonData = {...userProfile};
        commonData[field] = event.target.value;
        return setUserProfile(commonData);
    };

    const logout = () => {
        props.actions.customerAction.FBLogout();
    };

    const createCart = () => {
        props.actions.customerAction.CreateCart();
    };

    const getMonthsWiseOrders = (months) => {
        props.actions.customerAction.GetUsersOrder(months);
    };

    const handleChangeDirectComment = (event) => {
        const field = event.target.name;
        const commonData = {...data};
        commonData[field] = event.target.value;
        return setData(commonData);
    };

    const DirectCommentSubmit = () => {
        if (data.DirectComment && data.DirectComment !== "" && data.DirectComment !== null) {
            props.actions.customerAction.DirectComment(data.DirectComment);
            const commonData = {...data};
            commonData.DirectComment = '';
            return setData(commonData);
        }
    };

    return (
        <div>
            <div className={classes.root}>

                <div className="logoutButton">
                    <h3 className="pl-5 bg-color mb-0 py-3 headerStyle" style={{color: "#fff"}}>
                        OrdrStartr
                    </h3>

                    <Button variant="contained" color="secondary"
                            onClick={logout}>Logout</Button>
                </div>


                <AppBar position="static" className={classes.appBar}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Points" {...a11yProps(0)} />
                        <Tab label="Orders" {...a11yProps(1)} />
                        <Tab label="Detail" {...a11yProps(2)} />
                        {/* <Tab label="Direct Comment" {...a11yProps(3)} /> */}
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    {(userProfile !== null) && <div>
                        <h5> {prefix} Points : &nbsp; {Number(userProfile.PointsEarned).toFixed(2)}</h5>
                        <h5> {prefix} Points spent : &nbsp; {Number(userProfile.PointsUsed).toFixed(2)}</h5>
                        {(fbUserPage && fbUserPage.ShareButtonVisible) && <span>
                            <h5> My Referral code : &nbsp; {userProfile.UserCode}</h5>
                        <WhatsappShareButton
                            url={whatsappMessage.replace("{code}", userProfile.UserCode)}
                            title={""}
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round/>
                        </WhatsappShareButton>
                            &nbsp;
                            <TelegramShareButton
                                url={telegramMessage.replace("{code}", userProfile.UserCode)}
                                title={""}
                                className="Demo__some-network__share-button"
                            >
                            <TelegramIcon size={32} round/>
                        </TelegramShareButton>
                            {/*&nbsp;*/}
                            {/*<EmailShareButton*/}
                            {/*url={""}*/}
                            {/*subject={`You have a referral from (${userProfile.Name})  `}*/}
                            {/*body={emailMessage.replace("{code}", userProfile.UserCode)}*/}
                            {/*className="Demo__some-network__share-button"*/}
                            {/*>*/}
                            {/*<EmailIcon size={32} round/>*/}
                            {/*</EmailShareButton>*/}
                            &nbsp;
                            <CopyToClipboard text={copyValue}
                                             onCopy={() => setCopied(true)}>
                            <button>Copy Code</button>
                        </CopyToClipboard>
                            {copied ? <span style={{color: 'red'}}>Copied.</span> : null}
                        </span>
                        }

                        {(fbUserPage && fbUserPage.Is_OfflinePurchase) && <div>
                            <br/>
                            <Button variant="contained" color="primary" onClick={createCart}>CREATE SHOPPING
                                CART</Button>
                        </div>}

                        <br/>
                        <Transactions/>

                    </div>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Orders getMonthsWiseOrders={getMonthsWiseOrders}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {(userProfile !== null) && <div>
                        <div className="d-flex flex-column textBoxWidth">
                            <label>Name</label>
                            <input name="Name"
                                   type="text"
                                   disabled={true}
                                   className="form-control"
                                   value={userProfile.Name} placeholder={"Name"}/>
                        </div>

                        <br/>

                        <div className="d-flex flex-column textBoxWidth">
                            <label>Shipping Address</label>
                            <input name="ShippingAddress1"
                                   type="text"
                                   className="form-control"
                                   onChange={handleChangeDetail}
                                   value={userProfile.ShippingAddress1} placeholder={"Shipping Address"}/>
                        </div>

                        <br/>

                        <div className="d-flex flex-column textBoxWidth">
                            <label>Shipping Mobile</label>
                            <input name="ShippingMobile"
                                   type="number"
                                   className="form-control"
                                   onChange={handleChangeDetail}
                                   value={userProfile.ShippingMobile} placeholder={"Shipping Mobile"}/>
                        </div>

                        <br/>

                        <div className="d-flex flex-column textBoxWidth">
                            <label>Shipping PostalCode</label>
                            <input name="ShippingPostalCode"
                                   type="number"
                                   className="form-control"
                                   onChange={handleChangeDetail}
                                   value={userProfile.ShippingPostalCode} placeholder={"Shipping PostalCode"}/>
                        </div>

                        <br/>

                        <div className="d-flex flex-column textBoxWidth">
                            <label>Email</label>
                            <input name="email"
                                   type="email"
                                   className="form-control"
                                   pattern=".+@globex.com"
                                   onChange={handleChangeDetail}
                                   value={userProfile.email} placeholder={"Email"}/>
                        </div>

                        <br/>

                        <div>
                            <Button variant="contained" color="primary"
                                    onClick={updateDetail}>Save</Button>
                        </div>

                    </div>}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <div>
                        {(fbUserPage && fbUserPage.Is_VisibleDirectComment) ? <span>
                            <div className="d-flex flex-column textBoxWidth">
                            <div dangerouslySetInnerHTML={{__html: fbUserPage.LiveVideoIFrame}}/>
                            <CommentsDisplay/>
                            <input name="DirectComment"
                                   type="text"
                                   className="form-control mt-3"
                                   value={data.DirectComment} onChange={handleChangeDirectComment}
                                   placeholder={"Direct Comment (Product Code + Qty)"}/>
                        </div>
                        <div className="d-flex flex-row mt-3 w-25 justify-content-end">
                            <Button variant="contained" color="primary" onClick={DirectCommentSubmit}>Save</Button>
                        </div>
                        </span> : <div>
                            <h2> There is no event running for now. </h2>
                        </div>}
                    </div>
                </TabPanel>
            </div>
            {customerReducers.loading && <Loader/>}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: {
        customerAction: bindActionCreators(customerAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(CustomerDashBoard);
