import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import {browserHistory} from 'react-router';
import 'react-toastify/dist/ReactToastify.min.css';
import Loader from '../Loader/index';
import * as authAction from '../../actions/authAction';
import './login.css';
import ENVIRONMENT_VARIABLES from '../../environment.config';
import {getCustomerData} from "../../index";
import $ from "jquery";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {credentials: {userId: '', password: ''}};
        $('body > .overlay').removeClass("blocked !important");
    }

    notify = (message) => toast.error(message);


    removeToaster(toastId) {
        toast.dismiss(toastId);
        this.setState({toastId: ""})
    };

    handleChange = (event) => {
        const field = event.target.name;
        const credentials = this.state.credentials;
        credentials[field] = event.target.value;
        return this.setState({credentials: credentials});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.state.toastId && this.removeToaster(this.state.toastId);
        if (this.state.credentials.userId.trim() !== "" && this.state.credentials.password.trim() !== "") {
            this.props.actions.auth.loginUser(this.state.credentials);
        } else {
            let toastId = this.notify("Please Enter Username and Password");
            this.setState({toastId: toastId});
        }
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loading && nextProps.error_msg) {
            let toastId = this.notify(nextProps.error_msg);
            this.setState({toastId: toastId});
        }
        if (nextProps.isAuthenticated && nextProps.token !== null) {
            const userData = getCustomerData(nextProps.token);
            if (userData && userData.userRole === "crm")
                browserHistory.push('/CRM/Dashboard');
            else
                browserHistory.push('/Dashboard');
        }
    }

    FbLogin = () => {
        window.location.replace(ENVIRONMENT_VARIABLES.Base_API_URL + "/Oauths/facebook");
    };

    render() {
        return (
            <div>
                <ToastContainer
                    position="top-right"
                    type="default"
                    autoClose={6000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                />
                <div className="d-flex facebook-login flex-column">

                    <img src={"/assets/image/logo.png"} width={"40%"}/>
                    &nbsp;

                    <button className="loginBtn loginBtn--facebook" onClick={this.FbLogin}>
                        Login with Facebook
                    </button>

                    <span className="or-text">or</span>
                    <div className="d-flex flex-column w-100">
                        <div className="form-group">
                            <input type="text"
                                   value={this.state.credentials.userId}
                                   name="userId" placeholder="User Name"
                                   onChange={this.handleChange}
                                   className="form-control-text"/>
                        </div>
                        <div className="form-group">
                            <input type="password"
                                   value={this.state.credentials.password}
                                   name="password" placeholder="Password"
                                   onChange={this.handleChange}
                                   className="form-control-text"/>
                        </div>
                        <button type="button"
                                className="btn2 w-100"
                                onClick={this.handleSubmit}>LogIn
                        </button>
                        <div className="d-flex flex-column w-100 all-link">
                        </div>
                    </div>
                </div>
                {this.props.loading && <Loader/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {adminReducer} = state;
    return {
        loading: adminReducer.loading,
        error_msg: adminReducer.error_msg,
        isAuthenticated: adminReducer.isAuthenticated,
        token: adminReducer.token,
        newUserSuccess: adminReducer.newUserSuccess
    }
};

const mapDispatchToProps = dispatch => ({
    actions: {
        auth: bindActionCreators(authAction, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)


