import React, { useEffect, useState } from "react";
import SearchOrderItems from "../SearchOrderItems";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");
const columns = [
  { id: "itemName", label: "Item Name", minWidth: 170 },
  { id: "qty", label: "Qty", minWidth: 100, align: "center" },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 170,
  },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "center",
  },
  {
    id: "keyword",
    label: "Keyword",
    minWidth: 170,
  },
  {
    id: "sku",
    label: "SKU",
    minWidth: 170,
  },
  {
    id: "price",
    label: "Price($)",
    minWidth: 170,
    align: "right",
  },
];

function createData(itemName, qty, customerName, price, date, keyword, sku) {
  return { itemName, qty, customerName, price, date, keyword, sku };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 246px)",
  },
});

const OrderItemsDisplay = () => {
  const [search, setSearch] = useState({
    orderItemName: "",
    orderSKU: "",
    orderName: "",
    fromDate: new Date(),
    toDate: new Date(),
  });

  const setSearchData = (searchData) => {
    setSearch(searchData);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    orderItems: state.adminReducer.orderItems,
  }));

  useEffect(() => {
    if (
      adminReducers.orderItems !== null &&
      adminReducers.orderItems !== undefined
    ) {
      let orders = [];
      adminReducers.orderItems.map((singleOrder) => {
        singleOrder.Items.map((singleItem) => {
          if (search.orderItemName !== "") {
            if (
              singleItem.itemName
                .toLowerCase()
                .includes(search.orderItemName.toLowerCase())
            ) {
              if (search.orderSKU !== "") {
                if (
                  singleItem.SKU.toLowerCase().includes(
                    search.orderSKU.toLowerCase()
                  )
                ) {
                  orders.push(
                    createData(
                      singleItem.itemName,
                      singleItem.qty,
                      singleOrder.Name,
                      singleItem.price,
                      moment
                        .tz(singleItem.Date, "Asia/Singapore")
                        .format("DD-MM-YYYY hh:mm A")
                        .toString(),
                      singleItem.keyword,
                      singleItem.SKU
                    )
                  );
                }
              } else {
                orders.push(
                  createData(
                    singleItem.itemName,
                    singleItem.qty,
                    singleOrder.Name,
                    singleItem.price,
                    moment
                      .tz(singleItem.Date, "Asia/Singapore")
                      .format("DD-MM-YYYY hh:mm A")
                      .toString(),
                    singleItem.keyword,
                    singleItem.SKU
                  )
                );
              }
            }
          } else if (search.orderSKU !== "") {
            if (
              singleItem.SKU.toLowerCase().includes(
                search.orderSKU.toLowerCase()
              )
            ) {
              orders.push(
                createData(
                  singleItem.itemName,
                  singleItem.qty,
                  singleOrder.Name,
                  singleItem.price,
                  moment
                    .tz(singleItem.Date, "Asia/Singapore")
                    .format("DD-MM-YYYY hh:mm A")
                    .toString(),
                  singleItem.keyword,
                  singleItem.SKU
                )
              );
            }
          } else {
            orders.push(
              createData(
                singleItem.itemName,
                singleItem.qty,
                singleOrder.Name,
                singleItem.price,
                moment
                  .tz(singleItem.Date, "Asia/Singapore")
                  .format("DD-MM-YYYY hh:mm A")
                  .toString(),
                singleItem.keyword,
                singleItem.SKU
              )
            );
          }
        });
      });
      setRows(orders);
      setPage(0);
    }
  }, [adminReducers.orderItems]);

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchOrderItems setSearchData={setSearchData} />
      </Card>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default OrderItemsDisplay;
