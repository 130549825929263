import decode from "jwt-decode";

const accessToken = localStorage.getItem("accessToken");
const fbAccessToken = localStorage.getItem("fbAccessToken");

let token = null;
let fbUser = null;
let customerToken = null;
if (accessToken !== "undefined" && accessToken !== null) {
  token = accessToken;
}

if (fbAccessToken !== "undefined" && fbAccessToken !== null) {
  customerToken = fbAccessToken;
  fbUser = decode(customerToken);
}

export default {
  customerReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    fbUserProfile: fbUser,
    fbUserPage: null,
    isAuthenticated: false,
    token: customerToken,
    orders: [],
    logout: false,
    orderNumber: null,
    transactions: [],
    months: 3,
    comments: [],
  },
  authReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    isAuthenticated: false,
    token: token,
    adminUser: null,
    isAuthenticatedFail: false,
    allKeyWords: [],
    allGroups: [],
    allLiveShow: [],
    allLivePost: [],
    allImage: [],
    allLiveShowGroup: [],
    allShow: [],
    allPages: null,
    selectedPage: null,
    orders: [],
    orderItems: [],
    userDetail: [],
    coupons: [],
    checkOutOrder: null,
    importFailOrders: null,
    importFailCoupon: null,
    outOfStockOrder: null,
    searchOrderId: null,
    couponDetail: null,
    orderSearch: null,
    liveOrderSearch: null,
    usersOrders: [],
    selectTimeSlot: null,
    confirmDeliverySlot: null,
    ShippingCharge: null,
    luckyDraw: [],
    winnerList: [],
    transactions: [],
    preRegistration: null,
    newRegistration: null,
    deliveryFees: [],
    supplierDeliveryDate: [],
    userPointSummaryReport: [],
  },
};
