import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as adminAction from "../../actions/authAction";
import Loader from '../Loader/index';
import {browserHistory} from "react-router";
import decode from 'jwt-decode';

class FbCallBack extends Component {

    constructor(props) {
        super(props);
        if (this.props.location.query.access_token) {
            const User = decode(this.props.location.query.access_token);
            if (User && !(User.user.Block)) {
                this.props.actions.adminAction.RequestNewUserAndFbLogin(this.props.location.query.access_token);
            } else {
                this.props.actions.adminAction.logout();
            }
        } else {
            this.props.actions.adminAction.logout();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loading && nextProps.error_msg) {
            this.props.actions.adminAction.logout();
        }
        if (nextProps.isAuthenticated && nextProps.token !== null) {
            browserHistory.push('/Dashboard');
        }
    }

    render() {
        return (
            <div>
                {this.props.loading && <Loader/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {adminReducer} = state;
    return {
        loading: adminReducer.loading,
        error_msg: adminReducer.error_msg,
        isAuthenticated: adminReducer.isAuthenticated,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FbCallBack);
