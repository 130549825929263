import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import "./style.css";

export const EditDialog = (props) => {
  const [keyword, setKeyword] = useState(props.editKeyword);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newKeywordDetail = { ...keyword };
    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleSubmitClose = (keyword) => {
    props.handleEditSubmitClose(keyword);
  };

  const handleChangeSwitch = () => {
    let newKeywordDetail = { ...keyword };
    newKeywordDetail.offline = !newKeywordDetail.offline;
    setKeyword(newKeywordDetail);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle id="form-dialog-title">Edit Delivery Fees</DialogTitle>
          <DialogContent className="row m-0" style={{ gridRowGap: "20px" }}>
            <div className="col-sm-6">
              <TextValidator
                fullWidth
                variant="outlined"
                size="small"
                label="Supplier"
                name="supplierName"
                value={keyword.supplierName}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
            </div>
            <div className="col-sm-6">
              <TextValidator
                fullWidth
                variant="outlined"
                size="small"
                label="Minimum Amount"
                name="minimum"
                type="number"
                value={keyword.minimum}
                validators={["required"]}
                onChange={handleChange}
                errorMessages={["this field is required"]}
              />
            </div>
            <div className="col-sm-6">
              <TextValidator
                fullWidth
                variant="outlined"
                size="small"
                label="Shipping Charge"
                name="shippingCharge"
                type="number"
                value={keyword.shippingCharge}
                validators={["required"]}
                onChange={handleChange}
                errorMessages={["this field is required"]}
              />
            </div>
            <div className="col-sm-6">
              <label> Offline </label> &nbsp; &nbsp;
              <Switch
                checked={keyword.offline}
                onChange={handleChangeSwitch}
                name="DirectComment"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <div className="col-sm-6">
              <TextValidator
                fullWidth
                variant="outlined"
                size="small"
                label="Message"
                name="message"
                type="text"
                value={keyword.message}
                onChange={handleChange}
                errorMessages={["this field is required"]}
              />
            </div>
            <div className="col-sm-6">
              <TextValidator
                fullWidth
                variant="outlined"
                size="small"
                label="country"
                name="country"
                value={keyword.country}
                validators={["required"]}
                errorMessages={["this field is required"]}
                disabled={true}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default EditDialog;
