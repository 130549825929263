import { browserHistory } from "react-router";
import {
  ADMIN_INPROGRESS,
  ADMIN_ERROR,
  IS_AUTHENTICATED,
  LOGOUT,
  ALL_KEYWORDS,
  DELETE_KEYWORDS,
  ADD_KEYWORDS,
  SELECTED_PAGE,
  PAGE_LOAD_INTO_LOCAL,
  ADD_ORDER,
  CHANGE_PAGE_STATUS,
  EDIT_KEYWORDS,
  EDIT_KEYWORDS_QTY,
  SEND_FB_MESSAGE,
  GET_USER_ORDER,
  EDIT_ORDER,
  EDIT_PAGE_DETAIL,
  CHECK_OUT,
  IMPORT_ORDER,
  SEARCH_ORDER,
  ADD_COUPONS,
  EDIT_COUPON,
  DELETE_COUPON,
  GET_COUPON,
  SEARCH_ORDER_FILTER,
  SEARCH_ORDER_ITEMS_FILTER,
  USER_ORDERS,
  ADMIN_INPROGRESS_ORDER,
  SEARCH_USER_DETAIL_FILTER,
  SELECT_TIME_SLOT,
  CONFIRM_TIME_SLOT,
  GET_COUPONS,
  EDIT_ALL_KEYWORDS,
  ADD_LUCKY_DRAW,
  WINNER_LIST,
  SEARCH_TRANSACTIONS_FILTER,
  DELIVERY_SLOTS_PAGE_DETAIL,
  SEARCH_PREREGISTRATION_FILTER,
  SEARCH_NEWREGISTRATION_FILTER,
  EDIT_REGISTRATION_USER,
  EDIT_REGISTRATION_USER_WITH_TOKEN,
  ADD_USER_DETAILS,
  GET_DELIVERY_FEES,
  EDIT_DELIVERY_FEES,
  DELETE_DELIVERY_FEES,
  ADD_DELIVERY_FEES,
  GET_SUPPLIERS_DATES,
  ADD_SUPPLIER_DELIVERY_DATE,
  DELETE_SUPPLIER_DELIVERY_DATE,
  GET_USER_POINT_SUMMARY_REPORT,
  SEARCH_USER_POINT_SUMMARY_REPORT,
  ADD_GROUPS,
  EDIT_GROUPS,
  DELETE_GROUPS,
  ALL_GROUPS,
  GET_LIVE_SHOW,
  ADD_LIVE_ORDER,
  GET_ALL_SHOW,
  SEARCH_LIVE_ORDER_FILTER,
  GET_ALL_LIVE_POST,
  ADD_LIVE_SHOW_GROUP,
  GET_ALL_LIVE_SHOW_GROUP,
  DELETE_LIVE_SHOW_GROUPS,
  IMPORT_COUPON,
  EDIT_USER,
  UPDATE_USER_DEDUC,
  ADD_IMAGES,
  DELETE_IMAGES,
  ALL_IMAGES,
} from "../constants/actionTypes";
import initialState from "./initialState";

let moment = require("moment-timezone");

export default function adminReducer(state = initialState.authReducer, action) {
  switch (action.type) {
    case ADMIN_INPROGRESS:
      return Object.assign({}, state, {
        error_msg: null,
        success_msg: null,
        loading: true,
        isAuthenticatedFail: false,
      });

    case ADMIN_INPROGRESS_ORDER:
      return Object.assign({}, state, {
        error_msg: null,
        success_msg: null,
        loading: true,
        isAuthenticatedFail: false,
        usersOrders: [],
      });

    case ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error_msg: action.data.error_msg,
      });

    case IS_AUTHENTICATED:
      localStorage.setItem("accessToken", action.data.accessToken);
      return Object.assign({}, state, {
        isAuthenticated: true,
        error_msg: null,
        loading: false,
        token: action.data.accessToken,
      });

    case ALL_KEYWORDS:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allKeyWords: action.Keyword,
        orders: action.Orders.Orders,
      });

    case ALL_GROUPS:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allGroups: action.data,
      });

    case ALL_IMAGES:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allImage: action.data,
      });

    case GET_LIVE_SHOW:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allLiveShow: action.data.data,
      });

    case GET_ALL_LIVE_POST:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allLivePost: action.data,
      });

    case GET_ALL_LIVE_SHOW_GROUP:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allLiveShowGroup: action.data,
      });

    case GET_ALL_SHOW:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        allShow: action.data,
      });

    case GET_COUPONS:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        coupons: action.data,
      });

    case GET_DELIVERY_FEES:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        deliveryFees: action.data,
        supplierDeliveryDate: [],
      });

    case SEARCH_USER_DETAIL_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        userDetail: action.data,
      });

    case SEARCH_ORDER_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        orders: action.data.Orders,
        orderSearch: action.orderSearch,
      });

    case SEARCH_LIVE_ORDER_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        // orders: action.data.Orders,
        liveOrderSearch: action.data,
      });

    case SEARCH_ORDER_ITEMS_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        orderItems: action.data.Orders,
      });

    case SEARCH_USER_POINT_SUMMARY_REPORT:
      return {
        ...state,
        error_msg: null,
        loading: false,
        userPointSummaryReport: [...action.data],
      };

    case DELETE_KEYWORDS:
      action.data.result.map((singleKeyWords) => {
        const remove = state.allKeyWords.find(
          (data) => data._id === singleKeyWords
        );
        let index = state.allKeyWords.indexOf(remove);
        state.allKeyWords.splice(index, 1);
      });
      return {
        ...state,
        error_msg: null,
        loading: false,
        allKeyWords: [...state.allKeyWords],
      };

    case DELETE_IMAGES:
      action.data.result.map((singleKeyWords) => {
        const remove = state.allImage.find(
          (data) => data._id === singleKeyWords
        );
        let index = state.allImage.indexOf(remove);
        state.allImage.splice(index, 1);
      });
      return {
        ...state,
        error_msg: null,
        loading: false,
        allImage: [...state.allImage],
      };

    case DELETE_GROUPS:
      action.data.result.map((singleGroup) => {
        const remove = state.allGroups.find((data) => data._id === singleGroup);
        let index = state.allGroups.indexOf(remove);
        state.allGroups.splice(index, 1);
      });
      return {
        ...state,
        error_msg: null,
        loading: false,
        allGroups: [...state.allGroups],
      };

    case DELETE_LIVE_SHOW_GROUPS:
      const removeLiveGroup = state.allLiveShowGroup.find(
        (data) => data._id === action.data.result
      );
      let liveGroupIndex = state.allLiveShowGroup.indexOf(removeLiveGroup);
      state.allLiveShowGroup.splice(liveGroupIndex, 1);

      return {
        ...state,
        error_msg: null,
        loading: false,
        allLiveShowGroup: [...state.allLiveShowGroup],
      };

    case UPDATE_USER_DEDUC:
      let findUserDeduc = state.userDetail.find(
        (data) => data._id === action.data.data._id
      );
      if (findUserDeduc) {
        findUserDeduc.PointsEarned = action.data.data.PointsEarned;
        findUserDeduc.pointCollections = action.data.data.pointCollections;
        findUserDeduc.PointsUsed = action.data.data.PointsUsed;
        findUserDeduc.PointsExpiring = action.data.data.PointsExpiring;
        findUserDeduc.PointsExpired = action.data.data.PointsExpired;
      }

      return {
        ...state,
        // error_msg: action.data.errorResult,
        success_msg: action.data.result,
        loading: false,
        userDetail: [...state.userDetail],
      };

    case DELETE_COUPON:
      action.data.result.map((singleKeyWords) => {
        const remove = state.coupons.find(
          (data) => data._id === singleKeyWords
        );
        let index = state.coupons.indexOf(remove);
        state.coupons.splice(index, 1);
      });
      return {
        ...state,
        error_msg: null,
        loading: false,
        coupons: [...state.coupons],
      };

    case DELETE_DELIVERY_FEES:
      action.data.result.map((singleKeyWords) => {
        const remove = state.deliveryFees.find(
          (data) => data._id === singleKeyWords
        );
        let index = state.deliveryFees.indexOf(remove);
        state.deliveryFees.splice(index, 1);
      });
      return {
        ...state,
        error_msg: null,
        loading: false,
        deliveryFees: [...state.deliveryFees],
      };

    case DELETE_SUPPLIER_DELIVERY_DATE:
      const remove = state.supplierDeliveryDate.find(
        (data) => data._id === action.data.data
      );
      let index = state.supplierDeliveryDate.indexOf(remove);
      state.supplierDeliveryDate.splice(index, 1);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        supplierDeliveryDate: [...state.supplierDeliveryDate],
      };

    case ADD_KEYWORDS:
      state.allKeyWords.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        allKeyWords: [...state.allKeyWords],
      };

    case ADD_IMAGES:
      action.data.data.map((data) => state.allImage.push(data));
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        // allImage: action.data.data,
        allImage: [...state.allImage],
      };

    case ADD_GROUPS:
      state.allGroups.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        allGroups: [...state.allGroups],
      };

    case ADD_LIVE_ORDER:
      // state.allLiveShow.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        // allLiveShow: [...state.allLiveShow],
      };

    case ADD_LIVE_SHOW_GROUP:
      state.allLiveShowGroup.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        allLiveShowGroup: [...state.allLiveShowGroup],
      };

    case ADD_COUPONS:
      state.coupons.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        coupons: [...state.coupons],
      };

    case ADD_DELIVERY_FEES:
      state.deliveryFees.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        deliveryFees: [...state.deliveryFees],
        supplierDeliveryDate: [],
      };

    case ADD_SUPPLIER_DELIVERY_DATE:
      state.supplierDeliveryDate.push(action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        supplierDeliveryDate: [...state.supplierDeliveryDate],
      };

    case EDIT_KEYWORDS:
      let findEditKeyword = state.allKeyWords.find(
        (data) => data._id === action.data.data._id
      );
      if (findEditKeyword) {
        findEditKeyword._id = action.data.data._id;
        findEditKeyword.description = action.data.data.description;
        findEditKeyword.keyword = action.data.data.keyword;
        findEditKeyword.price = action.data.data.price;
        findEditKeyword.stock = action.data.data.stock;
        findEditKeyword.maxQty = action.data.data.maxQty;
        findEditKeyword.SKU = action.data.data.SKU;
        findEditKeyword.supplierName = action.data.data.supplierName;
        findEditKeyword.reply_message = action.data.data.reply_message;
        findEditKeyword.FbPageId = action.data.data.FbPageId;
        findEditKeyword.image = action.data.data.image;
        findEditKeyword.counter = action.data.data.counter;
        findEditKeyword.visible = action.data.data.visible;
        findEditKeyword.sold = action.data.data.sold;
        findEditKeyword.groupID = action.data.data.groupID;
        findEditKeyword.groupName = action.data.data.groupName;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        allKeyWords: [...state.allKeyWords],
      };

    case EDIT_GROUPS:
      let findEditGroup = state.allGroups.find(
        (data) => data._id === action.data.data._id
      );
      if (findEditGroup) {
        findEditGroup.name = action.data.data.name;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        allGroups: [...state.allGroups],
      };

    case EDIT_COUPON:
      let findEditCoupon = state.coupons.find(
        (data) => data._id === action.data.data._id
      );
      if (findEditCoupon) {
        findEditCoupon.DiscountName = action.data.data.DiscountName;
        findEditCoupon.PromoCode = action.data.data.PromoCode;
        findEditCoupon.DiscountAmount = action.data.data.DiscountAmount;
        findEditCoupon.CouponType = action.data.data.CouponType;
        findEditCoupon.StartDate = action.data.data.StartDate;
        findEditCoupon.EndDate = action.data.data.EndDate;
        findEditCoupon.Count = action.data.data.Count;
        findEditCoupon.Default = action.data.data.Default;
        findEditCoupon.DiscountType = action.data.data.DiscountType;
        findEditCoupon.couponValue = action.data.data.couponValue;
        findEditCoupon.linkedKeywordId = action.data.data.linkedKeywordId;
        findEditCoupon.Is_Link_Keyword = action.data.data.Is_Link_Keyword;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        coupons: [...state.coupons],
      };

    case EDIT_DELIVERY_FEES:
      let findEditDeliveryFees = state.deliveryFees.find(
        (data) => data._id === action.data.data._id
      );
      if (findEditDeliveryFees) {
        findEditDeliveryFees.supplierName = action.data.data.supplierName;
        findEditDeliveryFees.minimum = action.data.data.minimum;
        findEditDeliveryFees.shippingCharge = action.data.data.shippingCharge;
        findEditDeliveryFees.offline = action.data.data.offline;
        findEditDeliveryFees.message = action.data.data.message;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        deliveryFees: [...state.deliveryFees],
      };

    case EDIT_KEYWORDS_QTY:
      let findEditKeywordQty = state.allKeyWords.find(
        (data) => data._id === action.data._id
      );
      if (findEditKeywordQty) {
        findEditKeywordQty.stock = action.data.stock;
        findEditKeywordQty.sold = action.data.sold;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: null,
        allKeyWords: [...state.allKeyWords],
      };

    case SEND_FB_MESSAGE:
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
      };

    case USER_ORDERS:
      return {
        ...state,
        error_msg: null,
        loading: false,
        usersOrders: action.data.Orders,
      };

    case GET_COUPON:
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        couponDetail: { ...action.data.data },
      };

    case GET_SUPPLIERS_DATES:
      return {
        ...state,
        error_msg: null,
        loading: false,
        supplierDeliveryDate: [...action.data],
      };

    case ADD_ORDER:
      let findOrderUpdate = state.orders.find(
        (data) => data._id === action.data._id
      );
      if (findOrderUpdate) {
        findOrderUpdate.Items = [...action.data.Items];
        findOrderUpdate.Total = action.data.Total;
        findOrderUpdate.Status = action.data.Status;
        findOrderUpdate.PaymentStatus = action.data.PaymentStatus;
        findOrderUpdate.ShippingCharge = action.data.ShippingCharge;
        findOrderUpdate.DiscountAmount = action.data.DiscountAmount;
        findOrderUpdate.Coupon = action.data.Coupon;
        findOrderUpdate.DeliveryTimeSlot = action.data.DeliveryTimeSlot;
        findOrderUpdate.PaymentDate = action.data.PaymentDate;
        findOrderUpdate.CancelDate = action.data.CancelDate;
        findOrderUpdate.PaidAmount = action.data.PaidAmount;
        findOrderUpdate.Payment_id = action.data.Payment_id;
        findOrderUpdate.Coupon = action.data.Coupon;
        findOrderUpdate.DiscountAmount = action.data.DiscountAmount;
        findOrderUpdate.ApplyPoint = action.data.ApplyPoint;
        findOrderUpdate.DiscountAmountPoint = action.data.DiscountAmountPoint;
        findOrderUpdate.OrderWisePoint = action.data.OrderWisePoint;
        findOrderUpdate.PaymentClientStatus = action.data.PaymentClientStatus;
        findOrderUpdate.Residential_Type = action.data.Residential_Type;
        findOrderUpdate.FeedBackMessage = action.data.FeedBackMessage;
      } else {
        //Todo searching Data is available or not
        const searchingData = state.orderSearch;
        if (
          searchingData.orderName === "" &&
          searchingData.orderNumber === "" &&
          searchingData.paymentId === ""
        ) {
          state.orders.push(action.data);
        }
      }
      return {
        ...state,
        orders: [...state.orders],
      };

    case LOGOUT:
      browserHistory.push("/");
      return Object.assign({}, state, {
        loading: false,
        error_msg: null,
        isAuthenticated: false,
        token: null,
        isAuthenticatedFail: false,
        allKeyWords: [],
        allPages: null,
        selectedPage: null,
        orders: [],
        userDetail: [],
        userPointSummaryReport: [],
      });

    case SELECTED_PAGE:
      action.data.Is_update = true;
      const startDayDateTime = moment()
        .tz("Asia/Singapore")
        .startOf("day")
        .format();
      const endDayDateTime = moment()
        .tz("Asia/Singapore")
        .endOf("day")
        .format();
      return {
        ...state,
        error_msg: null,
        loading: false,
        selectedPage: { ...action.data },
        orderSearch: {
          orderName: "",
          orderNumber: "",
          fromDate: new Date(startDayDateTime),
          toDate: new Date(endDayDateTime),
          paymentId: "",
          postId: 0,
        },
        supplierDeliveryDate: [],
      };

    case PAGE_LOAD_INTO_LOCAL:
      return {
        ...state,
        error_msg: null,
        loading: false,
        allPages: [...action.data.userPages],
      };

    case GET_USER_ORDER:
      return {
        ...state,
        error_msg: null,
        loading: false,
        checkOutOrder: { ...action.data },
      };

    case CHECK_OUT:
      window.location.replace(action.data.redirectUrl);
      return {
        ...state,
        error_msg: null,
        loading: false,
      };

    case EDIT_ORDER:
      return {
        ...state,
        error_msg: action.data.errorResult,
        success_msg: action.data.result,
        loading: false,
        checkOutOrder: { ...action.data.data },
      };

    case EDIT_USER:
      let findUserUpdate = state.userDetail.find(
        (data) => data._id === action.data.data._id
      );
      if (findUserUpdate) {
        findUserUpdate.PointsEarned = action.data.data.PointsEarned;
        findUserUpdate.pointCollections = action.data.data.pointCollections;
        findUserUpdate.PointsUsed = action.data.data.PointsUsed;
        findUserUpdate.PointsExpiring = action.data.data.PointsExpiring;
        findUserUpdate.PointsExpired = action.data.data.PointsExpired;
      }

      return {
        ...state,
        // error_msg: action.data.errorResult,
        success_msg: action.data.result,
        loading: false,
        userDetail: [...state.userDetail],
      };

    case IMPORT_ORDER:
      return {
        ...state,
        error_msg:
          action.data.importFailOrders.length > 0 ? action.data.result : null,
        success_msg:
          action.data.importFailOrders.length === 0 ? action.data.result : null,
        loading: false,
        importFailOrders: [...action.data.importFailOrders],
        outOfStockOrder: [...action.data.outOfStockOrder],
      };

    case IMPORT_COUPON:
      return {
        ...state,
        error_msg: null,
        success_msg: action.data.result,
        loading: false,
        importFailCoupon: [...action.data.importFailCoupouns],
      };

    case SEARCH_ORDER:
      return {
        ...state,
        error_msg: null,
        loading: false,
        searchOrderId: action.data.orderId,
      };

    case CHANGE_PAGE_STATUS:
      const findSinglePage = state.allPages.find(
        (data) => data.FbPageId === action.data.FbPageId
      );
      findSinglePage.Is_Live = action.data.result;
      if (state.selectedPage.FbPageId === action.data.FbPageId) {
        state.selectedPage.Is_Live = action.data.result;
        state.selectedPage.Is_update = false;
      }
      return {
        ...state,
        error_msg: null,
        success_msg: "Your FbLive Status has been changed",
        loading: false,
        allPages: [...state.allPages],
        selectedPage: { ...state.selectedPage },
      };

    case EDIT_PAGE_DETAIL:
      const findPage = state.allPages.find(
        (data) => data.FbPageId === action.data.data.FbPageId
      );
      if (findPage) {
        findPage.Is_Live = action.data.data.Is_Live;
        findPage.ReplyMessage = action.data.data.ReplyMessage;
        findPage.OutOfStockMessage = action.data.data.OutOfStockMessage;
        findPage.PersonalMessage = action.data.data.PersonalMessage;
        findPage.MassMessage = action.data.data.MassMessage;
        findPage.TelegramMessage = action.data.data.TelegramMessage;
        findPage.WhatsAppMessage = action.data.data.WhatsAppMessage;
        findPage.EmailMessage = action.data.data.EmailMessage;
        findPage.RemarkMessage = action.data.data.RemarkMessage;
        findPage.HideDeliveryMessage = action.data.data.HideDeliveryMessage;
        findPage.CreditCardMessage = action.data.data.CreditCardMessage;
        findPage.LuckyDrawKeyWord = action.data.data.LuckyDrawKeyWord;
        findPage.TermsAndConditions = action.data.data.TermsAndConditions;
        findPage.PrivacyPolicy = action.data.data.PrivacyPolicy;
        findPage.DeliveryDate = action.data.data.DeliveryDate;
        findPage.Minimum = action.data.data.Minimum;
        findPage.ShippingMinimum = action.data.data.ShippingMinimum;
        findPage.Is_Cancel = action.data.data.Is_Cancel;
        findPage.CancelDate = action.data.data.CancelDate;
        findPage.MinimumOrder = action.data.data.MinimumOrder;
        findPage.Is_MinimumOrder = action.data.data.Is_MinimumOrder;
        findPage.DisplayProducts = action.data.data.DisplayProducts;
        findPage.Is_EarnPointMinimumOrder =
          action.data.data.Is_EarnPointMinimumOrder;
        findPage.EarnPointManagement = action.data.data.EarnPointManagement;
        findPage.RedeemPointMinimumOrder =
          action.data.data.RedeemPointMinimumOrder;
        findPage.Is_RedeemPointMinimumOrder =
          action.data.data.Is_RedeemPointMinimumOrder;
        findPage.RedeemManagement = action.data.data.RedeemManagement;
        findPage.ExchangeRate = action.data.data.ExchangeRate;
        findPage.ExchangePoint = action.data.data.ExchangePoint;
        findPage.FirstSignUpPoint = action.data.data.FirstSignUpPoint;
        findPage.repeatedStatus = action.data.data.repeatedStatus;
        findPage.LuckyDraw = action.data.data.LuckyDraw;
        findPage.LuckyDrawStatus = action.data.data.LuckyDrawStatus;
        findPage.ShareButtonVisible = action.data.data.ShareButtonVisible;
        findPage.CodePrefix = action.data.data.CodePrefix;
        findPage.Is_VisibleDeliveryTime =
          action.data.data.Is_VisibleDeliveryTime;
        findPage.Is_OfflinePurchase = action.data.data.Is_OfflinePurchase;
        findPage.ReferralPoint = action.data.data.ReferralPoint;
        findPage.Is_VisibleDirectComment =
          action.data.data.Is_VisibleDirectComment;
        findPage.DisableRemoveItem = action.data.data.DisableRemoveItem;
        findPage.UponPayment = action.data.data.UponPayment;
        findPage.FbAccessToken = action.data.data.FbAccessToken;
        findPage.FbUserAccessToken = action.data.data.FbUserAccessToken;
        findPage.FeedBack = action.data.data.FeedBack;
        findPage.PaymentCallBack = action.data.data.PaymentCallBack;
        findPage.ProductCallBack = action.data.data.ProductCallBack;
        findPage.CallBackToken = action.data.data.CallBackToken;
        findPage.Is_callBack = action.data.data.Is_callBack;
      }

      if (state.selectedPage.FbPageId === action.data.data.FbPageId) {
        state.selectedPage.Is_Live = action.data.data.Is_Live;
        state.selectedPage.ReplyMessage = action.data.data.ReplyMessage;
        state.selectedPage.OutOfStockMessage =
          action.data.data.OutOfStockMessage;
        state.selectedPage.PersonalMessage = action.data.data.PersonalMessage;
        state.selectedPage.MassMessage = action.data.data.MassMessage;
        state.selectedPage.TelegramMessage = action.data.data.TelegramMessage;
        state.selectedPage.WhatsAppMessage = action.data.data.WhatsAppMessage;
        state.selectedPage.EmailMessage = action.data.data.EmailMessage;
        state.selectedPage.RemarkMessage = action.data.data.RemarkMessage;
        state.selectedPage.HideDeliveryMessage =
          action.data.data.HideDeliveryMessage;
        state.selectedPage.CreditCardMessage =
          action.data.data.CreditCardMessage;
        state.selectedPage.LuckyDrawKeyWord = action.data.data.LuckyDrawKeyWord;
        state.selectedPage.TermsAndConditions =
          action.data.data.TermsAndConditions;
        state.selectedPage.PrivacyPolicy = action.data.data.PrivacyPolicy;
        state.selectedPage.DeliveryDate = action.data.data.DeliveryDate;
        state.selectedPage.Minimum = action.data.data.Minimum;
        state.selectedPage.ShippingMinimum = action.data.data.ShippingMinimum;
        state.selectedPage.Is_Cancel = action.data.data.Is_Cancel;
        state.selectedPage.CancelDate = action.data.data.CancelDate;
        state.selectedPage.MinimumOrder = action.data.data.MinimumOrder;
        state.selectedPage.Is_MinimumOrder = action.data.data.Is_MinimumOrder;
        state.selectedPage.DisplayProducts = action.data.data.DisplayProducts;
        state.selectedPage.Is_EarnPointMinimumOrder =
          action.data.data.Is_EarnPointMinimumOrder;
        state.selectedPage.EarnPointManagement =
          action.data.data.EarnPointManagement;
        state.selectedPage.RedeemPointMinimumOrder =
          action.data.data.RedeemPointMinimumOrder;
        state.selectedPage.Is_RedeemPointMinimumOrder =
          action.data.data.Is_RedeemPointMinimumOrder;
        state.selectedPage.RedeemManagement = action.data.data.RedeemManagement;
        state.selectedPage.ExchangeRate = action.data.data.ExchangeRate;
        state.selectedPage.ExchangePoint = action.data.data.ExchangePoint;
        state.selectedPage.FirstSignUpPoint = action.data.data.FirstSignUpPoint;
        state.selectedPage.repeatedStatus = action.data.data.repeatedStatus;
        state.selectedPage.LuckyDraw = action.data.data.LuckyDraw;
        state.selectedPage.LuckyDrawStatus = action.data.data.LuckyDrawStatus;
        state.selectedPage.ShareButtonVisible =
          action.data.data.ShareButtonVisible;
        state.selectedPage.CodePrefix = action.data.data.CodePrefix;
        state.selectedPage.Is_VisibleDeliveryTime =
          action.data.data.Is_VisibleDeliveryTime;
        state.selectedPage.Is_OfflinePurchase =
          action.data.data.Is_OfflinePurchase;
        state.selectedPage.ReferralPoint = action.data.data.ReferralPoint;
        state.selectedPage.Is_VisibleDirectComment =
          action.data.data.Is_VisibleDirectComment;
        state.selectedPage.DisableRemoveItem =
          action.data.data.DisableRemoveItem;
        state.selectedPage.UponPayment = action.data.data.UponPayment;
        state.selectedPage.FbAccessToken = action.data.data.FbAccessToken;
        state.selectedPage.FbUserAccessToken =
          action.data.data.FbUserAccessToken;
        state.selectedPage.FeedBack = action.data.data.FeedBack;
        state.selectedPage.PaymentCallBack = action.data.data.PaymentCallBack;
        state.selectedPage.ProductCallBack = action.data.data.ProductCallBack;
        state.selectedPage.CallBackToken = action.data.data.CallBackToken;
        state.selectedPage.Is_callBack = action.data.data.Is_callBack;
        state.selectedPage.Is_update = false;
      }

      return {
        ...state,
        error_msg: null,
        success_msg: action.data.result,
        loading: false,
        allPages: [...state.allPages],
        selectedPage: { ...state.selectedPage },
      };

    case SELECT_TIME_SLOT:
      return {
        ...state,
        loading: false,
        success_msg: action.data.result,
        error_msg: action.data.error_result,
        selectTimeSlot: {
          selectedTimeSlot: action.data.selectedTimeSlot,
          availableTimeSlots: action.data.availableTimeSlots,
        },
        ShippingCharge: action.data.ShippingCharge,
      };

    case CONFIRM_TIME_SLOT:
      return {
        ...state,
        loading: false,
        success_msg: action.data.result,
        error_msg: action.data.error_result,
        confirmDeliverySlot: {
          availableTimeSlots: action.data.availableTimeSlots,
          order: action.data.order,
        },
      };

    case EDIT_ALL_KEYWORDS:
      let allKeyWords = [...state.allKeyWords];
      allKeyWords.map((singleKeyword) => {
        if(!action.data.data.groupID || singleKeyword.groupID === action.data.data.groupID){
          singleKeyword.visible = action.data.data.visible;
        }
      });
      return {
        ...state,
        loading: false,
        success_msg: action.data.result,
        error_msg: null,
        allKeyWords: [...allKeyWords],
      };

    case ADD_LUCKY_DRAW:
      let luckyDrawList = [...state.luckyDraw];
      if (luckyDrawList.length >= 10) {
        luckyDrawList.splice(0, 1);
        luckyDrawList.push(action.data);
      } else {
        luckyDrawList.push(action.data);
      }
      return {
        ...state,
        luckyDraw: [...luckyDrawList],
      };

    case WINNER_LIST:
      let winnerList = action.data.result;
      return {
        ...state,
        loading: false,
        error_msg: null,
        winnerList: [...winnerList],
        luckyDraw: [],
      };

    case SEARCH_TRANSACTIONS_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        transactions: action.data,
      });

    case DELIVERY_SLOTS_PAGE_DETAIL:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        deliveryTimeSlotsPageDetail: action.data,
      });

    case SEARCH_PREREGISTRATION_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        preRegistration: action.data,
      });

    case SEARCH_NEWREGISTRATION_FILTER:
      return Object.assign({}, state, {
        error_msg: null,
        loading: false,
        newRegistration: action.data,
      });

    case EDIT_REGISTRATION_USER:
      let findEdit = state.newRegistration.find(
        (data) => data._id === action.data.data._id
      );
      if (findEdit) {
        findEdit.Block = action.data.data.Block;
        findEdit.RapydDev_access_key = action.data.data.RapydDev_access_key;
        findEdit.RapydDev_secret_key = action.data.data.RapydDev_secret_key;
        findEdit.RapydProd_access_key = action.data.data.RapydProd_access_key;
        findEdit.RapydProd_secret_key = action.data.data.RapydProd_secret_key;
      }
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        newRegistration: [...state.newRegistration],
      };

    case EDIT_REGISTRATION_USER_WITH_TOKEN:
      localStorage.clear();
      localStorage.setItem("accessToken", action.data.data);
      return {
        ...state,
        error_msg: null,
        loading: false,
        success_msg: action.data.result,
        token: action.data.data,
      };

    case ADD_USER_DETAILS:
      return {
        ...state,
        adminUser: action.data,
        loading: false,
      };

    case GET_USER_POINT_SUMMARY_REPORT:
      return {
        ...state,
        error_msg: null,
        loading: false,
        userPointSummaryReport: [...action.data],
      };

    default:
      return state;
  }
}
