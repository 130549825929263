import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import * as adminAction from "../../../actions/authAction";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import "./style.css";
import { Card } from "@material-ui/core";

const FeedBackVisible = (props) => {
  const [FeedBack, setFeedBack] = useState(false);

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage) {
      setFeedBack(adminReducers.selectedPage.FeedBack);
    }
  }, [adminReducers.selectedPage]);

  const updateDate = () => {
    props.actions.adminAction.editPageDetail(
      {
        FeedBack: FeedBack,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleFeedBackChange = () => {
    setFeedBack(!FeedBack);
  };

  return (
    <Card className="d-flex p-2 align-items-center gap-20">
      <div className="d-flex align-items-center">
        <label> FeedBack Visible Status </label> &nbsp; &nbsp;
        <Switch
          checked={FeedBack}
          onChange={handleFeedBackChange}
          name="PageStatus"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        className="ml-3"
        onClick={updateDate}
      >
        Save
      </Button>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(FeedBackVisible);
