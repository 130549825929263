const ENV = "production";

const ENVIRONMENT_LOCAL = {
  Base_API_URL: "http://192.168.1.24:9000/api",
  SOCKET_URL: "http://192.168.1.24:9000",
  IMAGE_URL: "http://192.168.1.24:9000/images/",
  FILE_URL: "http://192.168.1.24:9000/files/",
  UPLOAD_IMAGE_URL: "http://192.168.1.24:9000/",
  Base_CHECKOUT_URL: "http://192.168.1.24:9000/checkout/",
};

const ENVIRONMENT_DEVELOPMENT = {
  Base_API_URL: "https://dev.liveorder.thevelocitee.com:9000/api",
  SOCKET_URL: "https://dev.liveorder.thevelocitee.com:9000",
  IMAGE_URL: "https://dev.liveorder.thevelocitee.com:9000/images/",
  FILE_URL: "https://dev.liveorder.thevelocitee.com:9000/files/",
  UPLOAD_IMAGE_URL: "https://dev.liveorder.thevelocitee.com:9000/",
  Base_CHECKOUT_URL: "https://dev.liveorder.thevelocitee.com/checkout/",
};

const ENVIRONMENT_PRODUCTION = {
  Base_API_URL: "https://api.liveorder.ordrstartr.com/api",
  SOCKET_URL: "https://api.liveorder.ordrstartr.com",
  IMAGE_URL: "https://api.liveorder.ordrstartr.com/images/",
  FILE_URL: "https://api.liveorder.ordrstartr.com/files/",
  UPLOAD_IMAGE_URL: "https://api.liveorder.ordrstartr.com/",
  Base_CHECKOUT_URL: "https://liveorder.ordrstartr.com/checkout/",
};

let ENVIRONMENT_VARIABLES;

if (ENV === "local") {
  ENVIRONMENT_VARIABLES = ENVIRONMENT_LOCAL;
} else if (ENV === "development") {
  ENVIRONMENT_VARIABLES = ENVIRONMENT_DEVELOPMENT;
} else {
  ENVIRONMENT_VARIABLES = ENVIRONMENT_PRODUCTION;
}

export default ENVIRONMENT_VARIABLES;
