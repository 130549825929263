import React, {useState, useEffect} from 'react';
import decode from "jwt-decode";
import Header from '../header';
import Footer from '../footer';
import NotFound from '../../NotFound';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import '../style.css';

const FbCallback = (props) => {

    const [profile, setProfile] = useState(null);

    useEffect(() => {
        if (props.location.query.access_token) {
            const User = decode(props.location.query.access_token);
            setProfile(User.user);
        }
    }, []);


    return (
        <div>
            {profile ? <div>
                <div className="mt-3 layout">
                    <Header/>
                    <div className="container main_layout">
                        <div className="row col-12">
                            <div
                                className="responsive_inner_div d-flex col-6 flex-column justify-content-start align-items-start p-2">
                                <h2 className="h_2_1"> FaceBook Account Details </h2>

                                <ValidatorForm autoComplete="off" className="w-100">

                                    <div className="form-group mb-2 pt-4">
                                        <TextValidator
                                            label="FacebookID"
                                            name="FacebookID"
                                            value={profile.FBId}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group mb-2 pt-4">
                                        <TextValidator
                                            label="First Name"
                                            name="FirstName"
                                            value={profile.FirstName}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group mb-2 pt-4">
                                        <TextValidator
                                            label="Last Name"
                                            name="LastName"
                                            value={profile.LastName}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            className="form-control"
                                        />
                                    </div>

                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div> : <div>

                <NotFound/>

            </div>}
        </div>
    );

};

export default FbCallback;

