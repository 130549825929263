import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Loader from "../Loader";
import Header from "./header";
import Footer from "./footer";
import "./style.css";
import { connect, useSelector } from "react-redux";
import * as customerAction from "../../actions/customerAction";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import SecurityIcon from "@material-ui/icons/Security";

const StarterSubscription = (props) => {
  const [data, setData] = useState({
    FullName: "",
    CompanyName: "",
    Email: "",
    Phone: "",
    CompanyURL: "",
    FacebookURL: "",
    FacebookID: "",
    Type: "basic",
  });

  const customerReducers = useSelector((state) => ({
    loading: state.customerReducer.loading,
    error_msg: state.customerReducer.error_msg,
    success_msg: state.customerReducer.success_msg,
  }));

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newDetail = { ...data };
    newDetail[name] = value;
    setData(newDetail);
  };

  const handleSubmit = (data) => {
    props.actions.customerAction.NewBasicRegistration(data);
  };

  useEffect(() => {
    if (customerReducers.success_msg !== null) {
      setData({
        FullName: "",
        CompanyName: "",
        Email: "",
        Phone: "",
        CompanyURL: "",
        FacebookURL: "",
        FacebookID: "",
        Type: "basic",
      });
      swal("", customerReducers.success_msg, "success");
    }
  }, [customerReducers.success_msg]);

  useEffect(() => {
    if (customerReducers.error_msg !== null) {
      swal("Oops...", customerReducers.error_msg.toString(), "error");
    }
  }, [customerReducers.error_msg]);

  return (
    <div>
      <div className="mt-3 layout">
        <Header />
        <div className="container main_layout">
          <div className="row col-12">
            <div className="responsive_inner_div d-flex col-6 flex-column justify-content-start align-items-start p-2">
              <h2 className="h_2_1"> Become a Starter member now</h2>
              <p className="main_text">
                Simply fill up the form below for us to create an account with
                your payment gateway before you kick start your awesome journey.
              </p>
              <ValidatorForm
                onSubmit={() => handleSubmit(data)}
                autoComplete="off"
                className="w-100"
              >
                <div className="form-group mb-2 pt-2">
                  <TextValidator
                    label="Full Name (as per IC)"
                    name="FullName"
                    value={data.FullName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Company Name"
                    name="CompanyName"
                    value={data.CompanyName}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Your Email"
                    name="Email"
                    type="email"
                    value={data.Email}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Contact Number"
                    name="Phone"
                    value={data.Phone}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Company URL (if have any)"
                    name="CompanyURL"
                    value={data.CompanyURL}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="Facebook Page URL"
                    name="FacebookURL"
                    value={data.FacebookURL}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-2 pt-4">
                  <TextValidator
                    label="FacebookID"
                    name="FacebookID"
                    value={data.FacebookID}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    className="form-control"
                  />
                </div>

                <br />
                <button type="submit" className="form_but">
                  SUBMIT
                </button>
              </ValidatorForm>
            </div>
            <div className="responsive_inner_div d-flex col-6 flex-column justify-content-start align-items-center p-2">
              <div className="row">
                <SecurityIcon className="mr-4" />
                <div className="left_div">
                  <p>
                    <strong>Security &amp; Privacy are #1</strong>
                    <br />
                    We strictly protect your data.
                  </p>
                  <p>
                    <strong>Note:</strong>
                  </p>
                  <p>
                    By submitting this form, you agreed that your information
                    will be sent, as per our
                    <a
                      className="Policy"
                      href="https://www.ordrstartr.com/privacy-policy/"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    , and
                    <a
                      className="Policy"
                      href="https://www.ordrstartr.com/terms-of-service/"
                    >
                      {" "}
                      Terms of Services{" "}
                    </a>{" "}
                    to our system for creating Ordr Startr account and payment
                    gateway.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
      {customerReducers.loading && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    customerAction: bindActionCreators(customerAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(StarterSubscription);
