import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";

let moment = require("moment-timezone");

const columns = [
  { id: "Date", label: "Date", minWidth: 170 },
  {
    id: "Descriptions",
    label: "Descriptions",
    minWidth: 170,
  },
  {
    id: "Points",
    label: "Points",
    minWidth: 170,
    align: "right",
  },
];

function createData(Date, Descriptions, Points) {
  return { Date, Descriptions, Points };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Transactions = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const customerReducers = useSelector((state) => ({
    transactions: state.customerReducer.transactions,
  }));

  useEffect(() => {
    if (
      customerReducers.transactions !== null &&
      customerReducers.transactions !== undefined
    ) {
      let orders = [];
      customerReducers.transactions.map((singleItem) => {
        orders.push(
          createData(
            moment
              .tz(singleItem.Date, "Asia/Singapore")
              .format("DD-MM-YYYY hh:mm A"),
            singleItem.Descriptions,
            singleItem.Points
          )
        );
      });
      setRows(orders);
      setPage(0);
    }
  }, [customerReducers.transactions]);

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        let color = "black";
                        if (column.id === "Points") {
                          if (value < 0) {
                            color = "red";
                            value = -value;
                          }
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: color }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Transactions;
