import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { connect, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import SendIcon from "@material-ui/icons/Send";
import { bindActionCreators } from "redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactExport from "react-export-excel";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";

import * as adminAction from "../../../actions/authAction";
import SearchOrder from "../SearchOrder";
import Loader from "../../Loader";
import "./style.css";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  warningOrder: {
    "& > *": {
      backgroundColor: "yellow",
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow
        className={
          row.DeductQtyUponPaymentStatus !== ""
            ? classes.warningOrder
            : classes.root
        }
      >
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" lign="right">
          {row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== undefined
            ? row.BoxifyOrderNumber
            : row._id}
        </TableCell>
        <TableCell component="th" scope="row" lign="right">
          {row.name}
        </TableCell>
        <TableCell align="right">
          {row.total +
            row.ShippingCharge -
            (row.DiscountAmount + row.DiscountAmountPoint) >=
          0
            ? Number(
                row.total +
                  row.ShippingCharge -
                  (row.DiscountAmount + row.DiscountAmountPoint)
              ).toFixed(2)
            : 0}
        </TableCell>
        <TableCell align="center">{row.Is_FirstOrder.toString()}</TableCell>
        <TableCell align="center">
          {moment.tz(row.Date, "Asia/Singapore").format("DD-MM-YYYY hh:mm A")}
        </TableCell>
        <TableCell align="left">
          {row.order.FeedBackMessage || "-"}
        </TableCell>
        <TableCell align="left">{row.Payment_id}</TableCell>
        <TableCell align="right">{row.PaidAmount}</TableCell>
        <TableCell align="center">{row.PaymentDate}</TableCell>
        <TableCell align="center">{row.OrderWisePoint}</TableCell>
        <TableCell align="center">
          <Tooltip title="Send Facebook Message">
            <IconButton
              aria-label="Send"
              onClick={() => props.sendButton(row._id)}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>ItemName</b>
                    </TableCell>
                    <TableCell>
                      <b>Qty</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Price</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Total price ($)</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                      <TableCell align="right">
                        {Number(item.price).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {Number(item.total).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Orders = (props) => {
  const [rows, setRows] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const adminReducers = useSelector((state) => ({
    orders: state.adminReducer.orders,
  }));

  useEffect(() => {
    setIsLoader(true);
    let orders = [];
    let exceldata = [];
    let colors = ["FF0000FF", "FF00FF00", "FFF86B00", "FFFFFF00", "FFCCEEFF"];
    let colorCount = 0;
    let orderCounter = 0;
    adminReducers.orders.map((order) => {
      if (order.PaymentStatus === "paid") {
        orderCounter++;
        if (colorCount >= colors.length) {
          colorCount = 0;
        } else {
          colorCount += 1;
        }
        orders.push({
          name: order.Name,
          total: Number(order.Total),
          ShippingCharge: Number(order.ShippingCharge),
          DiscountAmount: Number(order.DiscountAmount),
          FbPSID: order.FbSPID,
          Items: order.Items,
          Date: order.Date,
          PaymentStatus: order.PaymentStatus,
          PaymentDate:
            order.PaymentDate !== undefined && order.PaymentDate !== null
              ? moment
                  .tz(order.PaymentDate, "Asia/Singapore")
                  .format("DD-MM-YYYY hh:mm A")
              : "",
          Status: order.Status,
          _id: order._id,
          order: order,
          BoxifyOrderNumber: order.BoxifyOrderNumber,
          PaidAmount: order.PaidAmount,
          Payment_id: order.Payment_id,
          Is_FirstOrder: order.Is_FirstOrder,
          Coupon: order.Coupon,
          ShippingEmail: order.ShippingEmail,
          DiscountAmountPoint: Number(order.DiscountAmountPoint),
          ApplyPoint: Number(order.ApplyPoint),
          OrderWisePoint: Number(order.OrderWisePoint),
          PaymentClientStatus: order.PaymentClientStatus,
          DeductQtyUponPaymentStatus: order.DeductQtyUponPaymentStatus,
        });

        if (order.Coupon === undefined || order.Coupon === null) {
          order.Coupon = "";
        }

        if (
          order.ShippingAddress2 === undefined ||
          order.ShippingAddress2 === null
        ) {
          order.ShippingAddress2 = "";
        }

        if (
          order.ShippingAddress1 === undefined ||
          order.ShippingAddress1 === null
        ) {
          order.ShippingAddress1 = "";
        }

        if (
          order.ShippingMobile === undefined ||
          order.ShippingMobile === null
        ) {
          order.ShippingMobile = "";
        }

        if (
          order.ShippingPostalCode === undefined ||
          order.ShippingPostalCode === null
        ) {
          order.ShippingPostalCode = "";
        }

        if (order.ShippingEmail === undefined || order.ShippingEmail === null) {
          order.ShippingEmail = "";
        }

        let orderNumber = order._id;
        let paymentDate = "";

        if (
          order.PaymentDate &&
          order.PaymentDate !== null &&
          order.PaymentDate !== undefined
        ) {
          paymentDate = moment
            .tz(order.PaymentDate, "Asia/Singapore")
            .format("DD-MM-YYYY hh:mm A")
            .toString();
        }

        let Total =
          order.Total +
          order.ShippingCharge -
          (Number(order.DiscountAmount) + Number(order.DiscountAmountPoint));
        if (Total < 0) Total = 0;

        let ResidentialType = "";
        if (order.Residential_Type) {
          ResidentialType = order.Residential_Type;
        }

        order.Items.map((singleItem, index) => {
          let data = null;
          let sku = "";
          let deliveryDate = "";
          let deliveryTimeRange = "";
          let deliveryType = "";
          if (
            order.DeliveryTimeSlot &&
            order.DeliveryTimeSlot !== null &&
            order.DeliveryTimeSlot !== undefined
          ) {
            const findSupplier = order.DeliveryTimeSlot.timeSlotId
              ? order.DeliveryTimeSlot.timeSlotId.find(
                  (data) => data.supplierName === singleItem.supplierName
                )
              : false;
            if (findSupplier && findSupplier.type !== "Disable") {
              deliveryDate =
                moment
                  .tz(findSupplier.startDateTime, "Asia/Singapore")
                  .format("DD-MM-YYYY hh:mm A")
                  .toString() +
                "-" +
                moment
                  .tz(findSupplier.endDateTime, "Asia/Singapore")
                  .format("DD-MM-YYYY hh:mm A")
                  .toString();
              deliveryTimeRange =
                moment
                  .tz(findSupplier.startDateTime, "Asia/Singapore")
                  .format("hh:mm A")
                  .toString() +
                "-" +
                moment
                  .tz(findSupplier.endDateTime, "Asia/Singapore")
                  .format("hh:mm A")
                  .toString();
            }
            deliveryType =
              findSupplier && findSupplier.type ? findSupplier.type : "";
          }
          if (singleItem.SKU && singleItem.SKU !== null) sku = singleItem.SKU;
          if (index === 0) {
            data = [
              {
                value: orderCounter.toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.Is_FirstOrder.toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryType,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: orderNumber,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.Name,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingMobile,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingAddress1,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingEmail,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.FeedBackMessage,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingPostalCode,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.supplierName ? singleItem.supplierName : "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryTimeRange,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryDate,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: ResidentialType,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: sku,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.keyword,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.itemName,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.qty.toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(singleItem.total).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(order.Total).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(Total).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.PaymentStatus,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: paymentDate,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(singleItem.price).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
            ];
          } else {
            data = [
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryType,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: orderNumber,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.Name,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingMobile,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingAddress1,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingEmail,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.FeedBackMessage,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: order.ShippingPostalCode,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.supplierName ? singleItem.supplierName : "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryTimeRange,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: deliveryDate,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: sku,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.keyword,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.itemName,
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: singleItem.qty.toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(singleItem.total).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: "",
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
              {
                value: Number(singleItem.price).toFixed(2).toString(),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: colors[colorCount] },
                  },
                },
              },
            ];
          }
          exceldata.push(data);
        });

        const offerData = [
          {
            title: `ShippingCharge`,
            value: Number(order.ShippingCharge).toFixed(2).toString(),
          },
          {
            title: `Coupon(${order.Coupon})`,
            value: "-" + Number(order.DiscountAmount).toFixed(2).toString(),
          },
          {
            title: `ApplyPoints(${order.ApplyPoint.toString()})`,
            value:
              "-" + Number(order.DiscountAmountPoint).toFixed(2).toString(),
          },
        ];

        offerData.map((singleDigit) => {
          let data = null;
          data = [
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: orderNumber,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.Name,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingMobile,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingAddress1,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingEmail,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.FeedBackMessage,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingPostalCode,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "NA",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleDigit.title,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "1",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleDigit.value,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
          ];
          exceldata.push(data);
        });
      }
    });
    let data = [
      {
        columns: [
          "OrderNumber",
          "First Timer",
          "Deliver/Collect",
          "DemoRef",
          "OrderNo",
          "Name",
          "Contact",
          "Address1",
          "Email",
          "Customer Feedback",
          "Postal Code",
          "Supplier Name",
          "Delivery Time Range",
          "Delivery Date",
          "Residential Type",
          "SKU Code",
          "Product Keyword",
          "Product Name",
          "Quantity",
          "Total Price",
          "Total Sum",
          "After Total Sum",
          "Payment Status",
          "Payment Date",
          "Unit Price",
        ],
        data: exceldata,
      },
    ];
    setRows(orders);
    setExcelData([...data]);
    setIsLoader(false);
  }, [adminReducers.orders]);

  const sendButton = (FbPSID) => {
    if (FbPSID !== null && FbPSID !== undefined) {
      props.actions.adminAction.sendFacebookMessage(FbPSID);
    } else {
      swal("Oops...", "Invalid UserId", "error");
    }
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchOrder />
        <div className="but_width">
          <ExcelFile
            element={
              <Button variant="contained" className="" color="primary">
                <GetAppIcon />
              </Button>
            }
            filename="PaidOrders"
          >
            <ExcelSheet dataSet={excelData} name="Organization" />
          </ExcelFile>
        </div>
      </Card>
      <TableContainer className="table-body" component={Paper}>
        <Table size="small" stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell>
                <b>OrderNo</b>
              </TableCell>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell align="right">
                <b>OrderValue</b>
              </TableCell>
              <TableCell align="center">
                <b>FirstOrder</b>
              </TableCell>
              <TableCell align="center">
                <b>OrderDate</b>
              </TableCell>
              <TableCell>
                <b>Customer Remarks</b>
              </TableCell>
              <TableCell align="left">
                <b>PaymentId</b>
              </TableCell>
              <TableCell align="right">
                <b>PaidAmount</b>
              </TableCell>
              <TableCell align="center">
                <b>PaymentDate</b>
              </TableCell>
              <TableCell align="center">
                <b>Points</b>
              </TableCell>
              <TableCell align="center">
                <b>SendMessage</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} sendButton={sendButton} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoader && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Orders);
