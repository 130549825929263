import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { connect, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { bindActionCreators } from "redux";
import * as customerAction from "../../actions/customerAction";
import "./style.css";

let moment = require("moment-timezone");

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const GotoYourShoppingCart = () => {
    window.open("/checkout/" + row._id, "_blank");
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" lign="right">
          {row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== undefined
            ? row.BoxifyOrderNumber
            : row._id}
        </TableCell>
        <TableCell align="right">{row.Status}</TableCell>
        <TableCell align="right">
          {row.total +
            row.ShippingCharge -
            (row.DiscountAmount + row.DiscountAmountPoint) >=
          0
            ? Number(
                row.total +
                  row.ShippingCharge -
                  (row.DiscountAmount + row.DiscountAmountPoint)
              ).toFixed(2)
            : 0}
        </TableCell>
        <TableCell align="right">
          {moment.tz(row.Date, "Asia/Singapore").format("DD-MM-YYYY hh:mm A")}
        </TableCell>
        <TableCell align="right">{row.PaymentStatus}</TableCell>
        <TableCell align="right">{row.OrderWisePoint}</TableCell>
        <TableCell align="right">{row.ApplyPoint}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ItemName</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                      <TableCell align="right">
                        {Number(item.price).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {Number(item.total).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {row.Status !== "cancel" && (
                    <Button
                      variant="contained"
                      className="mt-2"
                      color="primary"
                      onClick={GotoYourShoppingCart}
                    >
                      Go to your Shopping Cart
                    </Button>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Orders = (props) => {
  const [rows, setRows] = useState([]);
  const [months, setMonths] = useState(3);
  const classes = useRowStyles();
  const customerReducers = useSelector((state) => ({
    orders: state.customerReducer.orders,
    months: state.customerReducer.months,
  }));

  useEffect(() => {
    setMonths(3);
    props.actions.customerAction.getHomeCall();
  }, []);

  useEffect(() => {
    let orders = [];
    customerReducers.orders.map((order) => {
      orders.push({
        name: order.Name,
        total: Number(order.Total),
        ShippingCharge: Number(order.ShippingCharge),
        DiscountAmount: Number(order.DiscountAmount),
        FbPSID: order.FbSPID,
        Items: order.Items,
        Date: order.updatedAt,
        PaymentStatus: order.PaymentStatus,
        Status: order.Status,
        _id: order._id,
        order: order,
        BoxifyOrderNumber: order.BoxifyOrderNumber,
        Is_FirstOrder: order.Is_FirstOrder,
        DiscountAmountPoint: Number(order.DiscountAmountPoint),
        OrderWisePoint: Number(order.OrderWisePoint),
        ApplyPoint: Number(order.ApplyPoint),
      });
    });
    setRows(orders);
  }, [customerReducers.orders]);

  const handleChange = (event) => {
    props.getMonthsWiseOrders(event.target.value);
    setMonths(customerReducers.months);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Last Months</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={months}
          onChange={handleChange}
        >
          <MenuItem value={3}>LAST 3 MONTHS ORDERS</MenuItem>
          <MenuItem value={6}>LAST 6 MONTHS ORDERS</MenuItem>
        </Select>
      </FormControl>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>OrderNo</TableCell>
              <TableCell align="right">OrderStatus</TableCell>
              <TableCell align="right">OrderValue</TableCell>
              <TableCell align="right">OrderDate</TableCell>
              <TableCell align="right">PaymentStatus</TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right">Points Spent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    customerAction: bindActionCreators(customerAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Orders);
