import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Profile from '../Common/ProfilePage';
import RapydCredentials from '../Common/RapydCredential';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as adminAction from "../../../actions/authAction";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonPinIcon from '@material-ui/icons/PersonPin';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#51057C'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DisplayDetailDialog = (props) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const saveDetail = (data) => {
        data._id = props.data._id;
        props.actions.adminAction.editRegistrationUser(data);
    };

    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={props.closeOrders} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.closeOrders} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper square>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon label tabs example"
                    >
                        <Tab icon={<PersonPinIcon/>} label="PROFILE"/>
                        <Tab icon={<PaymentIcon/>} label="RAPYD CREDENTIALS"/>
                    </Tabs>
                </Paper>
                {(props.data && value === 0) && <Profile data={{...props.data}}/>}
                {(props.data && value === 1) && <RapydCredentials data={{...props.data}} saveDetail={saveDetail}/>}
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(DisplayDetailDialog);
