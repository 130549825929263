import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import AddDialog from "./addDialog";
import EditDialog from "./editDialog";
import { confirmAlert } from "react-confirm-alert";
import "./react-confirm-alert.css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CONTRIES } from "../../../constants/actionTypes";
import { Card } from "@material-ui/core";

function createData(
  supplierName,
  country,
  minimum,
  shippingCharge,
  offline,
  message,
  id
) {
  return {
    supplierName,
    country,
    minimum,
    shippingCharge,
    offline,
    message,
    id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "supplierName",
    numeric: false,
    disablePadding: true,
    label: "Supplier",
  },
  { id: "country", numeric: false, disablePadding: true, label: "Country" },
  {
    id: "minimum",
    numeric: true,
    disablePadding: false,
    label: "MinimumAmount",
  },
  {
    id: "shippingCharge",
    numeric: true,
    disablePadding: false,
    label: "Shipping Charge",
  },
  { id: "offline", numeric: false, disablePadding: false, label: "Offline" },
  { id: "message", numeric: false, disablePadding: false, label: "Message" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <div>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Supplier Delivery Fees Management
          </Typography>
        )}

        {numSelected > 0 ? (
          <span className="d-flex">
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={props.DeleteRecords}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {numSelected === 1 && (
              <Tooltip title="Delete">
                <IconButton aria-label="edit" onClick={props.HandleEditOpen}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </span>
        ) : (
          <Tooltip title="Add New Keywords">
            <IconButton
              aria-label="Add New Keywords"
              onClick={props.handleClickOpen}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: "calc(100vh - 315px)",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editKeyword, setEditKeyword] = useState(null);
  const [country, setCountry] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const adminReducers = useSelector((state) => ({
    deliveryFees: state.adminReducer.deliveryFees,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    const allDeliveryFees = [];
    adminReducers.deliveryFees.map((singleData) => {
      if (singleData.country === country) {
        allDeliveryFees.push(
          createData(
            singleData.supplierName,
            singleData.country,
            singleData.minimum,
            singleData.shippingCharge,
            singleData.offline,
            singleData.message,
            singleData._id
          )
        );
      }
    });
    setRows(allDeliveryFees);
    setSelected([]);
  }, [adminReducers.deliveryFees]);

  useEffect(() => {
    if (
      adminReducers.selectedPage &&
      adminReducers.selectedPage !== null &&
      adminReducers.selectedPage !== undefined
    )
      props.actions.adminAction.getDeliveryFees(
        adminReducers.selectedPage.FbPageId
      );
  }, [adminReducers.selectedPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const DeleteRecords = () => {
    confirmAlert({
      key: "123",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.actions.adminAction.deleteDeliveryFees(selected);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSubmitClose = (addKeyWord) => {
    addKeyWord.FbPageId = adminReducers.selectedPage.FbPageId;
    props.actions.adminAction.addDeliveryFees(addKeyWord);
    setOpen(false);
  };

  const handleEditSubmitClose = (editKeyWord) => {
    props.actions.adminAction.editDeliveryFess(editKeyWord);
    setEditOpen(false);
  };

  const HandleEditOpen = () => {
    const findKeyForEdit = adminReducers.deliveryFees.find(
      (data) => data._id === selected[0]
    );
    setEditKeyword(findKeyForEdit);
    setEditOpen(true);
  };

  const HandleEditClose = () => {
    setEditOpen(false);
  };

  const handleChangeSelect = (event) => {
    const { value } = event.target;
    const allDeliveryFees = [];
    adminReducers.deliveryFees.map((singleData) => {
      if (singleData.country === value) {
        allDeliveryFees.push(
          createData(
            singleData.supplierName,
            singleData.country,
            singleData.minimum,
            singleData.shippingCharge,
            singleData.offline,
            singleData.message,
            singleData._id
          )
        );
      }
    });
    setRows(allDeliveryFees);
    setCountry(value);
    setSelected([]);
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine justify-content-start">
        <FormControl variant="outlined" size="small" className="col-sm-2">
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Country"
            id="demo-simple-select"
            value={country}
            onChange={handleChangeSelect}
          >
            {CONTRIES.map((singleSupplier) => (
              <MenuItem value={singleSupplier}>{singleSupplier}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Card>

      {open && (
        <AddDialog
          handleClose={handleClose}
          open={open}
          handleSubmitClose={handleSubmitClose}
          country={country}
        />
      )}
      {editOpen && (
        <EditDialog
          handleClose={HandleEditClose}
          open={editOpen}
          handleEditSubmitClose={handleEditSubmitClose}
          editKeyword={editKeyword}
        />
      )}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          DeleteRecords={DeleteRecords}
          handleClickOpen={handleClickOpen}
          HandleEditOpen={HandleEditOpen}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.supplierName}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.country}
                      </TableCell>
                      <TableCell align="right">{row.minimum}</TableCell>
                      <TableCell align="right">{row.shippingCharge}</TableCell>
                      <TableCell align="left">
                        {row.offline.toString()}
                      </TableCell>
                      <TableCell align="left">{row.message}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(EnhancedTable);
