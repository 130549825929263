import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Switch from "@material-ui/core/Switch";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const CallBackSetting = (props) => {
  const [message, setMessage] = useState({
    PaymentCallBack: "",
    ProductCallBack: "",
    CallBackToken: "",
    Is_callBack: false,
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setMessage({
        PaymentCallBack: adminReducers.selectedPage.PaymentCallBack
          ? adminReducers.selectedPage.PaymentCallBack
          : "",
        ProductCallBack: adminReducers.selectedPage.ProductCallBack
          ? adminReducers.selectedPage.ProductCallBack
          : "",
        CallBackToken: adminReducers.selectedPage.CallBackToken
          ? adminReducers.selectedPage.CallBackToken
          : "",
        Is_callBack: adminReducers.selectedPage.Is_callBack,
      });
    }
  }, [adminReducers.selectedPage]);

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...message };
    commonData[field] = event.target.value;
    return setMessage(commonData);
  };

  const handleCallBackChange = () => {
    const rawMessage = { ...message };
    rawMessage.Is_callBack = !rawMessage.Is_callBack;
    setMessage({ ...rawMessage });
  };

  const submit = () => {
    props.actions.adminAction.editPageDetail(
      message,
      adminReducers.selectedPage.FbPageId
    );
  };

  return (
    <Card className="p-3">
      <div className="d-flex align-items-center">
        <label> CallBack Status </label> &nbsp; &nbsp;
        <Switch
          checked={message.Is_callBack}
          onChange={handleCallBackChange}
          name="CallBack Status"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
      <div className="d-flex flex-column w-50">
        <TextField
          name="CallBackToken"
          multiline
          maxRows={2}
          variant="outlined"
          cols="50"
          value={message.CallBackToken}
          onChange={handleChange}
          label="CallBack Token"
        />
      </div>
      <div className="d-flex flex-column mt-4 w-50">
        <TextField
          name="ProductCallBack"
          multiline
          maxRows={2}
          variant="outlined"
          cols="50"
          value={message.ProductCallBack}
          onChange={handleChange}
          label="Product CallBack"
        />
      </div>
      <div className="d-flex flex-column mt-4 w-50">
        <TextField
          name="PaymentCallBack"
          multiline
          maxRows={2}
          variant="outlined"
          cols="50"
          value={message.PaymentCallBack}
          onChange={handleChange}
          label="Payment CallBack"
        />
      </div>
      <div className="d-flex flex-row mt-4 w-50 justify-content-end">
        <Button variant="contained" color="primary" onClick={submit}>
          Save
        </Button>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(CallBackSetting);
