import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const columns = [
  { id: "description", label: "Description", minWidth: 120 },
  { id: "keyword", label: "Keyword", minWidth: 100 },
  { id: "price", label: "Price", minWidth: 100, align: "right" },
  { id: "visible", label: "Visible", minWidth: 100, align: "center" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 238px)",
  },
});

const DisplayProductsOnShoppingCart = (props) => {
  const [displayProducts, setDisplayProducts] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [group, setGroup] = useState([{ name: "All", _id: 0 }]);
  const [groupName, setGroupName] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    allKeyWords: state.adminReducer.allKeyWords,
    selectedPage: state.adminReducer.selectedPage,
    allGroups: state.adminReducer.allGroups,
  }));

  useEffect(() => {
    setFirstTime(false);
  }, []);

  useEffect(() => {
    if (adminReducers.selectedPage) {
      setDisplayProducts(adminReducers.selectedPage.DisplayProducts);
      props.actions.adminAction.getHomeCallGroup(
        adminReducers.selectedPage?.FbPageId
      );
    }
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.allGroups.length > 0) {
      setGroup([...adminReducers.allGroups, { name: "All", _id: 0 }]);
    }
  }, [adminReducers.allGroups]);

  useEffect(() => {
    if (adminReducers.allKeyWords) {
      filterCompaign(groupName);
    }
  }, [adminReducers.allKeyWords]);

  const updateDate = () => {
    props.actions.adminAction.editPageDetail(
      {
        DisplayProducts: displayProducts,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = () => {
    setDisplayProducts(!displayProducts);
  };

  const changeVisibility = (data) => {
    props.actions.adminAction.editKeyWords({
      ...data,
      _id: data._id,
      visible: !data.visible,
    });
  };

  const setAllVisible = (visible) => {
    props.actions.adminAction.editKeyWordsAllVisible(
      {
        visible: visible,
        groupID: groupName,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const filterCompaign = (groupName) => {
    let allData = [];
    if (groupName !== 0) {
      adminReducers.allKeyWords.map((singleData) => {
        if (singleData.groupID === groupName) {
          allData.push(singleData);
        }
      });
    } else {
      allData = [...adminReducers.allKeyWords];
    }
    setRows(allData);
    firstTime && setPage(0);
  };

  const handleChangeCompaign = (event) => {
    filterCompaign(event.target.value);
    setGroupName(event.target.value);
  };

  return (
    <div className="d-flex flex-column gap-20">
      <Card className="d-flex p-2 align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <div className="d-flex align-items-center">
            <label> Add Products on Cart </label> &nbsp; &nbsp;
            <Switch
              checked={displayProducts}
              onChange={handleChange}
              name="DisplayProductsOnCart"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <FormControl variant="outlined" size="small" className="campaign">
            <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Campaign"
              id="demo-simple-select"
              value={groupName}
              onChange={handleChangeCompaign}
            >
              {group.map((singleSupplier) => (
                <MenuItem value={singleSupplier._id}>
                  {singleSupplier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className="ml-3"
            onClick={updateDate}
          >
            Save
          </Button>
        </div>
        <div className="d-flex align-items-center gap-20">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAllVisible(true)}
          >
            All Active
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAllVisible(false)}
          >
            All In Active
          </Button>
        </div>
      </Card>

      {rows.length > 0 && (
        <div>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            if (column.id === "visible") {
                              return (
                                <TableCell key={index} align={"center"}>
                                  <Switch
                                    checked={row.visible}
                                    onChange={() => changeVisibility(row)}
                                    name="DisplayProductsOnCart"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 25, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DisplayProductsOnShoppingCart);
