import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import promise from "redux-promise";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers";
import initialState from "./reducers/initialState";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import NotFound from "./Components/NotFound";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import FbCallBack from "./Components/FbCallBack";
import CustomerFbCallBack from "./Components/CustomerFbCallBack";
import Groups from "./Components/admin/Groups";
import Keywords from "./Components/admin/Keywords";
import Coupons from "./Components/admin/Coupons";
import Order from "./Components/admin/Orders";
import MessageFormate from "./Components/admin/MessageFormate";
import UserDetail from "./Components/admin/UserDetail";
import Checkout from "./Components/Checkout";
import DeliveryDate from "./Components/admin/DeliveryDate";
import PaidOrder from "./Components/admin/PaidOrder";
import CancelOrder from "./Components/admin/CancelOrder";
import DeliveryFees from "./Components/admin/DeliveryFees";
import CheckoutCallBack from "./Components/CheckoutCallBack";
import ImportOrders from "./Components/admin/ImportOrders";
import SearchOrders from "./Components/OrderSearch";
import LastDateTime from "./Components/admin/LastDateTime";
import DisplayProductsOnShoppingCart from "./Components/admin/DisplayProductsOnShoppingCart";
import MassMessage from "./Components/admin/MassMessage";
import MinimumOrder from "./Components/admin/MinimumOrder";
import EarnPointManagement from "./Components/admin/EarnPointManagement";
import RedeemPointManagement from "./Components/admin/RedeemPointManagement";
import CustomerLogin from "./Components/SHC/CustomerFbLogin";
import AllDeveloperCustomerLogin from "./Components/AllDeveloper/CustomerFbLogin";
import AudioHouseCustomerLogin from "./Components/AudioHouse/CustomerFbLogin";
import PropertyLiveCustomerLogin from "./Components/PropertyLive/CustomerFbLogin";
import RegistrationCustomerLogin from "./Components/Registration/CustomerFbLogin";
import CustomerDashBoard from "./Components/CustomerDashBoard";
import ExchangePointValue from "./Components/admin/ExchangePointValue";
import UserPointSummaryReport from "./Components/admin/UserPointSummaryReport";
import FirstSignUpCustomersIncentivePoints from "./Components/admin/FirstSignUpCustomersIncentivePoints";
import OrderItemsDisplay from "./Components/admin/OrderItems";
import LuckyDraw from "./Components/admin/LuckyDraw";
import LuckyDrawWall from "./Components/admin/LuckyDrawWall";
import ShareButtonVisible from "./Components/admin/ShareButtonVisible";
import Transactions from "./Components/admin/Transaction";
import ReferralIncentivePoints from "./Components/admin/ReferralPoints";
import DisplayQrcCode from "./Components/DisplayQrcCode";
import Registration from "./Components/Registration";
import BasicSubscription from "./Components/Registration/basicSubscription";
import ProSubscription from "./Components/Registration/proSubscription";
import PreRegistration from "./Components/admin/PreRegistration";
import NewUserRequest from "./Components/admin/NewUserRequest";
import RegistrationFbCallBack from "./Components/Registration/FbCallBack";
import "bootstrap/dist/css/bootstrap.min.css";
import decode from "jwt-decode";
import Support from "./Components/admin/Support";
import MyPlan from "./Components/admin/MyPlan";
import MyProfile from "./Components/admin/MyProfile";
import DirectComment from "./Components/admin/DirectComment";
import DisableRemoveItems from "./Components/admin/DisableRemoveItems";
import DeductQtyUponPayment from "./Components/admin/DeductQtyUponPayment";
import FeedBack from "./Components/admin/FeedBackVisible";
import CallBackSetting from "./Components/admin/CallBackSetting";
import currentShow from "./Components/admin/LiveShow/currentShow";
import linkCampaign from "./Components/admin/LiveShow/linkCampaign";
import ImportCoupons from "./Components/admin/ImportCoupons";
import ImageUpload from "./Components/admin/ImageUpload";
import JapanSHC from "./Components/JapanSHC";
import CNSHC from "./Components/Cnshc";

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, promise))
);

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeEnhancers(applyMiddleware(thunk, promise, logger))
// );

function checkLoggedIn(nextState, replace) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken !== "undefined" && accessToken !== null) {
    const userProfile = getCustomerData(accessToken);
    if (userProfile && userProfile.userRole === "crm") {
      replace({
        pathname: "/CRM/Dashboard",
        state: { nextPathname: nextState.location.pathname },
      });
    } else
      replace({
        pathname: "/Dashboard",
        state: { nextPathname: nextState.location.pathname },
      });
  }
}

function checkCustomerLoggedIn(nextState, replace) {
  // const accessToken = localStorage.getItem('fbAccessToken');
  // if (accessToken !== "undefined" && accessToken !== null) {
  //     replace({
  //         pathname: '/CustomerDashBoard',
  //         state: {nextPathname: nextState.location.pathname}
  //     });
  // }
}

function requireAdminAuth(nextState, replace) {
  if (!isLoggedIn()) {
    localStorage.removeItem("accessToken");
    localStorage.clear();
    replace({
      pathname: "/",
      state: { nextPathname: nextState.location.pathname },
    });
  } else {
    if (!checkUser(getAccessToken())) {
      replace({
        pathname: "/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }
}

function requireCustomerAuth(nextState, replace) {
  if (!isCustomerLoggedIn()) {
    localStorage.removeItem("fbAccessToken");
    localStorage.clear();
    replace({
      pathname: "/SHC/Login",
      state: { nextPathname: nextState.location.pathname },
    });
  }
}

function checkUser(token) {
  if (token) {
    const userProfile = decode(token);
    if (userProfile && !userProfile.user.Block) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function checkAdminRole() {
  const token = getAccessToken();
  if (token) {
    const userProfile = decode(token);
    if (
      userProfile.user.userRole &&
      (userProfile.user.userRole === "admin" ||
        userProfile.user.userRole === "crm")
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getCustomerData(token) {
  const userProfile = decode(token);
  if (userProfile) return userProfile.user;
  else return null;
}

export function isLoggedIn() {
  const accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

export function isCustomerLoggedIn() {
  const accessToken = getCustomerAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

export function getCustomerAccessToken() {
  return localStorage.getItem("fbAccessToken");
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  if (expirationDate < new Date()) {
    clearAccessToken();
  }
  return expirationDate < new Date();
}

function getTokenExpirationDate(encodedToken) {
  try {
    if (encodedToken) {
      const token = decode(encodedToken);
      if (!token.exp) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(token.exp);
      return date;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function clearAccessToken() {
  localStorage.removeItem("accessToken");
  localStorage.clear();
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route component={Login} path="/" onEnter={checkLoggedIn} exact={true} />
      <Route
        component={CustomerLogin}
        path="/SHC/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />
      <Route
        component={AllDeveloperCustomerLogin}
        path="/AllDeveloper/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />

      <Route
        component={JapanSHC}
        path="/JapanSHC/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />

      <Route
        component={CNSHC}
        path="/CNSHC/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />
      <Route
        component={AudioHouseCustomerLogin}
        path="/AudioHouse/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />
      <Route
        component={PropertyLiveCustomerLogin}
        path="/ThePropertyLive/Login"
        onEnter={checkCustomerLoggedIn}
        exact={true}
      />
      <Route
        component={RegistrationCustomerLogin}
        path="/Registration/Login"
        exact={true}
      />
      <Route
        component={CustomerDashBoard}
        path="/CustomerDashBoard"
        onEnter={requireCustomerAuth}
        exact={true}
      />
      <Route component={PrivacyPolicy} path="/PrivacyPolicy" exact={true} />
      <Route
        component={CheckoutCallBack}
        path="/CheckoutCallBack"
        exact={true}
      />
      <Route component={FbCallBack} path="/connect/facebook" />
      <Route
        component={RegistrationFbCallBack}
        path="/connect/Registration/facebook"
      />
      <Route
        component={CustomerFbCallBack}
        path="connect/CustomerLogin/facebook"
      />
      <Route component={SearchOrders} path="/SearchOrders" />
      <Route
        component={Checkout}
        path="/checkout/:orderId/paymentConfirmation/:time"
      />
      <Route component={Checkout} path="/checkout/:orderId" />
      <Route
        component={DisplayQrcCode}
        path="/paymentQrc/:qrcCode/orderId/:orderId"
      />
      <Route component={Registration} path="/started" />
      <Route component={BasicSubscription} path="/StarterSubscription" />
      <Route component={ProSubscription} path="/ProSubscription" />
      <Route
        component={Dashboard}
        path="/Dashboard"
        onEnter={requireAdminAuth}
        exact={true}
      >
        <IndexRoute component={Order} />
        <Route path="/Dashboard/Groups" component={Groups} exact={true} />
        <Route path="/Dashboard/Keywords" component={Keywords} exact={true} />
        <Route path="/Dashboard/Coupons" component={Coupons} exact={true} />
        <Route
          path="/Dashboard/ImportCoupons"
          component={ImportCoupons}
          exact={true}
        />
        <Route
          path="/Dashboard/ImageUpload"
          component={ImageUpload}
          exact={true}
        />

        <Route
          path="/Dashboard/MessageFormat"
          component={MessageFormate}
          exact={true}
        />
        <Route
          path="/Dashboard/UserDetail"
          component={UserDetail}
          exact={true}
        />
        <Route
          path="/Dashboard/DeliveryDate"
          component={DeliveryDate}
          exact={true}
        />
        <Route path="/Dashboard/PaidOrder" component={PaidOrder} exact={true} />
        <Route
          path="/Dashboard/ShippingCharge"
          component={DeliveryFees}
          exact={true}
        />
        <Route
          path="/Dashboard/ImportKeywords"
          component={ImportOrders}
          exact={true}
        />

        <Route
          path="/Dashboard/currentShow"
          component={currentShow}
          exact={true}
        />
        <Route
          path="/Dashboard/linkCampaign"
          component={linkCampaign}
          exact={true}
        />
        <Route
          path="/Dashboard/OrderCancelDateTime"
          component={LastDateTime}
          exact={true}
        />
        <Route
          path="/Dashboard/MinimumOrder"
          component={MinimumOrder}
          exact={true}
        />
        <Route
          path="/Dashboard/CancelOrder"
          component={CancelOrder}
          exact={true}
        />
        <Route
          path="/Dashboard/DisplayProductsOnShoppingCart"
          component={DisplayProductsOnShoppingCart}
          exact={true}
        />
        <Route
          path="/Dashboard/SendMassMessages"
          component={MassMessage}
          exact={true}
        />
        <Route
          path="/Dashboard/EarnPointManagement"
          component={EarnPointManagement}
          exact={true}
        />
        <Route
          path="/Dashboard/RedeemPointManagement"
          component={RedeemPointManagement}
          exact={true}
        />
        <Route
          path="/Dashboard/ExchangePointValue"
          component={ExchangePointValue}
          exact={true}
        />
        <Route
          path="/Dashboard/UserPointSummaryReport"
          component={UserPointSummaryReport}
          exact={true}
        />
        <Route
          path="/Dashboard/FirstSignUpCustomersIncentivePoints"
          component={FirstSignUpCustomersIncentivePoints}
          exact={true}
        />
        <Route
          path="/Dashboard/OrderItems"
          component={OrderItemsDisplay}
          exact={true}
        />
        <Route path="/Dashboard/LuckyDraw" component={LuckyDraw} exact={true} />
        <Route
          path="/Dashboard/LuckyDrawWall"
          component={LuckyDrawWall}
          exact={true}
        />
        <Route
          path="/Dashboard/ShareButtonVisible"
          component={ShareButtonVisible}
          exact={true}
        />
        <Route
          path="/Dashboard/Transactions"
          component={Transactions}
          exact={true}
        />
        <Route
          path="/Dashboard/ReferralIncentivePoints"
          component={ReferralIncentivePoints}
          exact={true}
        />
        <Route
          path="/Dashboard/PreRegistration"
          component={PreRegistration}
          onEnter={checkAdminRole}
          exact={true}
        />
        <Route
          path="/Dashboard/NewUserRequest"
          component={NewUserRequest}
          onEnter={checkAdminRole}
          exact={true}
        />
        <Route path="/Dashboard/Support" component={Support} exact={true} />
        <Route path="/Dashboard/MyPlan" component={MyPlan} exact={true} />
        <Route path="/Dashboard/MyProfile" component={MyProfile} exact={true} />
        {/* <Route
          path="/Dashboard/DirectComment"
          component={DirectComment}
          exact={true}
        /> */}
        <Route
          path="/Dashboard/DisableRemoveItems"
          component={DisableRemoveItems}
          exact={true}
        />
        <Route
          path="/Dashboard/DeductQtyUponPayment"
          component={DeductQtyUponPayment}
          exact={true}
        />
        <Route path="/Dashboard/FeedBack" component={FeedBack} exact={true} />
        <Route
          path="/Dashboard/CallBackSetting"
          component={CallBackSetting}
          exact={true}
        />
      </Route>
      <Route
        component={Dashboard}
        path="/CRM/Dashboard"
        onEnter={requireAdminAuth}
        exact={true}
      >
        <IndexRoute component={PreRegistration} />
        <Route
          path="/Dashboard/PreRegistration"
          component={PreRegistration}
          onEnter={checkAdminRole}
          exact={true}
        />
        <Route
          path="/Dashboard/NewUserRequest"
          component={NewUserRequest}
          onEnter={checkAdminRole}
          exact={true}
        />
      </Route>
      <Route path="*" component={NotFound} exact={true} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
