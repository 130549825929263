import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container d-flex justify-content-center flex-column align-items-center">
        <div className="footer_logo">
          {/* <img src="https://www.ordrstartr.com/wp-content/uploads/2020/12/Ordr-Startr-White-logo.png"/> */}
          <img
            src={"/assets/image/ordrstartr.png"}
            className="LogoImage m-auto"
          />
        </div>
        <div className="row justify-content-center">
          <div className="footer_links">
            <span className="links_header_footer">ORDR STARTR</span>
            <a
              className="link_header_footer"
              href="https://www.ordrstartr.com/#faq"
            >
              FAQ
            </a>
            <a
              className="link_header_footer"
              href="https://www.ordrstartr.com/contact-sales/"
            >
              Contact Sales
            </a>
          </div>
          <div className="footer_links">
            <span className="links_header_footer">PRODUCT</span>
            <a
              className="link_header_footer"
              href="https://www.ordrstartr.com/features/"
            >
              Features
            </a>
            <a
              className="link_header_footer"
              href="https://www.ordrstartr.com/pricing/"
            >
              Pricing Plan
            </a>
          </div>
          <div className="footer_links">
            <span className="links_header_footer">PARTNER</span>
            <a
              className="link_header_footer"
              href="https://www.ordrstartr.com/become-a-partner/"
            >
              Become A Partner
            </a>
          </div>
        </div>
        <div className="row align-items-center mt-3 mb-3 justify-content-center">
          <p className="footer_p mr-6 text_center pl-4 pr-4">
            © 2021 Ordr Startr. All Rights Reserved. | Developed by{" "}
            <a href="https://craft.com.sg/">Craft.</a>
          </p>
          <p className="">
            <a
              className="link_header_footer mr-3 ml-3"
              href="https://www.ordrstartr.com/privacy-policy/"
            >
              Privacy Policy
            </a>
            <a
              className="link_header_footer mr-3 ml-3"
              href="https://www.ordrstartr.com/terms-of-service/"
            >
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
