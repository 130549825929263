import React from 'react';
import './style.css';
import Header from './header';
import Footer from './footer';
import {browserHistory} from "react-router";

const Registration = () => {

    const redirectUrl = (url) => {
        browserHistory.push(url);
    };

    return (
        <div>
            <div className="layout mt-3">
                <Header/>
                <div className="container">
                    <div className="d-flex flex-column justify-content-center align-items-center h_400">
                        <h2 className="h_2 text_center">GET STARTED FREE NOW</h2>
                        <p className="p_p text_center">Do you need any payment gateway?</p>
                        <div className="row d-flex p-3 justify-content-center w-100">
                            <a className="responsive_but no_but"
                               onClick={() => redirectUrl("/StarterSubscription")}>NO</a>
                            <a className="responsive_but yes_but"
                               onClick={() => redirectUrl("/ProSubscription")}>Yes</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );

};

export default Registration;
