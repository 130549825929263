import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import "./style.css";
import { Card } from "@material-ui/core";

const ShareButtonVisible = (props) => {
  const [shareButtonStatus, setShareButtonStatus] = useState(false);

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage) {
      setShareButtonStatus(adminReducers.selectedPage.ShareButtonVisible);
    }
  }, [adminReducers.selectedPage]);

  const updateDate = () => {
    props.actions.adminAction.editPageDetail(
      {
        ShareButtonVisible: shareButtonStatus,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = () => {
    setShareButtonStatus(!shareButtonStatus);
  };

  return (
    <Card className="searchEngine flex-row justify-content-start align-items-center">
      <div className="d-flex align-items-center">
        <label> Share Button visible Status </label> &nbsp; &nbsp;
        <Switch
          checked={shareButtonStatus}
          onChange={handleChange}
          name="PageStatus"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        className="ml-3"
        onClick={updateDate}
      >
        Save
      </Button>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ShareButtonVisible);
