import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import BackupIcon from "@material-ui/icons/Backup";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ReactExport from "react-export-excel";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import Zoom from "react-medium-image-zoom";
// import "./react-confirm-alert.css";
import "react-medium-image-zoom/dist/styles.css";
// import { confirmAlert } from "react-confirm-alert";
import { Card } from "@material-ui/core";
import { bindActionCreators } from "redux";
import ImageLoader from "react-load-image";

import * as adminAction from "../../../actions/authAction";
// import AddDialog from "./addDialog";
// import EditDialog from "./editDialog";
import ENVIRONMENT_VARIABLES from "../../../environment.config";
import ImageUploading from "react-images-uploading";
import { confirmAlert } from "react-confirm-alert";

// import "./style.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function createData(path, name, url, _id) {
  return {
    path,
    name,
    url,
    _id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "path", numeric: false, disablePadding: true, label: "Image" },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "url",
    numeric: false,
    disablePadding: true,
    label: "Url",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products Image Management
        </Typography>
      )}

      {numSelected > 0 ? (
        <span className="d-flex">
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={props.DeleteRecords}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ) : (
        <Tooltip title="">
          <IconButton
            aria-label="Add New"
            // onClick={props.handleClickOpen}
          >
            {/* <AddIcon /> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: "calc(100vh - 316px)",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ImageUpload = (props) => {
  const classes = useStyles();
  const [images, setImages] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editKeyword, setEditKeyword] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const [offlinePurchase, setOfflinePurchase] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
    allImage: state.adminReducer.allImage,
  }));

  useEffect(() => {
    props.actions.adminAction.getAllImages(
      adminReducers.selectedPage?.FbPageId
    );
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.allImage) {
      FillAllKeywords(adminReducers.allImage);
    }
  }, [adminReducers.allImage]);

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setIsLive(adminReducers.selectedPage.repeatedStatus);
      setOfflinePurchase(adminReducers.selectedPage.Is_OfflinePurchase);
    }
  }, [adminReducers.selectedPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const DeleteRecords = () => {
    confirmAlert({
      key: "123",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.actions.adminAction.deleteImages(selected);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSubmitClose = (addKeyWord) => {
    addKeyWord.FbPageId = adminReducers.selectedPage.FbPageId;
    props.actions.adminAction.addKeyWords(addKeyWord);
    setOpen(false);
  };

  const HandleEditOpen = () => {
    const findKeyForEdit = adminReducers.allImage.find(
      (data) => data._id === selected[0]
    );
    setEditKeyword(findKeyForEdit);
    setEditOpen(true);
  };

  const FillAllKeywords = (allImages, name = "") => {
    const allImage = [];
    let exceldata = [];
    let colors = ["FF0000FF", "FF00FF00", "FFF86B00", "FFFFFF00", "FFCCEEFF"];
    let colorCount = 0;
    let totalSales = 0;
    allImages.map((singleKeyWords, counter) => {
      let add = false;
      if (name !== "") {
        if (name !== "") {
          if (data.name.toLowerCase().includes(name)) {
            add = true;
          }
        } else if (name === "") {
          if (data.keyword.toLowerCase().includes(keyword)) {
            add = true;
          }
        }
      } else {
        add = true;
      }
      if (add) {
        allImage.push(
          createData(
            singleKeyWords.path,
            singleKeyWords.name,
            singleKeyWords.path,
            singleKeyWords._id
          )
        );
        //Todo export Keywords
        colorCount = 4;
        totalSales += singleKeyWords.sold * singleKeyWords.price;

        const data = [
          {
            value: (counter + 1).toString(),
            style: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: colors[colorCount] },
              },
            },
          },

          {
            value: singleKeyWords.name,
            style: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: colors[colorCount] },
              },
            },
          },
          {
            value: singleKeyWords.path,
            style: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: colors[colorCount] },
              },
            },
          },
        ];
        exceldata.push(data);
      }
    });

    let data = [
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: colors[colorCount] } },
        },
      },

      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: colors[colorCount] } },
        },
      },
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: colors[colorCount] } },
        },
      },
    ];
    exceldata.push(data);

    data = [
      {
        columns: ["Number", "Name", "Url"],
        data: exceldata,
      },
    ];
    setRows(allImage);
    setSelected([]);
    setExcelData([...data]);
  };

  const handleImgUpload = () => {
    const imgs = images.map((data) => data.file);

    props.actions.adminAction.addImages(
      imgs,
      adminReducers.selectedPage.FbPageId
    );
    setImages([]);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <div className="d-flex align-items-center gap-20">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
            accept="image/*"
            // acceptType={["jpg", "jpeg", "png"]}
            style={{ outline: "unset" }}
          >
            {({ onImageUpload, dragProps }) => (
              <Button size="large" onClick={onImageUpload} {...dragProps}>
                <span> Upload Image</span>
                &nbsp; <BackupIcon />
              </Button>
            )}
          </ImageUploading>
          <Button
            variant="contained"
            color="primary"
            onClick={handleImgUpload}
            disabled={images.length < 1}
          >
            Save
          </Button>
        </div>
        <ExcelFile
          element={
            <Button
              variant="contained"
              className="but_width m-0"
              color="primary"
            >
              <GetAppIcon />
            </Button>
          }
          filename="UploadImage"
        >
          <ExcelSheet dataSet={excelData} name="Organization" />
        </ExcelFile>
      </Card>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          DeleteRecords={DeleteRecords}
          handleClickOpen={handleClickOpen}
          HandleEditOpen={HandleEditOpen}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let color = "black";
                  if (row.stock === 0) {
                    color = "red";
                  } else if (row.stock < 40) {
                    color = "orange";
                  }
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell>
                        <Zoom>
                          <ImageLoader
                            src={`${ENVIRONMENT_VARIABLES.UPLOAD_IMAGE_URL}${row.path}`}
                          >
                            <img
                              className="img-fluid"
                              style={{ height: "60px", width: "60px" }}
                              alt={row.FbId}
                            />
                            <img
                              src="/assets/image/NoImages.png"
                              style={{ height: "60px", width: "60px" }}
                              alt={row.FbId}
                            />
                            <img
                              src="/assets/image/imageLoader.png"
                              style={{ height: "60px", width: "60px" }}
                              alt={row.FbId}
                            />{" "}
                          </ImageLoader>
                        </Zoom>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.url}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ImageUpload);
