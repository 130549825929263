import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export const EditDialog = (props) => {
  const [keyword, setKeyword] = useState(props.editItem);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newDetail = { ...keyword };
    newDetail[name] = value;
    newDetail.total = Number(newDetail.price * value).toFixed(2);
    setKeyword(newDetail);
  };

  const handleSubmitClose = (keyword) => {
    props.handleEditSubmitClose(keyword);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>
          <DialogContent>
            <div className="row" style={{ gridRowGap: "30px" }}>
              <div className="col-sm-6">
                <TextValidator
                  fullWidth
                  label="Description"
                  variant="outlined"
                  size="small"
                  name="itemName"
                  value={keyword.itemName}
                />
              </div>
              <div className="col-sm-6">
                <TextValidator
                  fullWidth
                  label="Keyword"
                  variant="outlined"
                  size="small"
                  name="keyword"
                  value={keyword.keyword}
                />
              </div>
              <div className="col-sm-6">
                <TextValidator
                  fullWidth
                  className="flex-grow-1"
                  label="Price"
                  variant="outlined"
                  size="small"
                  name="price"
                  type="number"
                  value={keyword.price}
                />
              </div>
              <div className="col-sm-6">
                <TextValidator
                  fullWidth
                  label="qty"
                  variant="outlined"
                  size="small"
                  name="qty"
                  value={keyword.qty}
                  type="number"
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-sm-6">
                <TextValidator
                  fullWidth
                  label="total"
                  variant="outlined"
                  size="small"
                  name="total"
                  value={keyword.total}
                  type="number"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            {/* <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button> */}
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            {/* <Button type="submit">Save</Button> */}
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default EditDialog;
