import {
    CUSTOMER_INPROGRESS,
    CUSTOMER_SUCCESS,
    CUSTOMER_ERROR,
    CUSTOMER_LOGOUT,
    CUSTOMER_LOGIN,
    CUSTOMER_UPDATE,
    CREATE_CART,
    CUSTOMER_ORDERS,
    NEW_REGISTRATION,
    CUSTOMER_DIRECT_COMMENT, NEW_COMMENT
} from '../constants/actionTypes';
import initialState from './initialState';
import decode from "jwt-decode";

export default function customerReducer(state = initialState.customerReducer, action) {

    switch (action.type) {

        case CUSTOMER_INPROGRESS:
            return {...state, error_msg: null, loading: true, success_msg: null, orderNumber: null};

        case CUSTOMER_LOGIN:
            localStorage.setItem('fbAccessToken', action.data.accessToken);
            return {
                ...state,
                isAuthenticated: true,
                error_msg: null,
                loading: false,
                logout: false,
                fbUserProfile: decode(action.data.accessToken),
                token: action.data.accessToken
            };

        case CUSTOMER_SUCCESS:
            return {
                ...state,
                error_msg: null,
                orders: action.Orders.Orders,
                fbUserProfile: action.UserDetail,
                fbUserPage: action.FbPageDetail,
                transactions: action.Transactions,
                loading: false
            };

        case CUSTOMER_UPDATE:
            return {
                ...state,
                fbUserProfile: action.data.data,
                success_msg: action.data.result,
                loading: false
            };

        case CUSTOMER_ERROR:
            return {...state, loading: false, error_msg: action.data.error_msg, success_msg: null};

        case CUSTOMER_LOGOUT:
            return {
                ...state,
                logout: true,
                isAuthenticated: false,
                error_msg: null,
                loading: false,
                fbUserProfile: null,
                token: null
            };

        case CREATE_CART:
            return {
                ...state,
                error_msg: null,
                orderNumber: action.data.OrderNumber,
                loading: false
            };

        case CUSTOMER_ORDERS:
            return {
                ...state,
                loading: false,
                error_msg: null,
                orders: action.data.Orders,
                months: action.months
            };

        case NEW_REGISTRATION:
            return {
                ...state,
                loading: false,
                error_msg: null,
                success_msg: action.data.result
            };

        case CUSTOMER_DIRECT_COMMENT:
            return {
                ...state,
                loading: false,
                error_msg: null,
                success_msg: action.data.result
            };

        case NEW_COMMENT:
            state.comments.unshift(action.data);
            return {
                ...state,
                loading: false,
                error_msg: null,
                success_msg: null,
                comments: [...state.comments]
            };

        default:
            return state;
    }
};
