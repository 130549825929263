import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import "./style.css";

let moment = require("moment-timezone");

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const currentShow = (props) => {
  const classes = useStyles();

  const [liveOrder, setLiveOrder] = useState([]);

  const adminReducers = useSelector((state) => ({
    allLiveShow: state.adminReducer.allLiveShow,
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage) {
      props.actions.adminAction.getCurrentShow(
        adminReducers.selectedPage.FbPageId
      );
    }
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.allLiveShow.length > 0) {
      setLiveOrder(adminReducers.allLiveShow);
    }
  }, [adminReducers.allLiveShow]);

  const handleRefresh = () => {
    props.actions.adminAction.getCurrentShow(
      adminReducers.selectedPage.FbPageId
    );
  };

  return (
    <div className="liveshow-layout">
      <div className="liveshow-header">
        <h4 className="w-100 text-center">Recent Posts</h4>
        <Button
          variant="contained"
          color="secondary"
          className="ml-3"
          onClick={handleRefresh}
        >
          <RefreshIcon />
        </Button>
      </div>

      <div className="liveshow-body">
        {liveOrder.length > 0 &&
          liveOrder.map((show) => (
            <div className="liveshow-card">
              <Card className="liveshow-card-inner">
                <CardActionArea className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    src={
                      "/assets/image/blue-social-media-logo_197792-1759.avif"
                    }
                    width={"40%"}
                  />

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {show.message}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {moment
                        .tz(show.created_time, "Asia/Singapore")
                        .format("DD-MM-YYYY hh:mm A")}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(currentShow);
