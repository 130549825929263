import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const ReferralIncentivePoints = (props) => {
  const [data, setData] = useState({
    ReferralPoint: 0,
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setData({
        ReferralPoint: adminReducers.selectedPage.ReferralPoint,
      });
    }
  }, [adminReducers.selectedPage]);

  const updatePageDetail = () => {
    props.actions.adminAction.editPageDetail(
      data,
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  return (
    <Card className="searchEngine flex-column">
      {/* <div className="d-flex flex-column w-25">
        <label> Referral incentive points </label>
        <input
          name="ReferralPoint"
          type="Number"
          className="form-control"
          value={data.ReferralPoint}
          onChange={handleChange}
          placeholder={"Referral Point"}
        />
      </div> */}
      <TextField
        className="filter-box"
        variant="outlined"
        size="small"
        name="ReferralPoint"
        type="Number"
        value={data.ReferralPoint}
        onChange={handleChange}
        label="Referral Point"
      />
      <div className="d-flex flex-row w-25 justify-content-end">
        <Button variant="contained" color="primary" onClick={updatePageDetail}>
          Save
        </Button>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ReferralIncentivePoints);
