import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {getCustomerData} from "../../../index";
import CheckIcon from '@material-ui/icons/Check';
import $ from "jquery";
import './style.css';


const MyPlan = () => {

    const [user, setUser] = useState(null);
    const [basicList, setBasicList] = useState([
        'Auto Order Capturing',
        'Auto Comment Reply',
        'Auto Send Shopping Cart',
        'Easy Keywords Set up',
        'Schedule Closed Order',
        'Real time Sales Performance',
        'Payment Gateway Integration',
        'Delivery Management'
    ]);

    const [proList, setProList] = useState([
        'Auto Order Capturing',
        'Auto Comment Reply',
        'Auto Send Shopping Cart',
        'Easy Keywords Set up',
        'Schedule Closed Order',
        'Real time Sales Performance',
        'Payment Gateway Integration',
        'Delivery Management',
        'Customer Management',
        'Promo Code and Referral Code',
        'Loyalty Programme Rewards',
        'Collect Payment via Payment Gateway',
        'Advance messaging function',
        'Sales Report Summary'
    ]);

    const adminReducers = useSelector((state) => ({
        token: state.adminReducer.token
    }));

    useEffect(() => {
        $('body > .overlay').removeClass("blocked !important");
        return function addBlocked() {
            if (user && !user.IsActive) {
                $('body > .overlay').addClass("blocked !important");
            }
        }
    });

    useEffect(() => {
        if (adminReducers.token) {
            const userData = getCustomerData(adminReducers.token);
            if (userData)
                setUser({...userData});
        }
    }, [adminReducers.token]);

    return (
        <div>
            <div className="main_layoutMyPlan">
                <div className="layout_card">
                    <div className="layout_card_inner">
                        <h1 className="card_header_text">Starter</h1>
                        <h1 className="card_header_text">$100</h1>
                        <div className="lists">
                            {basicList.map((singleList) => (
                                <div className="list">
                                    <CheckIcon/>
                                    &nbsp;
                                    &nbsp;
                                    <span className="liat_text">{singleList}</span>
                                </div>
                            ))}
                        </div>
                        {user && user.Type === "basic" ?
                            <div className="disable_layout"></div>
                            : <div className="footer_but">
                                <button className="but2">GET STARTED</button>
                            </div>
                        }
                    </div>
                </div>
                <div className="layout_card">
                    <div className="layout_card_inner">
                        <h1 className="card_header_text">Pro</h1>
                        <h1 className="card_header_text">$200</h1>
                        <div className="lists">
                            {proList.map((singleList) => (
                                <div className="list">
                                    <CheckIcon/>
                                    &nbsp;
                                    &nbsp;
                                    <span className="liat_text">{singleList}</span>
                                </div>
                            ))}
                        </div>
                        {user && user.Type === "pro" ?
                            <div className="disable_layout"></div>
                            : <div className="footer_but">
                                <button className="but2">GET STARTED</button>
                            </div>
                        }
                    </div>
                </div>
                <div className="layout_card">
                    <div className="layout_card_inner">
                        <h1 className="card_header_text">Looking for VIP plan?</h1>
                        <br/>
                        <h6>Advanced features for scaling your business</h6>
                        {user && user.Type === "vip" ?
                            <div className="disable_layout"></div>
                            : <div className="footer_but">
                                <a href={"https://api.whatsapp.com/send/?phone=6581385370&text&app_absent=0"}
                                   target={"_blank"}
                                   className="but2">GET STARTED</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

};

export default MyPlan;
