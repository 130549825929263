import axios from "axios";
import ENVIRONMENT_VARIABLES from "../environment.config";
import {
  ADMIN_INPROGRESS,
  IS_AUTHENTICATED,
  ADMIN_ERROR,
  LOGOUT,
  ALL_KEYWORDS,
  DELETE_KEYWORDS,
  ADD_KEYWORDS,
  SELECTED_PAGE,
  PAGE_LOAD_INTO_LOCAL,
  ADD_ORDER,
  CHANGE_PAGE_STATUS,
  EDIT_KEYWORDS,
  EDIT_KEYWORDS_QTY,
  SEND_FB_MESSAGE,
  GET_USER_ORDER,
  EDIT_ORDER,
  EDIT_PAGE_DETAIL,
  CHECK_OUT,
  IMPORT_ORDER,
  SEARCH_ORDER,
  ADD_COUPONS,
  EDIT_COUPON,
  DELETE_COUPON,
  GET_COUPON,
  SEARCH_ORDER_FILTER,
  SEARCH_ORDER_ITEMS_FILTER,
  USER_ORDERS,
  ADMIN_INPROGRESS_ORDER,
  SEARCH_USER_DETAIL_FILTER,
  SELECT_TIME_SLOT,
  CONFIRM_TIME_SLOT,
  GET_COUPONS,
  EDIT_ALL_KEYWORDS,
  ADD_LUCKY_DRAW,
  WINNER_LIST,
  SEARCH_TRANSACTIONS_FILTER,
  DELIVERY_SLOTS_PAGE_DETAIL,
  SEARCH_PREREGISTRATION_FILTER,
  SEARCH_NEWREGISTRATION_FILTER,
  EDIT_REGISTRATION_USER,
  EDIT_REGISTRATION_USER_WITH_TOKEN,
  ADD_USER_DETAILS,
  GET_DELIVERY_FEES,
  EDIT_DELIVERY_FEES,
  DELETE_DELIVERY_FEES,
  ADD_DELIVERY_FEES,
  GET_SUPPLIERS_DATES,
  ADD_SUPPLIER_DELIVERY_DATE,
  DELETE_SUPPLIER_DELIVERY_DATE,
  SEARCH_USER_POINT_SUMMARY_REPORT,
  ADD_GROUPS,
  EDIT_GROUPS,
  DELETE_GROUPS,
  ALL_GROUPS,
  GET_LIVE_SHOW,
  ADD_LIVE_ORDER,
  GET_ALL_SHOW,
  SEARCH_LIVE_ORDER_FILTER,
  GET_ALL_LIVE_POST,
  ADD_LIVE_SHOW_GROUP,
  GET_ALL_LIVE_SHOW_GROUP,
  DELETE_LIVE_SHOW_GROUPS,
  IMPORT_COUPON,
  EDIT_USER,
  DELETE_USER_PROFILE,
  UPDATE_USER_DEDUC,
  ADD_IMAGES,
  DELETE_IMAGES,
  ALL_IMAGES,
} from "../constants/actionTypes";
import { disconnect } from "../socket";

export const loginUser = (credentials) => {
  try {
    return (dispatch) => {
      const loginDetails = {
        userId: credentials.userId,
        password: credentials.password,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios
        .post(
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Oauths/login",
          loginDetails
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: IS_AUTHENTICATED,
              data: { accessToken: response.data.accessToken },
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const RequestNewUserAndFbLogin = (fbToken) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      dispatch({
        type: IS_AUTHENTICATED,
        data: { accessToken: fbToken },
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const logout = () => {
  try {
    disconnect();
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      localStorage.removeItem("accessToken");
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getHomeCall = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/keywords/" + FbPageId,
      };
      let orderApi = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/FbPage/" +
          FbPageId +
          "/orderSearch",
        data: {
          orderSearch: {
            orderName: "",
            orderNumber: "",
            fromDate: "",
            toDate: "",
            paymentId: "",
          },
        },
      };
      axios
        .all([axios(api), axios(orderApi)])
        .then(
          axios.spread((Keywords, Orders) => {
            dispatch({
              type: ALL_KEYWORDS,
              Keyword: Keywords.data,
              Orders: Orders.data,
            });
          })
        )
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getHomeCallGroup = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/group/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ALL_GROUPS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getAllImages = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/fileupload/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ALL_IMAGES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getCurrentShow = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/post/currentlive/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_LIVE_SHOW,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getAllShow = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/post/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_SHOW,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getAllPost = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/post/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_LIVE_POST,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getAllLiveShowGroup = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/liveshowgroup/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_LIVE_SHOW_GROUP,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteKeyWords = (DeleteKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/keywords",
        data: { keywords: DeleteKeyWords },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_KEYWORDS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteImages = (DeleteKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/fileupload",
        data: { Files: DeleteKeyWords },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_IMAGES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteGroups = (DeleteKeyWord) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/group",
        data: { keywords: DeleteKeyWord },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_GROUPS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteLiveShowGroups = (DeleteKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/liveshowgroup",
        data: { liveShowGroups: DeleteKeyWords },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_LIVE_SHOW_GROUPS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateUserDeduc = (orderDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/UserDetails/deductpoints",
        data: orderDetail,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_USER_DEDUC,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteCoupon = (DeleteKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Coupons",
        data: { keywords: DeleteKeyWords },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addKeyWords = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      var FormData = require("form-data");
      var data = new FormData();
      data.append("description", AddKeyWords.description);
      data.append("keyword", AddKeyWords.keyword);
      data.append("price", AddKeyWords.price);
      data.append("stock", AddKeyWords.stock);
      data.append("maxQty", AddKeyWords.maxQty);
      data.append("SKU", AddKeyWords.SKU);
      data.append("supplierName", AddKeyWords.supplierName);
      data.append("reply_message", AddKeyWords.reply_message);
      data.append("FbPageId", AddKeyWords.FbPageId);
      data.append("image", AddKeyWords.image);
      data.append("groupID", AddKeyWords.groupID);

      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/keywords",
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_KEYWORDS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addImages = (AddImage, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      var FormData = require("form-data");
      var data = new FormData();

      AddImage.map((img) => data.append("image", img));

      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/fileupload/" + FbPageId,
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_IMAGES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addGroups = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/group",
        data: AddKeyWords,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_GROUPS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addLivePost = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/post/",
        data: AddKeyWords,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_LIVE_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addLiveShowGroup = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/liveshowgroup",
        data: AddKeyWords,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_LIVE_SHOW_GROUP,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getCouponDetail = (FbPageId, CouponName, OrderNumber, Order) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/FbPage/" +
          FbPageId +
          "/GetDetail/" +
          CouponName +
          "/Order/" +
          OrderNumber,
        data: Order,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const applyPoints = (OrderNumber, Points, Order) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/ApplyPoints/" +
          Points +
          "/Order/" +
          OrderNumber,
        data: Order,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const removeCoupon = (FbPageId, CouponName, OrderNumber, Order) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/FbPage/" +
          FbPageId +
          "/RemoveCoupon/" +
          CouponName +
          "/Order/" +
          OrderNumber,
        data: Order,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const cancelOrder = (FbPageId, CouponName, OrderNumber, Order) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/FbPage/" +
          FbPageId +
          "/CancelOrder/" +
          CouponName +
          "/Order/" +
          OrderNumber,
        data: Order,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addCoupon = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      if (AddKeyWords.CouponType == 9) {
        AddKeyWords.couponValue = AddKeyWords.couponValue._id;
      }

      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Coupons",
        data: AddKeyWords,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_COUPONS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editKeyWords = (AddKeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      var FormData = require("form-data");
      var data = new FormData();
      data.append("_id", AddKeyWords._id);
      data.append("description", AddKeyWords.description);
      data.append("keyword", AddKeyWords.keyword);
      data.append("price", AddKeyWords.price);
      data.append("stock", AddKeyWords.stock);
      data.append("maxQty", AddKeyWords.maxQty);
      data.append("SKU", AddKeyWords.SKU);
      data.append("supplierName", AddKeyWords.supplierName);
      data.append("reply_message", AddKeyWords.reply_message);
      data.append("FbPageId", AddKeyWords.FbPageId);
      data.append("image", AddKeyWords.image);
      data.append("counter", AddKeyWords.counter);
      data.append("visible", AddKeyWords.visible);
      data.append("groupID", AddKeyWords.groupID);

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/keywords",
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_KEYWORDS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editGroups = (editKeyWord) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/group",
        data: editKeyWord,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_GROUPS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editCoupon = (EditCoupon) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      delete EditCoupon.Applied;
      delete EditCoupon.FbId;
      delete EditCoupon.Count;

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Coupons",
        data: EditCoupon,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateOrderDetail = (orderDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      orderDetail.Order.Items.map((singleItems) => {
        singleItems.qty = Number(singleItems.qty);
      });

      let api = {
        method: "PUT",
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders",
        data: orderDetail,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateAdminOrderDetail = (orderDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      orderDetail.Order.Items.map((singleItems) => {
        singleItems.qty = Number(singleItems.qty);
      });

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/adminUpdateOrder",
        data: orderDetail,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateUserDetail = (orderDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/UserDetails/adminpoints",
        data: orderDetail,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_USER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editKeyWordsQty = (KeyWords) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      dispatch({
        type: EDIT_KEYWORDS_QTY,
        data: KeyWords,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addOrder = (order) => {
  try {
    return (dispatch) => {
      dispatch({
        type: ADD_ORDER,
        data: order,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const pageSelected = async (selectedPage) => {
  try {
    await disconnect();
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      dispatch({
        type: SELECTED_PAGE,
        data: selectedPage,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getAllPages = () => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const accessToken = localStorage.getItem("accessToken");
      const token = "Bearer " + accessToken;

      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages",
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: PAGE_LOAD_INTO_LOCAL,
              data: { userPages: response.data },
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const changePageStatus = (status, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages/status/" + FbPageId,
        data: {
          status: status,
        },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CHANGE_PAGE_STATUS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editPageDetail = (data, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages/" + FbPageId,
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_PAGE_DETAIL,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editPageCancelOrderStatus = (data) => {
  try {
    return (dispatch) => {
      dispatch({
        type: EDIT_PAGE_DETAIL,
        data: data,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const sendFacebookMessage = (ID) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const accessToken = localStorage.getItem("accessToken");
      const token = "Bearer " + accessToken;

      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages/Message/" + ID,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEND_FB_MESSAGE,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const sendMessageToAll = (FbPageId, search) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const accessToken = localStorage.getItem("accessToken");
      const token = "Bearer " + accessToken;

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/FbPages/All/Message/" +
          FbPageId,
        data: { orderSearch: search },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEND_FB_MESSAGE,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getOrderDetail = (OrderId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      let api = {
        method: "GET",
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/customer/" + OrderId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USER_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getCheckout = (OrderId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      let api = {
        method: "POST",
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/checkout/" + OrderId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CHECK_OUT,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const importData = (data, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/keywords/importKeywords/" +
          FbPageId,
        data: {
          keywords: data,
        },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: IMPORT_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const importCouponData = (data, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/importcoupons/" +
          FbPageId,
        data: {
          coupons: data,
        },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: IMPORT_COUPON,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchOrder = (orderId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      let api = {
        method: "GET",
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/orderSearch/" + orderId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_ORDER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchOrderFilter = (orderSearch, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/FbPage/" +
          FbPageId +
          "/orderSearch",
        data: {
          orderSearch: orderSearch,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_ORDER_FILTER,
              data: response.data,
              orderSearch: orderSearch,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchLiveOrderFilter = (liveOrderSearch, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      const payload = { ...liveOrderSearch };
      delete payload.orderName;
      delete payload.orderNumber;
      delete payload.paymentId;
      delete payload.postId;

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/post/filter/" + FbPageId,
        data: payload,
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_LIVE_ORDER_FILTER,
              data: response.data,
              // liveOrderSearch: liveOrderSearch,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchOrderItemsFilter = (orderSearch, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/FbPage/" +
          FbPageId +
          "/orderSearch",
        data: {
          orderSearch: orderSearch,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_ORDER_ITEMS_FILTER,
              data: response.data,
              orderSearch: orderSearch,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const usersOrders = (ID, FbSPID, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS_ORDER });
      const accessToken = localStorage.getItem("accessToken");
      const token = "Bearer " + accessToken;

      if (FbSPID === null || FbSPID === "") FbSPID = undefined;

      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/customer/" +
          FbPageId +
          "/customerOrderList/" +
          ID +
          "/FbSPID/" +
          FbSPID,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: USER_ORDERS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const usersReferralOrders = (ID, FbSPID, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS_ORDER });
      const accessToken = localStorage.getItem("accessToken");
      const token = "Bearer " + accessToken;

      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/customer/" +
          FbPageId +
          "/customerReferralOrderList/" +
          ID,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: USER_ORDERS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserDetailFilter = (Search, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/UserDetails/" + FbPageId,
        data: {
          search: Search,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_USER_DETAIL_FILTER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const confirmDeliverySlot = (data, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/TimeSlots/" + FbPageId,
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SELECT_TIME_SLOT,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const confirmLastDeliverySlot = (data, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/TimeSlots/confirmDeliverySlot/" +
          FbPageId,
        data: data,
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CONFIRM_TIME_SLOT,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getCoupons = (FbPageId, data) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Coupons/AllCoupons/" +
          FbPageId,
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_COUPONS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editKeyWordsAllVisible = (KeyWords, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/keywords/visibleAllProducts/" +
          FbPageId,
        data: KeyWords,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_ALL_KEYWORDS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addLuckyDraw = (data) => {
  try {
    return (dispatch) => {
      dispatch({
        type: ADD_LUCKY_DRAW,
        data: data,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const luckyDraw = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages/luckyDraw/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: WINNER_LIST,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchTransactionsFilter = (orderSearch, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Transactions/" + FbPageId,
        data: {
          orderSearch: orderSearch,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_TRANSACTIONS_FILTER,
              data: response.data,
              orderSearch: orderSearch,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getPageDetail = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/FbPages/PageDetail/DeliveryTimeSlots/" +
          FbPageId,
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELIVERY_SLOTS_PAGE_DETAIL,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const loading = () => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUnionCheckout = (OrderId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });

      let api = {
        method: "POST",
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Orders/unionCheckout/" +
          OrderId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CHECK_OUT,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchPreRegistrationFilter = (Search) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/RegistrationRequests/search",
        data: {
          orderSearch: Search,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_PREREGISTRATION_FILTER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getSearchNewRegistrationFilter = (Search) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/RegistrationRequests/searchSystemRegistration",
        data: {
          orderSearch: Search,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_NEWREGISTRATION_FILTER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editRegistrationUser = (data) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/RegistrationRequests",
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_REGISTRATION_USER,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editRegistrationUserWithToken = (data) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/RegistrationRequests/updateUserWithToken",
        data: data,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_REGISTRATION_USER_WITH_TOKEN,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addUserDetail = (userDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      dispatch({
        type: ADD_USER_DETAILS,
        data: userDetail,
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getDeliveryFees = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/DeliveryFees/" + FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DELIVERY_FEES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getOnlineDeliveryFees = (FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/DeliveryFees/online/" +
          FbPageId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DELIVERY_FEES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const editDeliveryFess = (EditDeliveryFees) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/DeliveryFees",
        data: EditDeliveryFees,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_DELIVERY_FEES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteDeliveryFees = (DeleteDeliveryFees) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/DeliveryFees",
        data: { deliveryFees: DeleteDeliveryFees },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_DELIVERY_FEES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addDeliveryFees = (AddDelivery) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/DeliveryFees",
        data: AddDelivery,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_DELIVERY_FEES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getPageSuppliersDates = (FbPageId, supplierId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/DeliveryDates/" +
          FbPageId +
          "/supplierId/" +
          supplierId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_SUPPLIERS_DATES,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addSupplierDeliveryDate = (AddDeliveryDate) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/DeliveryDates",
        data: AddDeliveryDate,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_SUPPLIER_DELIVERY_DATE,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteSupplierDeliveryDate = (DeleteDeliveryDateId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "DELETE",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/DeliveryDates/" +
          DeleteDeliveryDateId,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: DELETE_SUPPLIER_DELIVERY_DATE,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserPointSummaryReport = (Search, FbPageId) => {
  try {
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      const token = "Bearer " + localStorage.getItem("accessToken");

      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Transactions/Users/" +
          FbPageId,
        data: {
          search: Search,
        },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SEARCH_USER_POINT_SUMMARY_REPORT,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 501)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
