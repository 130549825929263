import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import * as adminAction from "../../../actions/authAction";
import Button from "@material-ui/core/Button";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const ExchangePointValue = (props) => {
  const [data, setData] = useState({
    ExchangePoint: 0,
    ExchangeRate: 0,
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      setData({
        ExchangeRate: adminReducers.selectedPage.ExchangeRate,
        ExchangePoint: adminReducers.selectedPage.ExchangePoint,
      });
    }
  }, [adminReducers.selectedPage]);

  const updatePageDetail = () => {
    props.actions.adminAction.editPageDetail(
      {
        ExchangePoint: data.ExchangePoint,
        ExchangeRate: data.ExchangeRate,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  return (
    <Card className="searchEngine flex-column">
      {/* <div className="d-flex flex-column w-25">
        <label> Exchange Point </label>
        <input
          name="ExchangePoint"
          type="Number"
          className="form-control"
          value={data.ExchangePoint}
          onChange={handleChange}
          placeholder={"Exchange Point"}
        />
      </div> */}

      <TextField
        className="filter-box"
        variant="outlined"
        size="small"
        name="ExchangePoint"
        type="Number"
        value={data.ExchangePoint}
        onChange={handleChange}
        label="Exchange Point"
      />

      {/* <div className="d-flex flex-column w-25">
        <label> Exchange Point Rate ($)</label>
        <input
          name="ExchangeRate"
          type="Number"
          className="form-control"
          value={data.ExchangeRate}
          onChange={handleChange}
          placeholder={"Exchange Rate"}
        />
      </div> */}

      <TextField
        className="filter-box"
        variant="outlined"
        size="small"
        name="ExchangeRate"
        type="Number"
        value={data.ExchangeRate}
        onChange={handleChange}
        label="Exchange Rate"
      />

      <div className="d-flex flex-row w-25 justify-content-end">
        <Button variant="contained" color="primary" onClick={updatePageDetail}>
          Save
        </Button>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ExchangePointValue);
