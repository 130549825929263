import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import Loader from '../Loader/index';
import Button from '@material-ui/core/Button';
import {bindActionCreators} from "redux";
import * as adminAction from "../../actions/authAction";
import swal from 'sweetalert';
import './style.css'
import {browserHistory} from "react-router";

const SearchOrders = (props) => {

    const adminReducers = useSelector((state) => ({
        loading: state.adminReducer.loading,
        error_msg: state.adminReducer.error_msg,
        success_msg: state.adminReducer.success_msg,
        searchOrderId: state.adminReducer.searchOrderId
    }));

    const [orderId, setOrderId] = useState("");

    useEffect(() => {
        if (adminReducers.error_msg !== null) {
            swal('Oops...', adminReducers.error_msg.toString(), 'error');
        }
    }, adminReducers.error_msg);

    //Todo error list orders
    useEffect(() => {
        if (adminReducers.searchOrderId !== null && adminReducers.searchOrderId !== "") {
            browserHistory.push('/checkout/' + adminReducers.searchOrderId._id);
        }
    }, [adminReducers.searchOrderId]);


    const Proceed = () => {
        if (orderId !== null) {
            props.actions.adminAction.getSearchOrder(orderId);
        }
    };

    const handleChange = (event) => {
        return setOrderId(event.target.value);
    };

    return (
        <span>
            <h3 className="pl-5 bg-primary mb-0 py-3" style={{color: "#fff"}}>
                Live Order
            </h3>
            <div className="w-100 d-flex justify-content-center">
            <div className="orderSearch d-flex flex-column align-items-center">
                <label>Please enter your Order Number</label>
                <input type="text" className="form-control" name="OrderNumber" onChange={handleChange}
                       value={orderId}/>
                <Button variant="contained" className="mx-2 mt-2" color="primary" onClick={Proceed}> Proceed </Button>
            </div>
            </div>
            {adminReducers.loading && <Loader/>}
        </span>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(SearchOrders);
