import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from "@material-ui/icons/Image";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Loader from "../Loader/index";
import { browserHistory, Link } from "react-router";
import { bindActionCreators } from "redux";
import * as adminAction from "../../actions/authAction";
import swal from "sweetalert";
import PageSelection from "./pageSelectionDialog";
import { newSocketMessage, connection } from "../../socket";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Switch from "@material-ui/core/Switch";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PaymentIcon from "@material-ui/icons/Payment";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import DeleteIcon from "@material-ui/icons/Delete";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import SendIcon from "@material-ui/icons/Send";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CachedIcon from "@material-ui/icons/Cached";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ComputerIcon from "@material-ui/icons/Computer";
import SettingsIcon from "@material-ui/icons/Settings";
import ShareIcon from "@material-ui/icons/Share";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import Collapse from "@material-ui/core/Collapse";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { getCustomerData } from "../../index";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DescriptionIcon from "@material-ui/icons/Description";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import TextsmsIcon from "@material-ui/icons/Textsms";
import PeopleIcon from "@material-ui/icons/People";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RemoveIcon from "@material-ui/icons/Remove";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SyncIcon from "@material-ui/icons/Sync";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MovieIcon from "@material-ui/icons/Movie";
import $ from "jquery";
import "./style.css";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#51057C",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const fontTheme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: 11,
    fontWeightLight: 1000,
  },
});

const DashBoard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const adminReducers = useSelector((state) => ({
    loading: state.adminReducer.loading,
    error_msg: state.adminReducer.error_msg,
    success_msg: state.adminReducer.success_msg,
    selectedPage: state.adminReducer.selectedPage,
    allPages: state.adminReducer.allPages,
    orders: state.adminReducer.orders,
    token: state.adminReducer.token,
    adminUser: state.adminReducer.adminUser,
  }));
  const [isLive, setIsLive] = useState(false);
  const [totalOrder, setTotalOrders] = useState(0);
  const [totalCancelOrder, setTotalCancelOrders] = useState(0);
  const [totalPaidOrder, setTotalPaidOrders] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const [userProvider, setUseruserProvider] = useState(null);
  const [openOrders, setOpenOrders] = useState(false);
  const [openLoyalty, setOpenLoyalty] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
  const [openKeyWords, setOpenKeyWords] = useState(false);
  const [openLive, setOpenLive] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openLuckyDraw, setOpenLuckyDraw] = useState(false);
  const [openReferral, setOpenReferral] = useState(false);
  const [openSuperAdmin, setOpenSuperAdmin] = useState(false);
  const [openCallBackSetting, setOpenCallBackSetting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileMenu = Boolean(anchorEl);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    connection();
  }, []);

  useEffect(() => {
    if (adminReducers.token) {
      try {
        const userData = getCustomerData(adminReducers.token);
        if (userData) {
          setUserRole(userData.userRole);
          props.actions.adminAction.addUserDetail(userData);
          if (userData.Provider === "facebook")
            setUseruserProvider(userData.Provider);
          if (!userData.IsActive) {
            $("body > .overlay").addClass("blocked !important");
          }
          const displayName =
            userData.FirstName.charAt(0).toUpperCase() +
            userData.LastName.charAt(0).toUpperCase();
          setUserName(displayName);
          if (
            adminReducers.allPages === null &&
            (userData.userRole === "admin" || userData.userRole === "normal")
          ) {
            props.actions.adminAction.getAllPages();
          }
        }
      } catch (error) {
        props.actions.adminAction.logout();
      }
    }
  }, [adminReducers.token]);

  const handleClick = () => {
    setOpenOrders(!openOrders);
  };

  const handleClickLoyalty = () => {
    setOpenLoyalty(!openLoyalty);
  };

  const handleClickPoints = () => {
    setOpenPoints(!openPoints);
  };

  const handleClickKeyWords = () => {
    setOpenKeyWords(!openKeyWords);
  };

  const handleClickLive = () => {
    setOpenLive(!openLive);
  };

  const handleClickMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleClickLuckyDraw = () => {
    setOpenLuckyDraw(!openLuckyDraw);
  };

  const handleClickReferral = () => {
    setOpenReferral(!openReferral);
  };

  const handleClickDelivery = () => {
    setOpenDelivery(!openDelivery);
  };

  const handleClickSuperAdmin = () => {
    setOpenSuperAdmin(!openSuperAdmin);
  };

  const handleClickCallBackSetting = () => {
    setOpenCallBackSetting(!openCallBackSetting);
  };

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      if (adminReducers.selectedPage.Is_update === true) {
        connection();
        const messageKey = adminReducers.selectedPage.FbPageId;
        newSocketMessage(messageKey, (err, data) => {
          switch (data.type) {
            case "keywordUpdate":
              if (adminReducers.selectedPage.FbPageId === data.data.FbPageId) {
                props.actions.adminAction.editKeyWordsQty(data.data);
              }
              break;

            case "pageUpdate":
              props.actions.adminAction.editPageCancelOrderStatus(data.data);
              break;

            case "order":
              if (adminReducers.selectedPage.FbPageId === data.data.FbPageId) {
                props.actions.adminAction.addOrder(data.data);
              }
              break;

            case "luckyDraw":
              props.actions.adminAction.addLuckyDraw(data.data);
              break;

            case "connectivity":
              NotificationManager.info(
                data.data.toString(),
                "Connectivity",
                10000
              );
              break;
          }
        });
        props.actions.adminAction.getHomeCall(
          adminReducers.selectedPage.FbPageId
        );
        setIsLive(adminReducers.selectedPage.Is_Live);
      } else {
        setIsLive(adminReducers.selectedPage.Is_Live);
      }
    }
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.error_msg !== null) {
      if (
        adminReducers.error_msg === "Failed to authenticate token." ||
        adminReducers.error_msg === "UnAuthorized user."
      ) {
        localStorage.removeItem("accessToken");
        localStorage.clear();
        browserHistory.push("/");
      } else {
        swal("Oops...", adminReducers.error_msg.toString(), "error");
      }
    }
  }, [adminReducers.error_msg]);

  useEffect(() => {
    if (adminReducers.orders !== null) {
      setTotalOrders(adminReducers.orders?.length);
      const totalPaid = adminReducers.orders?.filter(
        (singleOrder) =>
          singleOrder.PaymentStatus && singleOrder.PaymentStatus === "paid"
      );
      setTotalPaidOrders(totalPaid?.length);
      const cancelOrder = adminReducers.orders?.filter(
        (singleOrder) => singleOrder.Status && singleOrder.Status === "cancel"
      );
      setTotalCancelOrders(cancelOrder?.length);
    }
  }, [adminReducers.orders]);

  useEffect(() => {
    if (adminReducers.success_msg !== null) {
      swal("", adminReducers.success_msg, "success");
    }
  }, [adminReducers.success_msg]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.actions.adminAction.logout();
  };

  const handleChange = () => {
    if (adminReducers.selectedPage && adminReducers.selectedPage.FbPageId) {
      props.actions.adminAction.changePageStatus(
        !isLive,
        adminReducers.selectedPage.FbPageId
      );
    }
  };

  const redirectUrl = (url) => {
    browserHistory.push(url);
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={fontTheme}>
      <div className={classes.root}>
        <NotificationContainer />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            {/*<Typography variant="h6" noWrap>*/}
            {/*OrdrStartr*/}
            {/*</Typography>*/}
            {userRole && (userRole === "normal" || userRole === "admin") && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                {adminReducers.allPages !== null &&
                  adminReducers.adminUser !== null &&
                  adminReducers.adminUser.IsActive && (
                    <PageSelection
                      open={adminReducers.selectedPage === null ? true : false}
                    />
                  )}
                <span className="d-flex align-items-center gap-20">
                  <h6 className="m-0">Total &nbsp; {totalOrder} </h6>

                  <h6 className="m-0" style={{ color: "#fcbc0d" }}>
                    Paid &nbsp; {totalPaidOrder}
                  </h6>

                  <h6 className="m-0" style={{ color: "#ff3333" }}>
                    Cancel &nbsp; {totalCancelOrder}
                  </h6>
                </span>
                <div className="d-flex align-items-center">
                  <span> Connect to Facebook page </span>
                  <Switch
                    checked={isLive}
                    onChange={handleChange}
                    name="PageStatus"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </div>
            )}
            <span className="flex-space" />
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar>{userName}</Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfileMenu}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 150,
                  },
                }}
              >
                {userProvider && (
                  <span>
                    <MenuItem
                      onClick={() => redirectUrl("/Dashboard/MyProfile")}
                    >
                      My Profile
                    </MenuItem>
                    <MenuItem onClick={() => redirectUrl("/Dashboard/MyPlan")}>
                      My Plan
                    </MenuItem>
                    <MenuItem onClick={() => redirectUrl("/Dashboard/Support")}>
                      Support
                    </MenuItem>
                  </span>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <img
              src={"/assets/image/ordrstartr.png"}
              className="LogoImage m-auto"
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          {open && userProvider && (
            <center style={{ paddingRight: "40px" }}>
              <button
                className="upgradeBtn"
                onClick={() => redirectUrl("/Dashboard/MyPlan")}
              >
                UPGRADE PLAN
              </button>
            </center>
          )}
          <Divider />
          <List>
            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClick}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <DescriptionIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Order Management"
                    style={{ color: "#51057C" }}
                  />
                  {openOrders ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openOrders} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"order"}
                      onClick={() => redirectUrl("/Dashboard")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <ShoppingCartIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Orders" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Paid Order"}
                      onClick={() => redirectUrl("/Dashboard/PaidOrder")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <PaymentIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Paid Orders" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Cancelled Orders"}
                      onClick={() => redirectUrl("/Dashboard/CancelOrder")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <DeleteIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Cancelled Orders" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Add Products On Cart"}
                      onClick={() =>
                        redirectUrl("/Dashboard/DisplayProductsOnShoppingCart")
                      }
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <AddShoppingCartIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Add Products On Cart" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Order Items"}
                      onClick={() => redirectUrl("/Dashboard/OrderItems")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <ShoppingBasketIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Order Items" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Minimum Order"}
                      onClick={() => redirectUrl("/Dashboard/MinimumOrder")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <LocalMallIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Minimum Order" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Order Cancel DateTime"}
                      onClick={() =>
                        redirectUrl("/Dashboard/OrderCancelDateTime")
                      }
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <AccessAlarmIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Order Cancel DateTime" />
                    </ListItem>
                    {/* <ListItem
                      button
                      key={"Direct Comment"}
                      onClick={() => redirectUrl("/Dashboard/DirectComment")}
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <AddCommentIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Direct Comment" />
                    </ListItem> */}
                    <ListItem
                      button
                      key={"Disable Remove Items"}
                      onClick={() =>
                        redirectUrl("/Dashboard/DisableRemoveItems")
                      }
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <HighlightOffIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Disable Remove Items" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Deduct Qty Upon Payment"}
                      onClick={() =>
                        redirectUrl("/Dashboard/DeductQtyUponPayment")
                      }
                      className={classes.menuItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <RemoveIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Deduct Qty Upon Payment" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickKeyWords}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <VpnKeyIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Keywords Management"
                    style={{ color: "#51057C" }}
                  />
                  {openKeyWords ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openKeyWords} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Groups"}
                      onClick={() => redirectUrl("/Dashboard/Groups")}
                    >
                      <ListItemIcon>
                        {" "}
                        <KeyboardIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Campaign" />
                    </ListItem>

                    <ListItem
                      button
                      key={"ImageUpload"}
                      onClick={() => redirectUrl("/Dashboard/ImageUpload")}
                    >
                      <ListItemIcon>
                        {" "}
                        <ImageIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="ImageUpload" />
                    </ListItem>

                    <ListItem
                      button
                      key={"keyword"}
                      onClick={() => redirectUrl("/Dashboard/Keywords")}
                    >
                      <ListItemIcon>
                        {" "}
                        <KeyboardIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Keywords" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Import Keywords"}
                      onClick={() => redirectUrl("/Dashboard/ImportKeywords")}
                    >
                      <ListItemIcon>
                        {" "}
                        <ImportExportIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Import Keywords" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickLive}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <VisibilityIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Live Show Management"
                    style={{ color: "#51057C" }}
                  />
                  {openLive ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openLive} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Current Show"}
                      onClick={() => redirectUrl("/Dashboard/currentShow")}
                    >
                      <ListItemIcon>
                        {" "}
                        <MovieIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Posts" />
                    </ListItem>

                    <ListItem
                      button
                      key={"Link Campaign"}
                      onClick={() => redirectUrl("/Dashboard/linkCampaign")}
                    >
                      <ListItemIcon>
                        {" "}
                        <KeyboardIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Link Campaign" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickDelivery}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <LocalShippingIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Delivery Management"
                    style={{ color: "#51057C" }}
                  />
                  {openDelivery ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openDelivery} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Shipping"}
                      onClick={() => redirectUrl("/Dashboard/ShippingCharge")}
                    >
                      <ListItemIcon>
                        {" "}
                        <DirectionsBoatIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Shipping" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Delivery Date"}
                      onClick={() => redirectUrl("/Dashboard/DeliveryDate")}
                    >
                      <ListItemIcon>
                        {" "}
                        <CalendarTodayIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Delivery Date" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickMessage}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <MessageIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Message Management"
                    style={{ color: "#51057C" }}
                  />
                  {openMessage ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openMessage} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"message"}
                      onClick={() => redirectUrl("/Dashboard/MessageFormat")}
                    >
                      <ListItemIcon>
                        {" "}
                        <TextsmsIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Message Format" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Send Mass Message"}
                      onClick={() => redirectUrl("/Dashboard/SendMassMessages")}
                    >
                      <ListItemIcon>
                        {" "}
                        <SendIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Send Mass Message" />
                    </ListItem>
                    <ListItem
                      button
                      key={"FeedBack"}
                      onClick={() => redirectUrl("/Dashboard/FeedBack")}
                    >
                      <ListItemIcon>
                        {" "}
                        <VisibilityOffIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="FeedBack Status" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickLoyalty}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <FaceIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Loyalty Management"
                    style={{ color: "#51057C" }}
                  />
                  {openLoyalty ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openLoyalty} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"userDetail"}
                      onClick={() => redirectUrl("/Dashboard/UserDetail")}
                    >
                      <ListItemIcon>
                        {" "}
                        <PersonIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Coupons"}
                      onClick={() => redirectUrl("/Dashboard/Coupons")}
                    >
                      <ListItemIcon>
                        {" "}
                        <LocalOfferIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Coupons" />
                    </ListItem>

                    <ListItem
                      button
                      key={"ImportCoupons"}
                      onClick={() => redirectUrl("/Dashboard/ImportCoupons")}
                    >
                      <ListItemIcon>
                        {" "}
                        <ImportExportIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Import Coupons" />
                    </ListItem>
                    <ListItem
                      button
                      key={"First SignUp Customers Incentive Points"}
                      onClick={() =>
                        redirectUrl(
                          "/Dashboard/FirstSignUpCustomersIncentivePoints"
                        )
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <FiberNewIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="First SignUp Customers Incentive Points" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickLuckyDraw}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <ComputerIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Lucky Draw Management"
                    style={{ color: "#51057C" }}
                  />
                  {openLuckyDraw ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openLuckyDraw} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Lucky Draw Image Wall"}
                      onClick={() => redirectUrl("/Dashboard/LuckyDrawWall")}
                    >
                      <ListItemIcon>
                        {" "}
                        <CardGiftcardIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Lucky Draw Image Wall" />
                    </ListItem>

                    <ListItem
                      button
                      key={"Lucky Draw Setting"}
                      onClick={() => redirectUrl("/Dashboard/LuckyDraw")}
                    >
                      <ListItemIcon>
                        {" "}
                        <SettingsIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Lucky Draw Setting" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickPoints}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <FolderSpecialIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Points Management"
                    style={{ color: "#51057C" }}
                  />
                  {openPoints ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openPoints} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Earn Point System"}
                      onClick={() =>
                        redirectUrl("/Dashboard/EarnPointManagement")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <CardGiftcardIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Earn Point System" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Redeem Point System"}
                      onClick={() =>
                        redirectUrl("/Dashboard/RedeemPointManagement")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <AccountBalanceWalletIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Redeem Point System" />
                    </ListItem>
                    <ListItem
                      button
                      key={"Exchange Point Rate"}
                      onClick={() =>
                        redirectUrl("/Dashboard/ExchangePointValue")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <CachedIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Exchange Point Rate" />
                    </ListItem>
                    <ListItem
                      button
                      key={"User Point Summary Report"}
                      onClick={() =>
                        redirectUrl("/Dashboard/UserPointSummaryReport")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <EventNoteIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="User Point Summary Report" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickReferral}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <PeopleIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Referral Management"
                    style={{ color: "#51057C" }}
                  />
                  {openReferral ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openReferral} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"Referral Incentive Setting"}
                      onClick={() =>
                        redirectUrl("/Dashboard/ReferralIncentivePoints")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <AttachMoneyIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Referral Incentive Setting" />
                    </ListItem>

                    <ListItem
                      button
                      key={"Share Button Setting"}
                      onClick={() =>
                        redirectUrl("/Dashboard/ShareButtonVisible")
                      }
                    >
                      <ListItemIcon>
                        {" "}
                        <ShareIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Share Button Setting" />
                    </ListItem>

                    <ListItem
                      button
                      key={"All Transactions for Referral/Points"}
                      onClick={() => redirectUrl("/Dashboard/Transactions")}
                    >
                      <ListItemIcon>
                        {" "}
                        <HistoryIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="All Transactions for Referral Points" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "normal" || userRole === "admin") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickCallBackSetting}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <SyncAltIcon style={{ color: "#51057C" }} />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="CallBack Setting"
                    style={{ color: "#51057C" }}
                  />
                  {openCallBackSetting ? (
                    <IconExpandLess />
                  ) : (
                    <IconExpandMore />
                  )}
                </ListItem>
                <Collapse in={openCallBackSetting} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"CallBack Setting"}
                      onClick={() => redirectUrl("/Dashboard/CallBackSetting")}
                    >
                      <ListItemIcon>
                        {" "}
                        <SyncIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="CallBack Setting" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}

            {userRole && (userRole === "admin" || userRole === "crm") && (
              <span>
                <ListItem
                  button
                  onClick={handleClickSuperAdmin}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    {" "}
                    <SupervisedUserCircleIcon
                      style={{ color: "#51057C" }}
                    />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary="Registration Management"
                    style={{ color: "#51057C" }}
                  />
                  {openReferral ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={openSuperAdmin} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      key={"PreRegistration Request"}
                      onClick={() => redirectUrl("/Dashboard/PreRegistration")}
                    >
                      <ListItemIcon>
                        {" "}
                        <ListAltIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="Pre Registration Request" />
                    </ListItem>

                    <ListItem
                      button
                      key={"New Registration Request"}
                      onClick={() => redirectUrl("/Dashboard/NewUserRequest")}
                    >
                      <ListItemIcon>
                        {" "}
                        <FormatListBulletedIcon />{" "}
                      </ListItemIcon>
                      <ListItemText primary="New Registration Request" />
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
        {adminReducers.loading && <Loader />}
      </div>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DashBoard);
