import React, {useState} from 'react';
import ImageLoader from 'react-load-image';
import ENVIRONMENT_VARIABLES from "../../../environment.config";
import './style.css';

let moment = require('moment-timezone');

const Profile = (props) => {

    const [user, setUser] = useState(props.data);

    return (
        <div>
            {user && <div className="containerProfile mt-4 ml-4">
                <form method="post">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="profile-img">
                                <ImageLoader
                                    src={ENVIRONMENT_VARIABLES.IMAGE_URL + user.FBId + ".jpg"}>
                                    <img className="img-fluid"
                                         style={{height: '150px', width: '150px'}}
                                         alt={user.FBId}/>
                                    <img src="/assets/image/NoImages.png"
                                         style={{height: '150px', width: '150px'}}
                                         alt={user.FBId}/>
                                    <img src="/assets/image/imageLoader.png"
                                         style={{height: '150px', width: '150px'}}
                                         alt={user.FBId}/>
                                </ImageLoader>
                            </div>
                        </div>
                        <div className="col-md-6 profileScroll">
                            <div className="profile-head">
                                <h5>
                                    {user.FirstName + " " + user.LastName}
                                </h5>
                            </div>
                            <br/>
                            <div className="tab-content profile-tab" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                     aria-labelledby="home-tab">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Registration No</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user._id}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>FaceBook ID</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.FBId}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>FaceBook First Name</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.FirstName}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>FaceBook Last Name</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.LastName}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>FaceBook Email</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.email}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Full Name (as per IC)</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.FullName}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Phone</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.Phone}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Email</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.Email}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Company Name</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.CompanyName}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Company URL</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.CompanyURL}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>FaceBook URL</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.FacebookURL}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Subscription</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{user.Type.toString().toUpperCase()}</p>
                                        </div>
                                    </div>
                                    {user.Type === "pro" && <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Residential Address</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.Address}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Date of Birth</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{moment.tz(user.DateOfBirth, 'Asia/Singapore').format("DD-MM-YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Id Type</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.IdType.toString().toUpperCase()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Id Number</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.IdNumber}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Proof of Identity</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.ProofOfIdentity}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>ID Issuing Country</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.IDIssuingCountry}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Primary Authorized User Proof of Address</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.PrimaryAuthorizedUserProofOfAddress}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>I am a Financial institution registered outside of the US
                                                    (requires additional compliance information)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CompanyInfo1.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>I plan to collect or disburse funds in the United States and am a
                                                    registered entity in the United States</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CompanyInfo2.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Rapyd Type</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.RapydType}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Official Business Name</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.OfficialBusinessName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Entity Type</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.EntityType}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Street Address</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.StreetAddress}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>City</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.City}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Postal Code</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.PostalCode}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Years In Business</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.YearsInBusiness}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Country Of Registration</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CountryOfRegistration}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Business In corporation Number</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.BusinessIncorporationNumber}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Tax Number</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.TaxNumber}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Business Phone Number</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.BusinessPhoneNumber}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Description Of Business</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.DescriptionOfBusiness}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Industry Type</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.Industry}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Service Provided</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.ServicesProvided}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Annual Revenue</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.AnnualRevenue}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Average Transaction</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.AverageTransaction}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Is the company engaged directly or indirectly in any
                                                    cryptocurrency activities?</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CryptoCurrencyActivities.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Is the company engaged directly or indirectly in any Gambling
                                                    activities?</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.GamblingActivities.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Company Incorporation Documents (of the last 3 months
                                                    Bizfile)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.CompanyIncorporationDocuments}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Capital Table/Organizational Chart (Please ignore if you are a
                                                    sole proprietor.)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.CapitalTableOrganizationalChart}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Please upload your Bank Statement* (within last 6 months of Bank
                                                    Statement)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.PleaseUploadYourBankStatement}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Proof of Company Address* (Rental agreement, Certificate of
                                                    Incorporation, or a utility bill under the company’s name)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.ProofOfCompanyAddress}
                                                   download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>The number of individuals or companies with at least a 25% stake
                                                    in the company</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.StakeInTheCompany}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CEO FullName</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CEOFullName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CEO Date Of Birth</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{moment.tz(user.CEODateOfBirth, 'Asia/Singapore').format("DD-MM-YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CEO Residential Address</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CEOResidentialAddress}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>ID Issuing Country</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CEOIDIssuingCountry}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Add CEO details (A copy of front and back IC to prove name and
                                                    address.)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.CEODetails} download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CFO FullName</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CFOFullName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CEO Date Of Birth</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{moment.tz(user.CFODateOfBirth, 'Asia/Singapore').format("DD-MM-YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>CFO Residential Address</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CFOResidentialAddress}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>ID Issuing Country</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.CFOIDIssuingCountry}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Add CEO details (A copy of front and back IC to prove name and
                                                    address.)</label>
                                            </div>
                                            <div className="col-md-6">
                                                <a href={ENVIRONMENT_VARIABLES.FILE_URL + user.CEODetails} download>
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Are any of the shareholders, partners, directors, officers or
                                                    legal
                                                    representatives Politically Exposed Persons (PEPs)?</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.PEPs.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Has the Company or any of its principals been subject to any
                                                    criminal
                                                    investigations, law enforcement proceedings, regulatory inquiries,
                                                    convictions,
                                                    consent decrees or similar actions by any government agencies within
                                                    the past
                                                    five (5) years?</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.PastFive.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>RapydGrabPay</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.RapydGrabPay.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>RapydCreditCard</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.RapydCreditCard.toString()}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>RapydPaynow</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{user.RapydPaynow.toString()}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    )

};

export default Profile;
