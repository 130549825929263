import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminAction from "../../../actions/authAction";
import ReactExport from "react-export-excel";
import "./style.css";
import Button from "@material-ui/core/Button";
import Loader from "../../Loader";

let moment = require("moment-timezone");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" lign="right">
          {row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== null &&
          row.BoxifyOrderNumber !== undefined
            ? row.BoxifyOrderNumber
            : row._id}
        </TableCell>
        <TableCell component="th" scope="row" lign="right">
          {row.name}
        </TableCell>
        <TableCell align="left">
          {moment.tz(row.Date, "Asia/Singapore").format("DD-MM-YYYY hh:mm A")}
        </TableCell>
        <TableCell align="right">
          {row.total +
            row.ShippingCharge -
            (row.DiscountAmount + row.DiscountAmountPoint) >=
          0
            ? Number(
                row.total +
                  row.ShippingCharge -
                  (row.DiscountAmount + row.DiscountAmountPoint)
              ).toFixed(2)
            : 0}
        </TableCell>
        <TableCell align="right">{row.PaymentStatus}</TableCell>
        <TableCell align="right">
          {Number(row.OrderWisePoint).toFixed(2)}
        </TableCell>
        <TableCell align="right">{Number(row.ApplyPoint).toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ItemName</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                      <TableCell align="right">
                        {Number(item.price).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {Number(item.total).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Orders = (props) => {
  const [rows, setRows] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const adminReducers = useSelector((state) => ({
    orders: state.adminReducer.usersOrders,
  }));

  useEffect(() => {
    setIsLoader(true);
    let orders = [];
    let exceldata = [];
    let colors = ["FF0000FF", "FF00FF00", "FFF86B00", "FFFFFF00", "FFCCEEFF"];
    let colorCount = 0;
    let orderCounter = 0;
    adminReducers.orders.map((order) => {
      orderCounter++;
      if (colorCount >= colors.length) {
        colorCount = 0;
      } else {
        colorCount += 1;
      }
      orders.push({
        name: order.Name,
        total: Number(order.Total),
        ShippingCharge: Number(order.ShippingCharge),
        DiscountAmount: Number(order.DiscountAmount),
        FbPSID: order.FbSPID,
        Items: order.Items,
        Date: order.updatedAt,
        PaymentStatus: order.PaymentStatus,
        Status: order.Status,
        _id: order._id,
        order: order,
        BoxifyOrderNumber: order.BoxifyOrderNumber,
        Is_FirstOrder: order.Is_FirstOrder,
        CancelDate: order.CancelDate,
        DiscountAmountPoint: Number(order.DiscountAmountPoint),
        ApplyPoint: Number(order.ApplyPoint),
        PaidAmount: order.PaidAmount,
        Payment_id: order.Payment_id,
        Coupon: order.Coupon,
        ShippingEmail: order.ShippingEmail,
        OrderWisePoint: Number(order.OrderWisePoint),
        PaymentDate:
          order.PaymentDate !== undefined && order.PaymentDate !== null
            ? moment
                .tz(order.PaymentDate, "Asia/Singapore")
                .format("DD-MM-YYYY hh:mm A")
            : "",
      });

      if (
        order.ShippingAddress2 === undefined ||
        order.ShippingAddress2 === null
      ) {
        order.ShippingAddress2 = "";
      }

      if (
        order.ShippingAddress1 === undefined ||
        order.ShippingAddress1 === null
      ) {
        order.ShippingAddress1 = "";
      }

      if (order.ShippingMobile === undefined || order.ShippingMobile === null) {
        order.ShippingMobile = "";
      }

      if (
        order.ShippingPostalCode === undefined ||
        order.ShippingPostalCode === null
      ) {
        order.ShippingPostalCode = "";
      }

      let orderNumber = order._id;
      if (order.BoxifyOrderNumber) {
        orderNumber = order.BoxifyOrderNumber;
      }

      let deliveryDate = "";
      let deliveryTimeRange = "";
      if (
        order.DeliveryTimeSlot &&
        order.DeliveryTimeSlot !== null &&
        order.DeliveryTimeSlot !== undefined
      ) {
        deliveryDate =
          moment
            .tz(order.DeliveryTimeSlot.startDateTime, "Asia/Singapore")
            .format("DD-MM-YYYY hh:mm A")
            .toString() +
          "-" +
          moment
            .tz(order.DeliveryTimeSlot.endDateTime, "Asia/Singapore")
            .format("DD-MM-YYYY hh:mm A")
            .toString();
        deliveryTimeRange =
          moment
            .tz(order.DeliveryTimeSlot.startDateTime, "Asia/Singapore")
            .format("hh:mm A")
            .toString() +
          "-" +
          moment
            .tz(order.DeliveryTimeSlot.endDateTime, "Asia/Singapore")
            .format("hh:mm A")
            .toString();
      }

      let paymentDate = "";
      if (
        order.PaymentDate &&
        order.PaymentDate !== null &&
        order.PaymentDate !== undefined
      ) {
        paymentDate = moment
          .tz(order.PaymentDate, "Asia/Singapore")
          .format("DD-MM-YYYY hh:mm A")
          .toString();
      }

      let Total =
        order.Total +
        order.ShippingCharge -
        (Number(order.DiscountAmount) + Number(order.DiscountAmountPoint));
      if (Total < 0) Total = 0;

      order.Items.map((singleItem, index) => {
        let data = null;
        if (index === 0) {
          data = [
            {
              value: orderCounter.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.Is_FirstOrder.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "029139",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: orderNumber,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.Name,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingMobile,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingAddress1,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingPostalCode,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: deliveryTimeRange,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: deliveryDate,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.SKU,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.keyword,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.itemName,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.qty.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.price.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.total.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: Number(Total).toFixed(2).toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.PaymentStatus,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: paymentDate,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
          ];
        } else {
          data = [
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: orderNumber,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.Name,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingMobile,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingAddress1,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: order.ShippingPostalCode,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.SKU,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.keyword,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.itemName,
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.qty.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.price.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: singleItem.total.toString(),
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
            {
              value: "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: colors[colorCount] },
                },
              },
            },
          ];
        }
        exceldata.push(data);
      });
    });
    let data = [
      {
        columns: [
          "OrderNumber",
          "First Timer",
          "CustomerNo",
          "DemoRef",
          "OrderNo",
          "Name",
          "Contact",
          "Address1",
          "Postal Code",
          "Delivery Time Range",
          "Delivery Date",
          "Avoid Delivery Time",
          "SKU Code",
          "Product Keyword",
          "Product Name",
          "Quantity",
          "Unit Price",
          "Total Price",
          "Total Sum",
          "Payment Status",
          "payment Date",
        ],
        data: exceldata,
      },
    ];
    setRows(orders);
    setExcelData([...data]);
    setIsLoader(false);
  }, [adminReducers.orders]);

  return (
    <div>
      <div className="export_orders">
        <ExcelFile
          element={
            <Button
              variant="contained"
              className="exportOrderButton"
              color="primary"
            >
              Export Orders
            </Button>
          }
        >
          <ExcelSheet dataSet={excelData} name="Organization" />
        </ExcelFile>
      </div>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>OrderNo</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>OrderDate</TableCell>
              <TableCell align="right">OrderValue</TableCell>
              <TableCell align="right">Order Status</TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right">Points spent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoader && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Orders);
