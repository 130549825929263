import React, {useState, useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {getCustomerData} from "../../../index";
import Profile from "../Common/ProfilePage";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import RapydCredentials from '../Common/RapydCredential';
import {bindActionCreators} from "redux";
import * as adminAction from "../../../actions/authAction";


const MyProfile = (props) => {

    const [user, setUser] = useState(null);
    const adminReducers = useSelector((state) => ({
        token: state.adminReducer.token
    }));

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (adminReducers.token) {
            const userData = getCustomerData(adminReducers.token);
            if (userData)
                setUser({...userData});
        }
    }, [adminReducers.token]);

    const saveDetail = (data) => {
        data._id = user._id;
        props.actions.adminAction.editRegistrationUserWithToken(data);
    };

    return (
        <div>
            <Paper square>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                >
                    <Tab icon={<PersonPinIcon/>} label="PROFILE"/>
                    <Tab icon={<PaymentIcon/>} label="RAPYD CREDENTIALS"/>
                </Tabs>
            </Paper>
            {(user && value === 0) && <Profile data={{...user}}/>}
            {(user && value === 1) && <RapydCredentials data={{...user}} saveDetail={saveDetail}/>}
        </div>
    )

};

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(adminAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(MyProfile);
