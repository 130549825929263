import React from "react";

const Header = () => {
  return (
    <div>
      <div className="row col-12 align-items-center responsive_main_div">
        <div className="responsive_header_width col-4 d-flex justify-content-sm-start">
          <div className="logo">
            {/* <img className="logo" src="https://www.ordrstartr.com/wp-content/uploads/2020/12/cropped-Ordr-Startr.png"/> */}
            <img
              src={"/assets/image/ordrstartr.png"}
              className="LogoImage m-auto"
            />
          </div>
        </div>
        <div className="responsive_menu header_links col-4 justify-content-center">
          <a href="https://www.ordrstartr.com/">HOME</a>
          <a href="https://www.ordrstartr.com/features/">FEATURES</a>
          <a href="https://www.ordrstartr.com/pricing/">PRICING</a>
        </div>
        <div className="responsive_header_width col-4 d-flex justify-content-end">
          <a className="elementor-button">GET STARTED FREE</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
