import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminAction from "../../actions/authAction";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      disableBackdropClick={true}
    >
      <DialogTitle id="simple-dialog-title">List of Pages</DialogTitle>
      <List>
        {props.pages.map((singlePage) => (
          <ListItem
            button
            onClick={() => handleListItemClick(singlePage)}
            key={singlePage.FbPageId}
          >
            <ListItemText primary={singlePage.FbPageName} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const PageSelection = (props) => {
  const [open, setOpen] = React.useState(props.open);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const adminReducers = useSelector((state) => ({
    allPages: state.adminReducer.allPages,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    props.actions.adminAction.pageSelected(value);
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div className="page-selection-btn">
      <Button className="w-100" variant="contained" onClick={handleClickOpen}>
        {selectedValue === null
          ? "Choose your Page"
          : `Selected: ${selectedValue.FbPageName}`}
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        pages={adminReducers.allPages}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(PageSelection);
