import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import ImageLoader from "react-load-image";

import ENVIRONMENT_VARIABLES from "../../../environment.config";

export const AddDialog = (props) => {
  var reader = new FileReader();
  const [group, setGroup] = useState([]);
  const [keyword, setKeyword] = useState(props.editKeyword);

  const [imageDisplay, setImageDisplay] = useState(
    `${ENVIRONMENT_VARIABLES.UPLOAD_IMAGE_URL}${props.editKeyword.image}`
  );

  const handleSubmitClose = (keyword) => {
    props.handleEditSubmitClose(keyword);
  };

  const adminReducers = useSelector((state) => ({
    allGroups: state.adminReducer.allGroups,
  }));

  useEffect(() => {
    if (adminReducers.allGroups.length > 0) {
      setGroup(adminReducers.allGroups);
    }
  }, [adminReducers.allGroups]);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newKeywordDetail = { ...keyword };
    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    let newKeywordDetail = { ...keyword };
    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleImageUpload = (event) => {
    setKeyword({
      ...keyword,
      image: event.target.files[0],
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  reader.onload = function (e) {
    setImageDisplay(e.target.result);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle
            className="position-sticky"
            style={{ top: "0", zIndex: "100", backgroundColor: "#fff" }}
            id="form-dialog-title"
          >
            Edit Keyword
          </DialogTitle>

          <DialogContent>
            <div className="row" style={{ gridRowGap: "20px" }}>
              <div className="popup-input-box col-4 ">
                <span className="d-block text-center w-100">Upload Image</span>
                <ImageLoader
                  src={imageDisplay}
                  className="d-flex justify-content-center"
                >
                  <img
                    className="img-fluid"
                    style={{ height: "60px", width: "60px" }}
                  />
                  <img
                    src="/assets/image/NoImages.png"
                    style={{ height: "60px", width: "60px" }}
                  />
                  <img
                    src="/assets/image/imageLoader.png"
                    style={{ height: "60px", width: "60px" }}
                  />
                </ImageLoader>
                <div className="primary-btn popup-btn d-flex justify-content-center mt-1">
                  <Button variant="contained" component="label">
                    Upload
                    <input
                      name="image"
                      accept="image/*"
                      hidden
                      type="file"
                      onChange={(event) => handleImageUpload(event)}
                    />
                  </Button>
                </div>
              </div>
              <div className="col-8">
                <div className="row" style={{ gridRowGap: "20px" }}>
                  <div className="col-6">
                    <TextValidator
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Description"
                      name="description"
                      value={keyword.description}
                      validators={["required"]}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div className="col-6">
                    <TextValidator
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Keyword"
                      name="keyword"
                      value={keyword.keyword}
                      validators={["required"]}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div className="col-6">
                    <TextValidator
                      variant="outlined"
                      size="small"
                      fullWidth
                      className="flex-grow-1"
                      label="Price"
                      name="price"
                      type="float"
                      value={keyword.price}
                      validators={["required"]}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div className="col-6">
                    <TextValidator
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Stock"
                      name="stock"
                      value={keyword.stock}
                      type="number"
                      validators={["required"]}
                      onChange={handleChange}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="MaxQty"
                  name="maxQty"
                  value={keyword.maxQty}
                  type="number"
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Reply Message"
                  name="reply_message"
                  value={keyword.reply_message}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="SKU"
                  name="SKU"
                  value={keyword.SKU}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-6">
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Supplier"
                  name="supplierName"
                  value={keyword.supplierName}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>

              <div className="col-12">
                <FormControl variant="outlined" size="small" className="w-100">
                  <InputLabel id="demo-simple-select-label">
                    Campaign
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Campaign"
                    id="demo-simple-select"
                    name="groupID"
                    value={keyword.groupID}
                    onChange={handleChange1}
                    fullWidth
                  >
                    {group.map((singleSupplier) => (
                      <MenuItem value={singleSupplier._id}>
                        {singleSupplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default AddDialog;
