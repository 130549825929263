import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import "./style.css";
import { Card, TextField } from "@material-ui/core";

const LuckyDraw = (props) => {
  const [luckyDraw, setLuckyDraw] = useState(0);
  const [luckyDrawStatus, setLuckyDrawStatus] = useState(false);
  const [LuckyDrawKeyWord, setLuckyDrawKeyWord] = useState("");

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (adminReducers.selectedPage) {
      setLuckyDraw(adminReducers.selectedPage.LuckyDraw);
      setLuckyDrawStatus(adminReducers.selectedPage.LuckyDrawStatus);
      const luckyDrawKeyword = adminReducers.selectedPage.LuckyDrawKeyWord
        ? adminReducers.selectedPage.LuckyDrawKeyWord
        : "";
      setLuckyDrawKeyWord(luckyDrawKeyword);
    }
  }, [adminReducers.selectedPage]);

  const updateDate = () => {
    props.actions.adminAction.editPageDetail(
      {
        LuckyDrawStatus: luckyDrawStatus,
        LuckyDraw: luckyDraw,
        LuckyDrawKeyWord: LuckyDrawKeyWord,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = () => {
    setLuckyDrawStatus(!luckyDrawStatus);
  };

  const handleChangeValue = (event) => {
    return setLuckyDraw(event.target.value);
  };

  const handleLuckyDrawKeyWordChange = (event) => {
    return setLuckyDrawKeyWord(event.target.value);
  };

  return (
    <Card className="p-3 gap-20 d-flex flex-column">
      {/* <div className="d-flex flex-column w-25">
                <label>Lucky Draw KeyWord</label>
                <input name="LuckyDrawKeyWord"
                       className="form-control"
                       value={LuckyDrawKeyWord} onChange={handleLuckyDrawKeyWordChange}
                       placeholder={"LuckyDraw Key Word"}/>
            </div> */}
      <TextField
        name="LuckyDrawKeyWord"
        value={LuckyDrawKeyWord}
        onChange={handleLuckyDrawKeyWordChange}
        label="LuckyDraw Key Word"
        variant="outlined"
        size="small"
        className="col-sm-3"
      />

      {/* <div className="d-flex flex-column mt-4 w-25">
        <label>Number of Lucky Draw</label>
        <input
          name="MinimumOrderAmount"
          type="number"
          className="form-control"
          value={luckyDraw}
          onChange={handleChangeValue}
          placeholder={"Number of Lucky Draw"}
        />
      </div> */}
      <TextField
        name="MinimumOrderAmount"
        type="number"
        value={luckyDraw}
        onChange={handleChangeValue}
        label="Number of Lucky Draw"
        variant="outlined"
        size="small"
        className="col-sm-3"
      />

      {/* <div className="d-flex mt-4">
        <div>
          <label> Status </label> &nbsp; &nbsp;
          <Switch
            checked={luckyDrawStatus}
            onChange={handleChange}
            name="PageStatus"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      </div>

      <div className="d-flex my-3 mx-5">
        <Button
          variant="contained"
          color="primary"
          className="ml-3"
          onClick={updateDate}
        >
          Save
        </Button>
      </div> */}

      <div className="d-flex align-items-center justify-content-between col-sm-3 p-0">
        <div className="d-flex align-items-center">
          <label> Status </label> &nbsp; &nbsp;
          <Switch
            checked={luckyDrawStatus}
            onChange={handleChange}
            name="PageStatus"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          className="ml-3"
          onClick={updateDate}
        >
          Save
        </Button>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(LuckyDraw);
