import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import DateTimePicker from "react-datetime-picker";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { Card } from "@material-ui/core";

import * as adminAction from "../../../actions/authAction";
import "./style.css";

let moment = require("moment-timezone");

const DeliveryDate = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [lastDateTime, setLastDateTime] = useState(null);
  const [isCancel, setIsCancel] = useState(false);

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  const onStartChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (
      adminReducers.selectedPage &&
      adminReducers.selectedPage.CancelDate &&
      adminReducers.selectedPage.CancelDate !== null
    ) {
      setLastDateTime(adminReducers.selectedPage.CancelDate);
      setIsCancel(adminReducers.selectedPage.Is_Cancel);
    }
  }, [adminReducers.selectedPage]);

  const updateDate = () => {
    props.actions.adminAction.editPageDetail(
      {
        Is_Cancel: isCancel,
        CancelDate: startDate,
      },
      adminReducers.selectedPage.FbPageId
    );
  };

  const handleChange = () => {
    setIsCancel(!isCancel);
  };

  return (
    <Card
      className="p-3 gap-20 d-flex flex-column"
      style={{ overflow: "unset" }}
    >
      <div className="d-flex flex-column">
        <label className="m-0"> DateTime </label> &nbsp;
        <DateTimePicker
          onChange={onStartChange}
          value={startDate}
          className="col-sm-3 p-0"
        />
      </div>

      <div className="d-flex align-items-center justify-content-between col-sm-3 p-0">
        <div className="d-flex align-items-center">
          <label> Status </label> &nbsp; &nbsp;
          <Switch
            checked={isCancel}
            onChange={handleChange}
            name="PageStatus"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          className="ml-3"
          onClick={updateDate}
        >
          Save
        </Button>
      </div>
      {lastDateTime !== null && (
        <div className="d-flex">
          <label>
            <h5> DateTime </h5>
          </label>{" "}
          &nbsp; &nbsp;
          <div>
            <h5>
              {moment
                .tz(lastDateTime, "Asia/Singapore")
                .format("DD-MM-YYYY hh:mm A")}
            </h5>
          </div>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DeliveryDate);
