import React, { useEffect, useState } from "react";
import SearchBar from "./searchBar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");
const columns = [
  { id: "FullName", label: "Full Name", minWidth: 120 },
  { id: "Email", label: "Email", minWidth: 120 },
  { id: "Phone", label: "Phone", minWidth: 120 },
  { id: "Date", label: "Date", minWidth: 120 },
];

function createData(FullName, Email, Phone, Date) {
  return { FullName, Email, Phone, Date };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 258px)",
  },
});

const PreRegistration = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    preRegistration: state.adminReducer.preRegistration,
  }));

  useEffect(() => {
    if (
      adminReducers.preRegistration !== null &&
      adminReducers.preRegistration !== undefined
    ) {
      let records = [];
      adminReducers.preRegistration.map((singleItem) => {
        records.push(
          createData(
            singleItem.FullName,
            singleItem.Email,
            singleItem.Phone,
            moment
              .tz(singleItem.Date, "Asia/Singapore")
              .format("DD-MM-YYYY hh:mm A")
          )
        );
      });
      setRows(records);
      setPage(0);
    }
  }, [adminReducers.preRegistration]);

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchBar />
      </Card>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default PreRegistration;
