import React, {useEffect} from 'react';
import './login.css';
import ENVIRONMENT_VARIABLES from '../../../environment.config';

const CustomerLogin = (props) => {

    useEffect(() => {
        if (props.location.query.token) {
            localStorage.setItem('token', props.location.query.token);
        } else {
            localStorage.setItem('token', null);
        }
    }, []);

    const FbLogin = () => {
        window.location.replace(ENVIRONMENT_VARIABLES.Base_API_URL + "/Oauths/SHC/facebook");
    };

    return (
        <div>

            <div className="d-flex facebook-login flex-column">

                <img src={"/assets/image/SHC.png"} width={"40%"}/>
                &nbsp;

                <button className="loginBtn loginBtn--facebook" onClick={FbLogin}>
                    Login with Facebook
                </button>

                <br/>
                <div className="alert alert-warning">
                    <strong>Note:</strong> By logging in, you are agreeing to be a Singapore Home Cooks Customer.
                </div>

            </div>

        </div>
    );

};

export default CustomerLogin;


