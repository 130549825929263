import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Loader from "../Loader/index";
import { browserHistory, Link } from "react-router";
import { bindActionCreators } from "redux";
import * as adminAction from "../../actions/authAction";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TimeSlotsSelection from "./TimeSlotsSelection";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import { connection, newSocketMessage } from "../../socket";
import ImageLoader from "react-load-image";
import { CONTRIES } from "../../constants/actionTypes";
import ENVIRONMENT_VARIABLES from "../../environment.config";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./react-confirm-alert.css";
import "./style.css";
import { TextField, FormControl } from "@material-ui/core";

let moment = require("moment-timezone");
let _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
  },
  pointApply: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Checkout = (props) => {
  const adminReducers = useSelector((state) => ({
    loading: state.adminReducer.loading,
    error_msg: state.adminReducer.error_msg,
    success_msg: state.adminReducer.success_msg,
    checkOutOrder: state.adminReducer.checkOutOrder,
    couponDetail: state.adminReducer.couponDetail,
    selectTimeSlot: state.adminReducer.selectTimeSlot,
    ShippingCharge: state.adminReducer.ShippingCharge,
    confirmDeliverySlot: state.adminReducer.confirmDeliverySlot,
    allGroups: state.adminReducer.allGroups,
  }));
  const [order, setOrder] = useState(null);
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectDeliveryTimeSlot, setSelectDeliveryTimeSlot] = useState([]);
  const [
    oldVersionSelectDeliveryTimeSlot,
    setOldVersionSelectDeliveryTimeSlot,
  ] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [visibleMoreItems, setVisibleMoreItems] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [discountTotal, setDiscountTotal] = useState(0);
  const [pointDiscount, setPointDiscount] = useState(0);
  const [chipData, setChipData] = useState([]);
  const [isPay, setIsPay] = useState(true);
  const [checkedTamsCondition, setCheckedTamsCondition] = useState(false);
  const [isApplyPoint, setIsApplyPoint] = useState(false);
  const [checkoutUserDetails, setCheckoutUserDetails] = useState(null);
  const [pageDetail, setPageDetail] = useState(null);
  const [listOfPoints, setListOfPoints] = useState([]);
  const [selectPoint, setSelectPoint] = useState(0);
  const [deliverySlotVisible, setDeliverySlotVisible] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(null);
  const [timerSeconds, setTimerSeconds] = useState(null);
  const [residentialType, setResidentialType] = useState([]);
  const [selectResidential, setSelectResidential] = useState(null);
  const [is_VisibleDeliveryTime, setIs_VisibleDeliveryTime] = useState(true);
  const [paymentValue, setPaymentValue] = useState(null);
  const [paymentMethodVisible, setPaymentMethodVisible] = useState(null);
  const [paymentMethods, setPaymentMehods] = useState([
    {
      label: "Paynow & Credit/Debit card",
      value: 0,
    },
    {
      label: "UnionPay QR Code Payment",
      value: 1,
    },
  ]);
  const [country, setCountry] = useState("");
  const [group, setGroup] = useState([{ name: "All", _id: 0 }]);
  const [groupName, setGroupName] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    props.actions.adminAction.loading();
    if (props.params.orderId !== null && props.params.orderId !== undefined) {
      connection();
      newSocketMessage(props.params.orderId, (err, data) => {
        switch (data.type) {
          case "orderUpdate":
            ReloadOrder();
            break;
        }
      });
      if (props.params.time !== null && props.params.time !== undefined) {
        setTimeout(() => {
          props.actions.adminAction.getOrderDetail(props.params.orderId);
        }, props.params.time);
      } else props.actions.adminAction.getOrderDetail(props.params.orderId);
    } else {
      browserHistory.push("/not_found");
    }
  }, []);

  const ReloadOrder = () => {
    props.actions.adminAction.getOrderDetail(props.params.orderId);
    swal("", "Your order has been updated.", "success");
  };

  useEffect(() => {
    if (adminReducers.error_msg !== null) {
      swal("Oops...", adminReducers.error_msg.toString(), "error");
    }
  }, [adminReducers.error_msg]);

  const timeoutInterval = (confirmSlotDate) => {
    if (id !== null) {
      clearInterval(id);
    }
    if (confirmSlotDate !== null) {
      const after10Minutes = moment.tz(confirmSlotDate.date, "Asia/Singapore");
      setId(
        setInterval(() => {
          const currentDateTime = moment().tz("Asia/Singapore");
          const diff = after10Minutes.diff(currentDateTime, "seconds");
          const minutes = diff / 60;
          const seconds = diff % 60;
          if (minutes >= 0 || seconds >= 0) {
            let secondTmp = parseInt(seconds);
            if (secondTmp < 10) {
              secondTmp = "0" + secondTmp;
            }
            setTimerMinutes(parseInt(minutes));
            setTimerSeconds(secondTmp);
          } else {
            clearInterval(id);
            setId(null);
            setTimerMinutes(0);
            setTimerSeconds(0);
            location.reload();
          }
        }, 1000)
      );
    }
  };

  useEffect(() => {
    if (adminReducers.checkOutOrder) {
      props.actions.adminAction.getHomeCallGroup(
        adminReducers.checkOutOrder?.Order.FbPageId
      );
      try {
        const {
          Order,
          PageDetail,
          Products,
          checkoutUserDetail,
          availableTimeSlots,
          selectedTimeSlot,
        } = adminReducers.checkOutOrder;
        setOrder({ ...Order });
        setPageDetail(PageDetail);
        setAvailableTimeSlots(availableTimeSlots);

        if (groupName === 0) {
          setProducts([...Products]);
        } else {
          const filterProductCompaignWise = Products.filter(
            (product) => product.groupID === groupName
          );
          if (filterProductCompaignWise.length > 0) {
            setProducts(filterProductCompaignWise);
          }
        }
        let selectedTimeSlots = [];
        if (selectedTimeSlot?.timeSlotId) {
          setSelectDeliveryTimeSlot(selectedTimeSlot.timeSlotId);
          selectedTimeSlots = selectedTimeSlot.timeSlotId;
        }

        let totalPaymentMethods = [];
        PageDetail.PaymentActiveMethods.map((singlePayment) => {
          const FindSinglePayment = paymentMethods.find(
            (findPaymentData) => findPaymentData.value === singlePayment
          );
          if (FindSinglePayment) {
            totalPaymentMethods.push({
              label: FindSinglePayment.label,
              value: FindSinglePayment.value,
            });
          }
        });
        setPaymentMethodVisible(totalPaymentMethods);
        let IsApplyPointVisible = false;
        let checkOutUserProfile = checkoutUserDetails;
        let options = [0];

        setSelectPoint(Order.ApplyPoint);
        setPointDiscount(Order.DiscountAmountPoint);

        //Todo residential type
        let residentialData = [];
        ["Landed", "HDB", "Condominium/Apartment", "OFFICE"].map(
          (singleResidentialType) => {
            residentialData.push({
              value: singleResidentialType,
            });
          }
        );
        setResidentialType(residentialData);

        //Todo page redeem value sort.
        let redeemPointValueSort = PageDetail.RedeemManagement;
        redeemPointValueSort.sort((a, b) => (a.Point > b.Point ? 1 : -1));

        if (checkoutUserDetail !== undefined && checkoutUserDetail !== null) {
          setCheckoutUserDetails(checkoutUserDetail);
          setCheckedTamsCondition(checkoutUserDetail.Opt_In);
          checkOutUserProfile = checkoutUserDetail;
        }

        //Todo add into the combobox
        if (
          PageDetail.Is_RedeemPointMinimumOrder === true &&
          Order.Total >= PageDetail.RedeemPointMinimumOrder &&
          checkOutUserProfile !== null
        ) {
          redeemPointValueSort.map((singlePoint) => {
            if (checkOutUserProfile.PointsEarned >= singlePoint.Point) {
              options.push(singlePoint.Point);
              IsApplyPointVisible = true;
            }
          });
        }

        if (IsApplyPointVisible) {
          setIsApplyPoint(true);
          setListOfPoints(options);
        } else {
          setIsApplyPoint(false);
        }

        if (PageDetail.Is_MinimumOrder && Order.PaymentStatus === "unpaid") {
          if (Order.Total >= PageDetail.MinimumOrder) {
            setIsPay(true);
          } else {
            setTimeout(() => {
              swal(
                "",
                `Please add-on to hit minimum order of $${PageDetail.MinimumOrder}, else we will not be able to process your order.`,
                "warning"
              );
            }, 1000);
            setIsPay(false);
          }
        } else {
          setIsPay(true);
        }

        let CouponsData = [];
        if (Order.Coupon && Order.Coupon !== undefined && Order.Coupon !== "") {
          CouponsData.push({ key: 0, label: Order.Coupon });
        }

        if (
          Order.ReferralCode &&
          Order.ReferralCode !== undefined &&
          Order.ReferralCode !== ""
        ) {
          CouponsData.push({ key: 1, label: Order.ReferralCode });
        }

        setChipData(CouponsData);
        setDiscountTotal(Order.DiscountAmount);
        setPointDiscount(Order.DiscountAmountPoint);

        if (Order.Status === "confirmed") {
          let checkDeliverySlot = [];
          let findAllDeliverySlots = 0;
          let identifyCountryName = "";

          availableTimeSlots.map((singleDeliveryDate) => {
            singleDeliveryDate.checked = false;
            if (
              moment
                .tz(singleDeliveryDate.endDateTime, "Asia/Singapore")
                .format() >= moment().tz("Asia/Singapore").format()
            ) {
              //Todo load all the slots;
              if (
                selectedTimeSlot &&
                selectedTimeSlot !== null &&
                selectedTimeSlots.find(
                  (data) => data.timeSlotId === singleDeliveryDate._id
                )
              ) {
                singleDeliveryDate.checked = true;
                identifyCountryName = singleDeliveryDate.country;
                findAllDeliverySlots += 1;
              }
              checkDeliverySlot.push(singleDeliveryDate);
            }
          });
          //Todo group by
          setCountry(identifyCountryName);
          const groupByCountry = checkDeliverySlot.filter(
            (data) => data.country === identifyCountryName
          );
          const uniqueDeliveryDate = _.groupBy(groupByCountry, "supplierName");
          const convertObjectToArray = _.values(uniqueDeliveryDate);
          timeoutInterval(selectedTimeSlot);
          setDeliveryTimeSlot([...convertObjectToArray]);
          if (findAllDeliverySlots === selectedTimeSlots.length) {
            setConfirm(selectedTimeSlot);
          }
        }

        if (Order.PaymentStatus === "paid") {
          if (Order.DeliveryTimeSlot && Order.DeliveryTimeSlot.timeSlotId) {
            if (
              Order.DeliveryTimeSlot === undefined ||
              Order.DeliveryTimeSlot === null
            ) {
              let checkDeliverySlot = [];
              let identifyCountryName = "";
              availableTimeSlots.map((singleDeliveryDate) => {
                singleDeliveryDate.checked = false;
                if (
                  moment
                    .tz(singleDeliveryDate.endDateTime, "Asia/Singapore")
                    .format() >= moment().tz("Asia/Singapore").format()
                ) {
                  //Todo load all the slots;
                  if (
                    selectedTimeSlot &&
                    selectedTimeSlot !== null &&
                    selectedTimeSlots.find(
                      (data) => data.timeSlotId === singleDeliveryDate._id
                    )
                  ) {
                    singleDeliveryDate.checked = true;
                    identifyCountryName = singleDeliveryDate.country;
                  }
                  checkDeliverySlot.push(singleDeliveryDate);
                }
              });
              const uniqueDeliveryDate = _.groupBy(
                checkDeliverySlot,
                "supplierName"
              );
              const convertObjectToArray = _.values(uniqueDeliveryDate);
              setDeliveryTimeSlot([...convertObjectToArray]);
              setCountry(identifyCountryName);
              setOpen(true);
            } else {
              setSelectDeliveryTimeSlot(Order.DeliveryTimeSlot.timeSlotId);
              Order.DeliveryTimeSlot.timeSlotId.length > 0 &&
                setCountry(Order.DeliveryTimeSlot.timeSlotId[0].country);
            }
          } else {
            setOldVersionSelectDeliveryTimeSlot(Order.DeliveryTimeSlot);
          }
        }
      } catch (error) {
        swal(
          "Oops...",
          "Please contact to admin - (Delivery time slots have not been added)",
          "error"
        );
      }
    }
  }, [adminReducers.checkOutOrder]);

  useEffect(() => {
    if (adminReducers.couponDetail !== null) {
      setOrder({ ...adminReducers.couponDetail });
      setDiscountTotal(adminReducers.couponDetail.DiscountAmount);
      setPointDiscount(adminReducers.couponDetail.DiscountAmountPoint);

      let CouponsData = [];
      if (
        adminReducers.couponDetail.Coupon &&
        adminReducers.couponDetail.Coupon !== undefined &&
        adminReducers.couponDetail.Coupon !== ""
      ) {
        CouponsData.push({ key: 0, label: adminReducers.couponDetail.Coupon });
      }

      if (
        adminReducers.couponDetail.ReferralCode &&
        adminReducers.couponDetail.ReferralCode !== undefined &&
        adminReducers.couponDetail.ReferralCode !== ""
      ) {
        CouponsData.push({
          key: 1,
          label: adminReducers.couponDetail.ReferralCode,
        });
      }

      setChipData(CouponsData);

      //Todo set If condition for Point
      if (
        adminReducers.couponDetail.ApplyPoint &&
        adminReducers.couponDetail.ApplyPoint !== undefined &&
        adminReducers.couponDetail.ApplyPoint !== ""
      ) {
        setSelectPoint(adminReducers.couponDetail.ApplyPoint);
      }
    }
  }, [adminReducers.couponDetail]);

  useEffect(() => {
    if (adminReducers.success_msg !== null) {
      swal("", adminReducers.success_msg, "success");
    }
  }, [adminReducers.success_msg]);

  useEffect(() => {
    if (adminReducers.selectTimeSlot !== null) {
      const availableTimeSlots =
        adminReducers.selectTimeSlot.availableTimeSlots;
      let checkDeliverySlot = [];
      let selectedTimeSlot = [];
      if (adminReducers.selectTimeSlot?.selectedTimeSlot.timeSlotId) {
        selectedTimeSlot =
          adminReducers.selectTimeSlot.selectedTimeSlot.timeSlotId;
        timeoutInterval(adminReducers.selectTimeSlot.selectedTimeSlot);
        setSelectDeliveryTimeSlot([...selectedTimeSlot]);
        setConfirm([...selectedTimeSlot]);
        setDeliverySlotVisible(true);
      } else {
        availableTimeSlots.map((singleDeliveryDate) => {
          singleDeliveryDate.checked = false;
          if (
            moment
              .tz(singleDeliveryDate.endDateTime, "Asia/Singapore")
              .format() >= moment().tz("Asia/Singapore").format()
          ) {
            //Todo load all the slots;
            if (
              selectedTimeSlot &&
              selectedTimeSlot !== null &&
              selectedTimeSlot.find(
                (data) => data.timeSlotId === singleDeliveryDate._id
              )
            ) {
              singleDeliveryDate.checked = true;
            }
            checkDeliverySlot.push(singleDeliveryDate);
          }
        });
        const uniqueDeliveryDate = _.groupBy(checkDeliverySlot, "supplierName");
        const convertObjectToArray = _.values(uniqueDeliveryDate);
        setDeliveryTimeSlot([...convertObjectToArray]);
      }
    }
  }, [adminReducers.selectTimeSlot]);

  useEffect(() => {
    if (adminReducers.ShippingCharge !== null) {
      let commonData = { ...order };
      commonData.ShippingCharge = adminReducers.ShippingCharge.ShippingCharge;
      commonData.ShippingSupplier =
        adminReducers.ShippingCharge.ShippingSupplier;
      setOrder({ ...commonData });
    }
  }, [adminReducers.ShippingCharge]);

  useEffect(() => {
    if (adminReducers.confirmDeliverySlot !== null) {
      const { availableTimeSlots, order } = adminReducers.confirmDeliverySlot;
      setDeliveryTimeSlot(availableTimeSlots);
      setOrder(order);
      if (order.DeliveryTimeSlot === null) {
        setSelectDeliveryTimeSlot([]);
        setOpen(true);
      }
    }
  }, [adminReducers.confirmDeliverySlot]);

  useEffect(() => {
    if (adminReducers.allGroups.length > 0) {
      setGroup([...adminReducers.allGroups, { name: "All", _id: 0 }]);
    }
  }, [adminReducers.allGroups]);

  const handleChange = (event) => {
    const field = event.target.name;
    let commonData = { ...order };
    commonData[field] = event.target.value;
    return setOrder(commonData);
  };

  const handleChangeProductMinus = (ProductId) => {
    if (order.Status === "active" || order.Status === "confirmed") {
      const commonData = { ...order };
      let findItem = commonData.Items.find((data) => data.id === ProductId);
      findItem.qty = Number(findItem.qty) - 1;
      if (findItem.qty >= 0) {
        setOrder(commonData);
        props.actions.adminAction.updateOrderDetail({
          Order: order,
          OrderStatus: "active",
        });
      }
      return order;
    } else {
      swal("Oops...", "cannot cancel", "error");
    }
  };

  const handleChangeProductAdd = (ProductId) => {
    if (order.Status === "active" || order.Status === "confirmed") {
      const commonData = { ...order };
      let findItem = commonData.Items.find((data) => data.id === ProductId);
      findItem.qty = Number(findItem.qty) + 1;
      if (findItem.qty >= 0) {
        setOrder(commonData);
        props.actions.adminAction.updateOrderDetail({
          Order: order,
          OrderStatus: "active",
        });
      }
      return order;
    }
  };

  const handleChangeNewProduct = (ProductId) => {
    if (order.Status === "active" || order.Status === "confirmed") {
      const Products = [...products];
      let findItem = Products.find((data) => data.id === ProductId);
      findItem.qty = 1;
      props.actions.adminAction.updateOrderDetail({
        Order: order,
        OrderStatus: "active",
        addNewItem: findItem,
      });
    }
  };

  const OrderConfirm = () => {
    if (
      order.ShippingName !== "" &&
      order.ShippingName !== null &&
      order.ShippingName !== undefined &&
      order.ShippingMobile !== "" &&
      order.ShippingMobile !== null &&
      order.ShippingMobile !== undefined &&
      order.ShippingAddress1 !== "" &&
      order.ShippingAddress1 !== null &&
      order.ShippingAddress1 !== undefined &&
      order.ShippingPostalCode !== "" &&
      order.ShippingPostalCode !== null &&
      order.ShippingPostalCode !== undefined &&
      order.ShippingEmail !== "" &&
      order.ShippingEmail !== null &&
      order.ShippingEmail !== undefined &&
      order.Status === "active" &&
      order.Residential_Type !== undefined &&
      order.Residential_Type !== null &&
      order.Residential_Type !== "" &&
      order.Items.length >= 1
    ) {
      props.actions.adminAction.updateOrderDetail({
        Order: order,
        OrderStatus: "confirmed",
      });
    } else {
      swal(
        "Oops...",
        "All fields are required or basket should not be empty",
        "error"
      );
    }
  };

  const OrderBackToEdit = () => {
    props.actions.adminAction.updateOrderDetail({
      Order: order,
      OrderStatus: "active",
    });
  };

  const PayConfirm = async () => {
    if (
      order.ShippingName !== "" &&
      order.ShippingName !== null &&
      order.ShippingName !== undefined &&
      order.ShippingMobile !== "" &&
      order.ShippingMobile !== null &&
      order.ShippingMobile !== undefined &&
      order.ShippingAddress1 !== "" &&
      order.ShippingAddress1 !== null &&
      order.ShippingAddress1 !== undefined &&
      order.ShippingPostalCode !== "" &&
      order.ShippingPostalCode !== null &&
      order.ShippingPostalCode !== undefined &&
      order.ShippingEmail !== "" &&
      order.ShippingEmail !== null &&
      order.ShippingEmail !== undefined &&
      order.Status === "confirmed" &&
      order.Residential_Type !== undefined &&
      order.Residential_Type !== null &&
      order.Residential_Type !== "" &&
      paymentValue !== null &&
      (confirm !== null || availableTimeSlots.length === 0)
    ) {
      pageDetail.UponPayment === true &&
        (await swal(
          "Your products are blocked for 10 minutes.",
          "Please complete your payment within 10 minutes",
          "warning"
        ));
      switch (paymentValue) {
        case 0:
          props.actions.adminAction.getCheckout(props.params.orderId);
          break;
        case 1:
          props.actions.adminAction.getUnionCheckout(props.params.orderId);
          break;
      }
    } else {
      if (paymentValue === null)
        swal("Oops...", "Please Choose Your Payment Type", "error");
      else swal("Oops...", "Please Choose Your Delivery Time", "error");
    }
  };

  const OnChange = (selectSlot) => {
    const filterDeliveryTimeSlotsBasedOnCountry = availableTimeSlots.filter(
      (data) => data.country === country
    );
    const checkDeliverySlot = [...filterDeliveryTimeSlotsBasedOnCountry];
    let selectedTimeSlot = [...selectDeliveryTimeSlot];

    //Todo check selectedTimeSlot
    let find = selectedTimeSlot.find(
      (data) => data.supplierName === selectSlot.supplierName
    );
    selectSlot.timeSlotId = selectSlot._id;
    if (!find) {
      selectedTimeSlot.push(selectSlot);
    } else {
      //replace
      const findIndex = selectedTimeSlot.indexOf(find);
      selectedTimeSlot[findIndex] = selectSlot;
    }

    checkDeliverySlot.map((singleSlots) => {
      singleSlots.checked = false;
      if (
        selectedTimeSlot.find((data) => data.timeSlotId === singleSlots._id)
      ) {
        singleSlots.checked = true;
      }
    });

    //Todo group by
    const uniqueDeliveryDate = _.groupBy(checkDeliverySlot, "supplierName");
    const convertObjectToArray = _.values(uniqueDeliveryDate);
    setDeliveryTimeSlot([...convertObjectToArray]);
    setSelectDeliveryTimeSlot([...selectedTimeSlot]);
    setDeliverySlotVisible(false);
  };

  const onChange = (event) => {
    setCouponName(event.target.value);
  };

  const OnChangeResidential = (selectResidential) => {
    let commonData = { ...order };
    commonData.Residential_Type = selectResidential.target.value;
    setOrder(commonData);
  };

  const toggleAddMoreItems = () => {
    setVisibleMoreItems(!visibleMoreItems);
  };

  const applyCoupon = () => {
    if (couponName !== "" && couponName !== null) {
      props.actions.adminAction.getCouponDetail(
        order.FbPageId,
        couponName,
        order._id,
        order
      );
    }
  };

  const applyPoint = () => {
    props.actions.adminAction.applyPoints(order._id, selectPoint, order);
  };

  const removeCoupon = (chipToDelete) => () => {
    //setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    const couponName = chipData.find((chip) => chip.key === chipToDelete.key);
    setCouponName("");
    props.actions.adminAction.removeCoupon(
      order.FbPageId,
      couponName.label,
      order._id,
      order
    );
  };

  const OrderCancel = () => {
    confirmAlert({
      key: "123",
      message: "Proceed to remove my order and release bidded items to public",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.actions.adminAction.updateOrderDetail({
              Order: order,
              OrderStatus: "cancel",
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleTamsCondition = (event) => {
    setCheckedTamsCondition(event.target.checked);
  };

  const handleChangePoint = (event) => {
    setSelectPoint(event.target.value);
  };

  const confirmDeliverySlot = () => {
    const uniqueDeliveryDate = _.groupBy(selectDeliveryTimeSlot, "country");
    const convertObjectToArray = _.values(uniqueDeliveryDate);
    if (
      selectDeliveryTimeSlot.length === deliveryTimeSlot.length &&
      convertObjectToArray.length === 1
    ) {
      props.actions.adminAction.confirmDeliverySlot(
        {
          orderId: order._id,
          timeSlotId: selectDeliveryTimeSlot,
        },
        order.FbPageId
      );
    } else {
      swal("Oops...", "please select deliverySlot for all suppliers", "error");
    }
  };

  const handleClose = (value) => {
    //Todo api call confirm delivery time slots
    if (value !== null) {
      if (value.length === deliveryTimeSlot.length) {
        props.actions.adminAction.confirmLastDeliverySlot(
          {
            orderId: order._id,
            timeSlotId: value,
          },
          order.FbPageId
        );
        setSelectDeliveryTimeSlot(value);
        setOpen(false);
      } else {
        swal(
          "Oops...",
          "please select all the supplier deliverySlots",
          "error"
        );
      }
    } else {
      setOpen(false);
    }
  };

  const handleOpenTimeSlots = () => {
    setOpen(true);
  };

  const handleChangePayment = (event) => {
    setPaymentValue(Number(event.target.value));
  };

  const handleChangeSelectCountry = (event) => {
    const { value } = event.target;

    const filterDeliveryTimeSlotsBasedOnCountry = availableTimeSlots.filter(
      (data) => data.country === value
    );
    const checkDeliverySlot = [...filterDeliveryTimeSlotsBasedOnCountry];
    let selectedTimeSlot = [...selectDeliveryTimeSlot];

    checkDeliverySlot.map((singleSlots) => {
      singleSlots.checked = false;
      if (
        selectedTimeSlot.find((data) => data.timeSlotId === singleSlots._id)
      ) {
        singleSlots.checked = true;
      }
    });

    //Todo group by
    const uniqueDeliveryDate = _.groupBy(checkDeliverySlot, "supplierName");
    const convertObjectToArray = _.values(uniqueDeliveryDate);
    setDeliveryTimeSlot([...convertObjectToArray]);
    setSelectDeliveryTimeSlot([...selectedTimeSlot]);
    setDeliverySlotVisible(false);
    setCountry(value);
  };

  const handleChangeCompaign = (event) => {
    const { Products } = adminReducers.checkOutOrder;
    if (event.target.value === 0) {
      setProducts([...Products]);
    } else {
      const filterProductCompaignWise = Products.filter(
        (product) => product.groupID === event.target.value
      );
      setProducts(filterProductCompaignWise);
    }
    setGroupName(event.target.value);
  };

  return (
    <span>
      <h3 className="pl-5 bg-color mb-0 py-3">
        <img src={"/assets/image/ordrstartrWhite.png"} className="LogoImage" />
      </h3>
      {order !== null &&
        order.Status !== "cancel" &&
        residentialType.length > 0 && (
          <div className="d-flex flex-column card">
            {order.PaymentStatus === "paid" && (
              <span>
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                  <h3
                    className="mb-0 py-3 h_3"
                    style={{ color: "#fbf9ff", textTransform: "uppercase" }}
                  >
                    {" "}
                    Paid{" "}
                  </h3>

                  {order.DeductQtyUponPaymentStatus !== "" && (
                    <h4
                      className="mb-0 py-3 h_4"
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      {order.DeductQtyUponPaymentStatus}{" "}
                    </h4>
                  )}
                </div>

                <TimeSlotsSelection
                  selectDeliveryTimeSlots={selectDeliveryTimeSlot}
                  deliveryTimeSlots={deliveryTimeSlot}
                  availableTimeSlots={availableTimeSlots}
                  classes={{ paper: classes.paper }}
                  id="ringtone-menu"
                  keepMounted
                  open={open}
                  onClose={handleClose}
                />
              </span>
            )}

            <div className="d-flex flex-column inner_card">
              <div className="d-flex flex-row label-row">
                <div className="d-flex align-items-center">
                  <label className="mb-0 text_label">Order Id</label>
                  <div className="mb-0">:</div>
                </div>
                <label className="mb-0">{order._id}</label>
              </div>
              <div className="d-flex flex-row label-row">
                <div className="d-flex align-items-center">
                  <label className="mb-0 text_label">Order Status</label>
                  <div className="mb-0">:</div>
                </div>
                <label className="mb-0">{order.Status}</label>
              </div>
              <div className="d-flex flex-row label-row">
                <div className="d-flex align-items-center">
                  <label className="mb-0 text_label">Name</label>
                  <div className="mb-0">:</div>
                </div>
                <label className="mb-0">{order.Name}</label>
              </div>

              <h3
                className="mt-3 mb-3"
                style={{ color: "#51057C", textTransform: "uppercase" }}
              >
                Shipping Details
              </h3>
              <div
                className="responsive_div row align-items-center"
                style={{ gridRowGap: "16px", maxWidth: "1000px" }}
              >
                <div className="col-sm-6 px-2">
                  <TextField
                    label="Shipping Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ShippingName"
                    onChange={handleChange}
                    value={order.ShippingName}
                  />
                </div>
                <div className="col-sm-6 px-2">
                  <TextField
                    label="Shipping Mobile"
                    variant="outlined"
                    fullWidth
                    type="number"
                    size="small"
                    name="ShippingMobile"
                    onChange={handleChange}
                    value={order.ShippingMobile}
                  />
                </div>
                <div className="col-sm-6 px-2">
                  <TextField
                    label="Shipping Address"
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    name="ShippingAddress1"
                    onChange={handleChange}
                    value={order.ShippingAddress1}
                  />
                </div>
                <div className="col-sm-6 px-2">
                  <TextField
                    label="Shipping Postal Code"
                    variant="outlined"
                    fullWidth
                    type="number"
                    size="small"
                    name="ShippingPostalCode"
                    onChange={handleChange}
                    value={order.ShippingPostalCode}
                  />
                </div>

                <div className="col-sm-6 px-2 row align-items-center gap-20 m-0">
                  {order.Status === "active" ? (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      className="m-0"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Residential Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Residential Type"
                        id="demo-simple-select"
                        value={order.Residential_Type}
                        onChange={OnChangeResidential}
                      >
                        {residentialType.map((singleSupplier) => (
                          <MenuItem value={singleSupplier.value}>
                            {singleSupplier.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      label="Residence Type"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={true}
                      name="residenceType"
                      value={order.Residential_Type}
                    />
                  )}
                </div>

                {order.Status === "active" && pageDetail.FeedBack ? (
                  <div className="col-sm-6 px-2">
                    <TextField
                      label="Customer Feedback"
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                      name="FeedBackMessage"
                      onChange={handleChange}
                      value={order.FeedBackMessage}
                    />
                  </div>
                ) : null}

                <div className="col-sm-6 px-2">
                  <TextField
                    type="email"
                    pattern=".+@globex.com"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ShippingEmail"
                    onChange={handleChange}
                    value={order.ShippingEmail}
                  />
                </div>

                {order.Status !== "active" ? (
                  <div className="col-sm-12 px-2">
                    {is_VisibleDeliveryTime ? (
                      <div
                        className=" row p-0 align-items-center"
                        style={{ gridRowGap: "16px" }}
                      >
                        <div className="col-sm-6 px-2">
                          {order.Status === "confirmed" &&
                          order.PaymentStatus !== "paid" ? (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                              className="m-0"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Country
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                label="Country"
                                id="demo-simple-select"
                                value={country}
                                onChange={handleChangeSelectCountry}
                              >
                                {CONTRIES.map((singleSupplier) => (
                                  <MenuItem value={singleSupplier}>
                                    {singleSupplier}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="mb-2 ml-2 d-flex flex-row align-items-baseline mt-1">
                              {country}
                            </div>
                          )}
                        </div>
                        {/* </div> */}

                        {country !== "" && (
                          <div className="col-sm-6 px-2 d-flex align-items-center">
                            <div className="d-flex flex-row w-25">
                              <label className="mb-0 delivery_web_text">
                                Delivery Slot
                              </label>
                              <span className="mb-0">:</span>
                            </div>
                            <div className="d-flex flex-column">
                              {order.Status === "confirmed" &&
                              order.PaymentStatus !== "paid" ? (
                                <div>
                                  {deliveryTimeSlot &&
                                    deliveryTimeSlot.map(
                                      (singleSupplier, key) =>
                                        singleSupplier.length > 0 && (
                                          <span key={key}>
                                            <div className="d-flex flex-row">
                                              <label
                                                className="mb-0 delivery_web_text"
                                                style={{
                                                  color: "#51057C",
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                <h6>
                                                  {
                                                    singleSupplier[0]
                                                      .supplierName
                                                  }
                                                </h6>
                                              </label>
                                            </div>
                                            {singleSupplier &&
                                              singleSupplier.map(
                                                (singleTimeSlot, index) => (
                                                  <div
                                                    className="mb-2 ml-2 d-flex flex-row align-items-baseline mt-1"
                                                    key={index}
                                                  >
                                                    <input
                                                      type="radio"
                                                      name={
                                                        singleTimeSlot.supplierName
                                                      }
                                                      value={
                                                        singleTimeSlot.startDateTime +
                                                        "_" +
                                                        singleTimeSlot.endDateTime
                                                      }
                                                      key={index}
                                                      checked={
                                                        singleTimeSlot.checked
                                                      }
                                                      onChange={() =>
                                                        OnChange({
                                                          ...singleTimeSlot,
                                                        })
                                                      }
                                                    />
                                                    <div className="d-flex flex-column">
                                                      <span className="mb-0 ml-2">
                                                        {moment
                                                          .tz(
                                                            singleTimeSlot.startDateTime,
                                                            "Asia/Singapore"
                                                          )
                                                          .format(
                                                            "DD-MM-YYYY hh:mm A"
                                                          )}{" "}
                                                        -{" "}
                                                        {moment
                                                          .tz(
                                                            singleTimeSlot.endDateTime,
                                                            "Asia/Singapore"
                                                          )
                                                          .format(
                                                            "DD-MM-YYYY hh:mm A"
                                                          )}{" "}
                                                        ({singleTimeSlot.type}){" "}
                                                        {singleTimeSlot.numberOfSlots -
                                                          singleTimeSlot.numberOfSlotsBooked <=
                                                          10 &&
                                                          "(Limited Slots!)"}{" "}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </span>
                                        )
                                    )}

                                  {order.OfflineMessage &&
                                    order.OfflineMessage.map(
                                      (selectDeliveryTimeSlot, index) => (
                                        <div
                                          className="d-flex flex-column"
                                          key={index}
                                        >
                                          <span
                                            className="mb-0 ml-2 mt-2"
                                            style={{
                                              color: "#51057C",
                                              textTransform: "uppercase",
                                            }}
                                            key={index}
                                          >
                                            <h6>
                                              {" "}
                                              (
                                              {
                                                selectDeliveryTimeSlot.supplierName
                                              }
                                              ) -{" "}
                                              {selectDeliveryTimeSlot.message}{" "}
                                            </h6>
                                          </span>
                                        </div>
                                      )
                                    )}

                                  <Button
                                    variant="contained"
                                    className="btn mb-0 ml-2"
                                    color="primary"
                                    disabled={deliverySlotVisible}
                                    onClick={confirmDeliverySlot}
                                  >
                                    {" "}
                                    Confirm{" "}
                                  </Button>
                                </div>
                              ) : (
                                <div className="mb-2 ml-2">
                                  {selectDeliveryTimeSlot.length > 0 && (
                                    <span>
                                      <div className="d-flex flex-column">
                                        {selectDeliveryTimeSlot.map(
                                          (selectDeliveryTimeSlot, index) => (
                                            <span
                                              className="mb-0 ml-2"
                                              key={index}
                                            >
                                              {" "}
                                              (
                                              {
                                                selectDeliveryTimeSlot.supplierName
                                              }
                                              ) -{" "}
                                              {moment
                                                .tz(
                                                  selectDeliveryTimeSlot.startDateTime,
                                                  "Asia/Singapore"
                                                )
                                                .format(
                                                  "DD-MM-YYYY hh:mm A"
                                                )}{" "}
                                              -{" "}
                                              {moment
                                                .tz(
                                                  selectDeliveryTimeSlot.endDateTime,
                                                  "Asia/Singapore"
                                                )
                                                .format(
                                                  "DD-MM-YYYY hh:mm A"
                                                )}{" "}
                                              ({selectDeliveryTimeSlot.type})
                                            </span>
                                          )
                                        )}
                                      </div>
                                      {order.OfflineMessage.map(
                                        (selectDeliveryTimeSlot, index) => (
                                          <div
                                            className="d-flex flex-column"
                                            key={index}
                                          >
                                            <span
                                              className="mb-0 ml-2 mt-2"
                                              style={{
                                                color: "#51057C",
                                                textTransform: "uppercase",
                                              }}
                                              key={index}
                                            >
                                              <h6>
                                                {" "}
                                                (
                                                {
                                                  selectDeliveryTimeSlot.supplierName
                                                }
                                                ) -{" "}
                                                {selectDeliveryTimeSlot.message}{" "}
                                              </h6>
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </span>
                                  )}

                                  {oldVersionSelectDeliveryTimeSlot !==
                                    null && (
                                    <span>
                                      <div className="d-flex flex-column">
                                        <span className="mb-0 ml-2">
                                          {moment
                                            .tz(
                                              oldVersionSelectDeliveryTimeSlot.startDateTime,
                                              "Asia/Singapore"
                                            )
                                            .format("DD-MM-YYYY hh:mm A")}{" "}
                                          -{" "}
                                          {moment
                                            .tz(
                                              oldVersionSelectDeliveryTimeSlot.endDateTime,
                                              "Asia/Singapore"
                                            )
                                            .format("DD-MM-YYYY hh:mm A")}{" "}
                                          (
                                          {
                                            oldVersionSelectDeliveryTimeSlot.type
                                          }
                                          )
                                        </span>
                                      </div>
                                    </span>
                                  )}

                                  {order.PaymentStatus === "paid" &&
                                    selectDeliveryTimeSlot === null && (
                                      <Button
                                        variant="contained"
                                        className="btn"
                                        color="primary"
                                        onClick={handleOpenTimeSlots}
                                      >
                                        {" "}
                                        Select Your Delivery Time Slot{" "}
                                      </Button>
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="col-sm-6 px-2">
                          <TextField
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            size="small"
                            disabled={true}
                            multiline
                            rows={2}
                            name="remarks"
                            value={order.RemarkMessage}
                          />
                        </div>

                        {order.PaymentStatus === "unpaid" && (
                          <div className="col-sm-12 px-2">
                            {timerMinutes !== null && (
                              <h3 style={{ color: "#51057C" }}>
                                {" "}
                                {timerMinutes}:{timerSeconds}{" "}
                              </h3>
                            )}
                            <p style={{ color: "red" }}>
                              <b>
                                Your Delivery slot will be on hold for 15
                                minutes, please make payment within 15 minutes
                                to secure time slot{" "}
                              </b>
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="col-sm-6 px-2">
                        <div className="col-sm-6">
                          <TextField
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            size="small"
                            disabled={true}
                            multiline
                            rows={2}
                            name="remarks"
                            value={order.RemarkMessage}
                          />
                        </div>
                        <div className="col-sm-6">
                          <p className="w-100" style={{ color: "red" }}>
                            <b> {order.HideDeliveryMessage}</b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-sm-6 px-2">
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={true}
                      multiline
                      rows={2}
                      name="remarks"
                      value={order.RemarkMessage}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex flex-column mt-5 order_table">
              {order.Status === "active" &&
                order.PaymentStatus === "unpaid" && (
                  <div>
                    <div>
                      <label>
                        <b>Order</b>
                      </label>
                      <div className="mainOrderDiv">
                        <div className="innerOrderDiv">
                          {order.Items.map((singleItem, index) => (
                            <div
                              className="d-flex order_table_details justify-content-start w-100"
                              key={singleItem.id}
                            >
                              <Zoom>
                                <ImageLoader
                                  className="product-image"
                                  src={`${ENVIRONMENT_VARIABLES.UPLOAD_IMAGE_URL}${singleItem.image}`}
                                >
                                  <img
                                    className="img-fluid"
                                    style={{ height: "60px", width: "60px" }}
                                  />
                                  <img
                                    src="/assets/image/NoImages.png"
                                    style={{ height: "60px", width: "60px" }}
                                  />
                                  <img
                                    src="/assets/image/imageLoader.png"
                                    style={{ height: "60px", width: "60px" }}
                                  />
                                </ImageLoader>
                              </Zoom>
                              <div className="product-card">
                                <div className="w-100 d-flex align-items-center ">
                                  {/* <label className="index mb-0">{index + 1}</label> */}
                                  <label className="flex-grow-1 description d-flex mb-0">
                                    {singleItem.itemName}&nbsp;
                                    <strong>[{singleItem.keyword}]</strong>
                                  </label>
                                  <div className="d-flex align-items-center">
                                    <Button
                                      variant="contained"
                                      className="btn buttonQtyChange"
                                      color="primary"
                                      onClick={() =>
                                        handleChangeProductMinus(singleItem.id)
                                      }
                                    >
                                      -
                                    </Button>
                                    <label className="qtyLabel">
                                      {singleItem.qty}
                                    </label>
                                    <Button
                                      variant="contained"
                                      className="btn buttonQtyChange"
                                      color="primary"
                                      onClick={() =>
                                        handleChangeProductAdd(singleItem.id)
                                      }
                                    >
                                      +
                                    </Button>
                                  </div>
                                  {/* <label className="description d-flex mb-0"></label> */}
                                </div>
                                <div className="d-flex align-items-center w-100 justify-content-between">
                                  <label className="unit_price d-flex mb-0">
                                    ${Number(singleItem.price).toFixed(2)}
                                  </label>
                                  <label className="total_price d-flex mb-0 justify-content-end w-100">
                                    <strong className="main_price">
                                      ${Number(singleItem.total).toFixed(2)}
                                    </strong>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex my-5 flex-column"
                      style={{ gap: "20px" }}
                    >
                      <FormControl
                        variant="outlined"
                        size="small"
                        className="campaign"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Campaign
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Campaign"
                          id="demo-simple-select"
                          value={groupName}
                          onChange={handleChangeCompaign}
                        >
                          {group.map((singleSupplier) => (
                            <MenuItem value={singleSupplier._id}>
                              {singleSupplier.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        className="btn add_more_items"
                        color="primary"
                        onClick={toggleAddMoreItems}
                      >
                        Add More Items
                      </Button>
                    </div>
                  </div>
                )}

              {order.PaymentStatus === "unpaid" &&
                order.Status === "active" &&
                visibleMoreItems && (
                  <div className="mainOrderDiv ">
                    <div className="innerOrderDiv">
                      {products.map((singleItem, index) => (
                        <div
                          className="d-flex order_table_details"
                          key={singleItem.id}
                        >
                          <Zoom>
                            <ImageLoader
                              className="product-image"
                              src={`${ENVIRONMENT_VARIABLES.UPLOAD_IMAGE_URL}${singleItem.image}`}
                            >
                              <img
                                className="img-fluid"
                                style={{ height: "60px", width: "60px" }}
                              />
                              <img
                                src="/assets/image/NoImages.png"
                                style={{ height: "60px", width: "60px" }}
                              />
                              <img
                                src="/assets/image/imageLoader.png"
                                style={{ height: "60px", width: "60px" }}
                              />
                            </ImageLoader>
                          </Zoom>
                          <div className="product-card">
                            <div className="w-100 d-flex align-items-center">
                              {/* <label className="index mb-0">{index + 1}</label> */}
                              <label className="description d-flex mb-0 w-100">
                                {singleItem.itemName}&nbsp;
                                <strong>[{singleItem.keyword}]</strong>
                              </label>

                              {/* <label className="description d-flex mb-0">{singleItem.keyword}</label> */}
                            </div>
                            <div className="d-flex align-items-center w-100 justify-content-between">
                              <label className="unit_price d-flex mb-0">
                                ${Number(singleItem.price).toFixed(2)}
                              </label>
                              <span className="total_price d-flex mb-0 justify-content-end">
                                <Button
                                  variant="contained"
                                  className="btn add_more_items"
                                  color="primary"
                                  onClick={() =>
                                    handleChangeNewProduct(singleItem.id)
                                  }
                                >
                                  Add
                                </Button>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {(order.Status === "confirmed" ||
                order.PaymentStatus === "paid") && (
                <div>
                  <table className="table table-bordered">
                    <thead
                      style={{ color: "black", backgroundColor: "#86faff" }}
                    >
                      <tr>
                        <th scope="col" className="col-md-6">
                          Order Items
                        </th>
                        <th scope="col" className="col-md-2">
                          Keyword
                        </th>
                        <th scope="col">Quantity</th>
                        <th scope="col" className="col-md-2">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.Items.map((singleItem) => (
                        <tr key={singleItem.id}>
                          <td>{singleItem.itemName}</td>
                          <td>{singleItem.keyword}</td>
                          <td>{singleItem.qty}</td>
                          <td className="text-right">
                            ${Number(singleItem.total).toFixed(2)}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <h6>Sub Total</h6>
                        </td>
                        <td className="text-right">
                          ${Number(order.Total).toFixed(2)}
                        </td>
                      </tr>

                      {order.ShippingSupplier &&
                        order.ShippingSupplier.map((supplier, index) => (
                          <tr key={index}>
                            <td colSpan={2}></td>
                            <td>
                              <h6> &#8226; {supplier.supplierName}</h6>
                            </td>
                            <td className="text-right">
                              ${Number(supplier.shippingCharge).toFixed(2)}
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <h6
                            style={{
                              color: "#51057C",
                              textTransform: "uppercase",
                            }}
                          >
                            Total Shipping Charge
                          </h6>
                        </td>
                        <td className="text-right">
                          ${Number(order.ShippingCharge).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <h6>Discount</h6>
                        </td>
                        <td className="text-right">
                          ${Number(discountTotal).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <h6>Point Discount</h6>
                        </td>
                        <td className="text-right">
                          ${Number(pointDiscount).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <h6>Grand Total</h6>
                        </td>
                        <td className="text-right">
                          $
                          {order.Total +
                            order.ShippingCharge -
                            (discountTotal + pointDiscount) >=
                          0
                            ? Number(
                                order.Total +
                                  order.ShippingCharge -
                                  (discountTotal + pointDiscount)
                              ).toFixed(2)
                            : 0}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}></td>
                        <td colSpan={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.print()}
                          >
                            Print Order
                          </Button>
                        </td>
                      </tr>

                      {order.PaymentStatus === "paid" && (
                        <tr>
                          <td colSpan={4}>
                            <p style={{ color: "black", textAlign: "center" }}>
                              <b>
                                {" "}
                                {pageDetail && pageDetail.CreditCardMessage}
                              </b>
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {order.PaymentStatus === "unpaid" &&
                order.Status === "confirmed" &&
                checkoutUserDetails &&
                checkoutUserDetails.Opt_In === false && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTamsCondition}
                          onChange={handleTamsCondition}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={` Please sign me up to be a member of ${pageDetail.FbPageName}`}
                    />
                  </div>
                )}

              {order.PaymentStatus === "unpaid" && (
                <div className="d-flex total_order_price flex-column">
                  {order.Status === "active" && (
                    <span className="d-flex w-100 flex-column align-items-end">
                      <label>
                        <h6>
                          Sub Total &nbsp; &nbsp; $
                          {Number(order.Total).toFixed(2)}
                        </h6>
                      </label>
                      {order.ShippingSupplier.map((supplier, index) => (
                        <label key={index}>
                          <h6>
                            {" "}
                            &#8226; {supplier.supplierName} &nbsp; &nbsp; $
                            {Number(supplier.shippingCharge).toFixed(2)}
                          </h6>
                        </label>
                      ))}
                      <label>
                        <h6
                          style={{
                            color: "#51057C",
                            textTransform: "uppercase",
                          }}
                        >
                          Total Shipping Charge &nbsp; &nbsp; $
                          {Number(order.ShippingCharge).toFixed(2)}
                        </h6>
                      </label>
                      {chipData.length > 0 && (
                        <div>
                          <Paper component="ul" className={classes.root}>
                            {chipData.map((data) => {
                              return (
                                <li key={data.key}>
                                  <Chip
                                    label={data.label}
                                    onDelete={removeCoupon(data)}
                                    className={classes.chip}
                                  />
                                </li>
                              );
                            })}
                          </Paper>{" "}
                          <br />
                        </div>
                      )}
                      <label>
                        <h6>
                          Discount &nbsp; &nbsp; $
                          {Number(discountTotal).toFixed(2)}
                        </h6>
                      </label>
                      <label>
                        <h6>
                          Point Discount &nbsp; &nbsp; $
                          {Number(pointDiscount).toFixed(2)}
                        </h6>
                      </label>
                      <label>
                        <h6>
                          Grand Total &nbsp; &nbsp; $
                          {order.Total +
                            order.ShippingCharge -
                            (discountTotal + pointDiscount) >=
                          0
                            ? Number(
                                order.Total +
                                  order.ShippingCharge -
                                  (discountTotal + pointDiscount)
                              ).toFixed(2)
                            : 0}
                        </h6>
                      </label>
                    </span>
                  )}

                  {isPay ? (
                    <span className="w-100">
                      {order.Status === "active" ? (
                        <div style={{ textAlign: "end" }}>
                          <input
                            type="text"
                            value={couponName}
                            name="couponName"
                            placeholder={"Coupon/Referral Code"}
                            onChange={onChange}
                          />
                          &nbsp; &nbsp;
                          <Button
                            variant="contained"
                            className="btn apply_coupon"
                            color="primary"
                            onClick={applyCoupon}
                          >
                            Apply
                          </Button>
                          {isApplyPoint && (
                            <div className={classes.pointApply}>
                              <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">
                                  ({pageDetail.ExchangePoint} = $
                                  {pageDetail.ExchangeRate})
                                  {Number(
                                    checkoutUserDetails.PointsEarned
                                  ).toFixed(2)}{" "}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectPoint}
                                  onChange={handleChangePoint}
                                >
                                  {listOfPoints.map((singleCoupon, index) => (
                                    <MenuItem key={index} value={singleCoupon}>
                                      {singleCoupon}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant="contained"
                                className="btn apply_coupon marginLeft"
                                color="primary"
                                onClick={applyPoint}
                              >
                                Apply
                              </Button>
                            </div>
                          )}
                          <br />
                          <Button
                            variant="contained"
                            className="btn cancel_order margin-top20"
                            color="secondary"
                            onClick={OrderCancel}
                          >
                            DELETE MY ORDER
                          </Button>
                          <Button
                            variant="contained"
                            className="btn confirm_order margin-top20 ml-10"
                            color="primary"
                            onClick={OrderConfirm}
                          >
                            SAVE ORDER
                          </Button>
                        </div>
                      ) : (
                        <span className="w-100">
                          {order.PaymentStatus === "unpaid" &&
                            order.Status === "confirmed" && (
                              <span>
                                {pageDetail && (
                                  <span>
                                    <div className="d-flex align-items-end flex-column">
                                      <FormControl
                                        component="fieldset"
                                        className=""
                                      >
                                        <FormLabel component="legend">
                                          <h4>Payment Type</h4>
                                        </FormLabel>
                                        <RadioGroup
                                          aria-label="payment"
                                          name="payment"
                                          value={paymentValue}
                                          onChange={handleChangePayment}
                                        >
                                          {paymentMethodVisible &&
                                            paymentMethodVisible.map(
                                              (singlePayment, index) => (
                                                <span key={index}>
                                                  <FormControlLabel
                                                    value={singlePayment.value}
                                                    key={index}
                                                    control={<Radio />}
                                                    label={singlePayment.label}
                                                  />
                                                  <img
                                                    src={
                                                      "/assets/image/" +
                                                      singlePayment.value +
                                                      ".png"
                                                    }
                                                    width={"100px"}
                                                  />
                                                </span>
                                              )
                                            )}
                                        </RadioGroup>
                                      </FormControl>

                                      <br />
                                      <div className="d-flex">
                                        <Button
                                          variant="contained"
                                          className="btn"
                                          color="secondary"
                                          onClick={OrderBackToEdit}
                                        >
                                          Return to Edit Cart
                                        </Button>
                                        &nbsp;
                                        <Button
                                          variant="contained"
                                          className="btn"
                                          color="primary"
                                          disabled={!checkedTamsCondition}
                                          onClick={PayConfirm}
                                        >
                                          Save and Proceed to Pay
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="w-100 mt-4">
                                      <p style={{ textAlign: "center" }}>
                                        {" "}
                                        By placing your order, You agree to the
                                        <a href={pageDetail.TermsAndConditions}>
                                          {" "}
                                          <b> terms and conditions </b>{" "}
                                        </a>{" "}
                                        and acknowledge the
                                        <a href={pageDetail.PrivacyPolicy}>
                                          {" "}
                                          <b> privacy policy.</b>{" "}
                                        </a>
                                      </p>
                                      <p
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        <b>
                                          {" "}
                                          {pageDetail &&
                                            pageDetail.CreditCardMessage}
                                        </b>
                                      </p>
                                    </div>
                                  </span>
                                )}
                              </span>
                            )}
                        </span>
                      )}
                    </span>
                  ) : (
                    <span>
                      {order.Status !== "cancel" && (
                        <Button
                          variant="contained"
                          className="btn cancel_order"
                          onClick={OrderCancel}
                          color="secondary"
                        >
                          CANCEL ORDER
                        </Button>
                      )}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      {adminReducers.loading && <Loader />}
    </span>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Checkout);
