import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import DateTimePicker from "react-datetime-picker";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import swal from "sweetalert";

import { CONTRIES } from "../../../constants/actionTypes";
import {
  Card,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import "./style.css";

let moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DeliveryDate = (props) => {
  const classes = useStyles();
  const [newDeliveryDate, setNewDeliveryDate] = useState({
    FbPageId: "",
    supplierId: null,
    supplierName: "",
    startDateTime: new Date(),
    endDateTime: new Date(),
    numberOfSlots: 0,
    numberOfSlotsBooked: 0,
    type: "Deliver",
    country: "",
  });
  const [allTypes, setAllTypes] = useState([
    {
      value: "Deliver",
      checked: true,
    },
    {
      value: "Self Collect",
      checked: false,
    },
  ]);
  const [supplier, setSupplier] = useState([]);
  const [country, setCountry] = useState("");

  const handleChangeSelect = (event) => {
    const { value } = event.target;
    const findSupplierName = supplier.find((data) => data._id === value);
    let newDetail = { ...newDeliveryDate };
    newDetail.supplierId = value;
    newDetail.supplierName = findSupplierName.supplierName;
    newDetail.country = findSupplierName.country;
    setNewDeliveryDate(newDetail);
    props.actions.adminAction.getPageSuppliersDates(
      adminReducers.selectedPage.FbPageId,
      value
    );
  };

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
    supplierDeliveryDate: state.adminReducer.supplierDeliveryDate,
    deliveryFees: state.adminReducer.deliveryFees,
  }));

  const onStartChange = (date) => {
    let newDetail = { ...newDeliveryDate };
    newDetail.startDateTime = date;
    setNewDeliveryDate(newDetail);
  };

  const onEndChange = (date) => {
    let newDetail = { ...newDeliveryDate };
    newDetail.endDateTime = date;
    setNewDeliveryDate(newDetail);
  };

  useEffect(() => {
    if (adminReducers.selectedPage !== null) {
      props.actions.adminAction.getOnlineDeliveryFees(
        adminReducers.selectedPage.FbPageId
      );
      setNewDeliveryDate({
        FbPageId: "",
        supplierId: null,
        supplierName: "",
        startDateTime: new Date(),
        endDateTime: new Date(),
        numberOfSlots: 0,
        numberOfSlotsBooked: 0,
        type: "Deliver",
        country: "",
      });
    }
  }, [adminReducers.selectedPage]);

  useEffect(() => {
    if (adminReducers.deliveryFees) {
      setSupplier([...adminReducers.deliveryFees]);
    }
  }, [adminReducers.deliveryFees]);

  const addDeliveryDate = () => {
    if (newDeliveryDate.supplierId != null) {
      props.actions.adminAction.addSupplierDeliveryDate({
        ...newDeliveryDate,
        FbPageId: adminReducers.selectedPage.FbPageId,
      });
      setNewDeliveryDate({
        ...newDeliveryDate,
        FbPageId: "",
        startDateTime: new Date(),
        endDateTime: new Date(),
        numberOfSlots: 0,
        numberOfSlotsBooked: 0,
        type: "Deliver",
      });
    } else {
      swal("Oops...", "please select Supplier", "error");
    }
  };

  const deleteDeliveryDate = (remove) => {
    props.actions.adminAction.deleteSupplierDeliveryDate(remove._id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newDetail = { ...newDeliveryDate };
    newDetail[name] = value;
    setNewDeliveryDate(newDetail);
  };

  const OnChangeResidential = (select) => {
    let TypeData = [...allTypes];
    let newDetail = { ...newDeliveryDate };
    newDetail.type = select.value;
    TypeData.map((single) => {
      single.checked = false;
      if (single.value === select.value) {
        single.checked = true;
      }
    });
    setNewDeliveryDate(newDetail);
    setAllTypes(TypeData);
  };

  const handleChangeSelectCountry = (event) => {
    const { value } = event.target;
    const allDeliveryFees = [];
    adminReducers.deliveryFees.map((singleData) => {
      if (singleData.country === value) {
        allDeliveryFees.push(singleData);
      }
    });
    setSupplier(allDeliveryFees);
    setCountry(value);
  };

  return (
    <div className="orders-layout">
      <Card
        className="searchEngine align-items-center justify-content-start"
        style={{ overflow: "unset" }}
      >
        <FormControl variant="outlined" size="small">
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Country"
            id="demo-simple-select"
            value={country}
            onChange={handleChangeSelectCountry}
            className="filter-box m-0"
          >
            {CONTRIES.map((singleSupplier) => (
              <MenuItem value={singleSupplier}>{singleSupplier}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel id="demo-simple-select-label">Suppliers</InputLabel>
          <Select
            className="filter-box m-0"
            labelId="demo-simple-select-label"
            label="Suppliers"
            id="demo-simple-select"
            value={newDeliveryDate.supplierId}
            onChange={handleChangeSelect}
          >
            {supplier.map((singleSupplier) => (
              <MenuItem value={singleSupplier._id}>
                {singleSupplier.supplierName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="m-0 d-flex flex-column">
          <label className="m-0"> Start Time </label>
          <DateTimePicker
            onChange={onStartChange}
            value={newDeliveryDate.startDateTime}
            className="filter-box"
          />
        </div>
        <div className="m-0 d-flex flex-column">
          <label className="m-0"> End Time </label>
          <DateTimePicker
            className="filter-box"
            onChange={onEndChange}
            value={newDeliveryDate.endDateTime}
          />
        </div>

        <TextField
          className="filter-box"
          label="Number of Slots"
          variant="outlined"
          size="small"
          name="numberOfSlots"
          style={{ width: "200px", height: "30px" }}
          onChange={handleChange}
          value={newDeliveryDate.numberOfSlots}
        />

        <FormControl component="fieldset">
          <FormLabel component="legend">Deliver/Pick Up :</FormLabel>

          <RadioGroup
            className="d-flex flex-row"
            aria-label="Deliver/Pick Up"
            name="Deliver/Pick Up"
          >
            {allTypes.map((singleType, index) => (
              <FormControlLabel
                color="primary"
                control={<Radio color="primary" />}
                label={singleType.value}
                name="DeliveryType"
                value={singleType.value}
                key={index}
                checked={singleType.checked}
                onChange={() => OnChangeResidential(singleType)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {/* <div className="d-flex flex-row justify-content-center mt-3">
          <span className="mr-2">Deliver/Pick Up : </span>
          {allTypes.map((singleType, index) => (
            <div className="deliveryStyle d-flex align-items-center mr-2">
              <input
                type="radio"
                name="DeliveryType"
                className="mr-1"
                value={singleType.value}
                key={index}
                checked={singleType.checked}
                onChange={() => OnChangeResidential(singleType)}
              />
              <span> {singleType.value} </span>
            </div>
          ))}
        </div> */}
        <Button
          variant="contained"
          color="primary"
          className="ml-3"
          onClick={addDeliveryDate}
        >
          Save
        </Button>
      </Card>

      {/* <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Start DateTime</th>
            <th scope="col">End DateTime</th>
            <th scope="col">Available Slots</th>
            <th scope="col">Number of Slots</th>
            <th scope="col">Deliver/Pick Up</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {adminReducers.supplierDeliveryDate.map((singleDate, index) => {
            let color = "black";
            let checkSlots =
              singleDate.numberOfSlots - singleDate.numberOfSlotsBooked;
            if (checkSlots <= 0) {
              checkSlots = 0;
              color = "red";
            } else if (checkSlots < 40) {
              color = "orange";
            }
            return (
              <tr key={index}>
                <th scope="row">{index}</th>
                <td>
                  {moment
                    .tz(singleDate.startDateTime, "Asia/Singapore")
                    .format("DD-MM-YYYY hh:mm A")}
                </td>
                <td>
                  {moment
                    .tz(singleDate.endDateTime, "Asia/Singapore")
                    .format("DD-MM-YYYY hh:mm A")}
                </td>
                <td style={{ color: color }}>{checkSlots}</td>
                <td>{singleDate.numberOfSlots}</td>
                <td>{singleDate.type}</td>
                <td>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteDeliveryDate(singleDate)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}

      <TableContainer className="table-body" component={Paper}>
        <Table
          size="small"
          stickyHeader
          className={classes.table}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Start DateTime</TableCell>
              <TableCell align="center">End DateTime</TableCell>
              <TableCell align="right">Available Slots</TableCell>
              <TableCell align="right">Number of Slots</TableCell>
              <TableCell align="center">Deliver/Pick Up</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminReducers.supplierDeliveryDate.map((singleDate, index) => {
              let color = "black";
              let checkSlots =
                singleDate.numberOfSlots - singleDate.numberOfSlotsBooked;
              if (checkSlots <= 0) {
                checkSlots = 0;
                color = "red";
              } else if (checkSlots < 40) {
                color = "orange";
              }
              return (
                <TableRow key={index}>
                  <TableCell align="center" component="th" scope="row">
                    {index}
                  </TableCell>
                  <TableCell align="center">
                    {moment
                      .tz(singleDate.startDateTime, "Asia/Singapore")
                      .format("DD-MM-YYYY hh:mm A")}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {moment
                      .tz(singleDate.endDateTime, "Asia/Singapore")
                      .format("DD-MM-YYYY hh:mm A")}
                  </TableCell>
                  <TableCell style={{ color: color }} align="right">
                    {checkSlots}
                  </TableCell>
                  <TableCell align="right">
                    {singleDate.numberOfSlots}
                  </TableCell>
                  <TableCell align="center">{singleDate.type}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteDeliveryDate(singleDate)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(DeliveryDate);
