import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { Card } from "@material-ui/core";
import ImageLoader from "react-load-image";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminAction from "../../../actions/authAction";

import DateTimePicker from "react-datetime-picker";
import ENVIRONMENT_VARIABLES from "../../../environment.config";

import moment from "moment-timezone";

export const EditDialog = (props) => {
  var reader = new FileReader();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (props.editItem) {
      setUserProfile({
        ...props.editItem,
        ExpiryDate: new Date(),
        PointsToAdd: 1,
      });
    }
  }, [props.editItem]);

  const [imageDisplay, setImageDisplay] = useState(
    `${ENVIRONMENT_VARIABLES.UPLOAD_IMAGE_URL}${props.editItem.image}`
  );

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newDetail = { ...userProfile };
    newDetail[name] = value;
    newDetail.total = Number(newDetail.price * value).toFixed(2);
    setUserProfile(newDetail);
  };

  useEffect(() => {
    if (userProfile) {
      let TmpKeyword = { ...userProfile };
      TmpKeyword.ExpiryDate = new Date(TmpKeyword.ExpiryDate);
      setUserProfile({ ...TmpKeyword });
    }
  }, []);

  const handleSubmitClose = (userProfile) => {
    props.handleEditSubmitClose(userProfile);
  };

  const onStartChange = (date) => {
    let keywordTmp = { ...userProfile };
    keywordTmp.ExpiryDate = date;
    setUserProfile(keywordTmp);
  };

  reader.onload = function (e) {
    setImageDisplay(e.target.result);
  };

  const deleteUserProfile = (editItems) => {
    const data = {
      _id: props.editItem._id,
      pointCollectionID: editItems._id,
    };
    props.actions.adminAction.updateUserDeduc(data);
  };

  return (
    <div>
      {userProfile && (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <ValidatorForm
            onSubmit={() => handleSubmitClose(userProfile)}
            autoComplete="off"
          >
            <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
            <DialogContent>
              <div className="row" style={{ gridRowGap: "20px" }}>
                <div className="popup-input-box col-4 ">
                  <span className="d-block text-center w-100">
                    Upload Image
                  </span>
                  <ImageLoader
                    src={imageDisplay}
                    className="d-flex justify-content-center"
                  >
                    <img
                      className="img-fluid"
                      style={{ height: "60px", width: "60px" }}
                    />
                    <img
                      src="/assets/image/NoImages.png"
                      style={{ height: "60px", width: "60px" }}
                    />
                    <img
                      src="/assets/image/imageLoader.png"
                      style={{ height: "60px", width: "60px" }}
                    />
                  </ImageLoader>
                </div>
                <div className="col-8">
                  <div className="row" style={{ gridRowGap: "20px" }}>
                    <div className="col-6">
                      <TextValidator
                        className="flex-grow-1"
                        label="Name"
                        fullWidth
                        name="Name"
                        type="text"
                        value={userProfile.Name}
                        disabled={true}
                        onChange={handleChange}
                        errorMessages={["this field is required"]}
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    <div className="col-6">
                      <TextValidator
                        className="flex-grow-1"
                        label="Mobile Number"
                        fullWidth
                        name="ShippingMobile"
                        type="number"
                        value={userProfile.ShippingMobile}
                        disabled={true}
                        onChange={handleChange}
                        errorMessages={["this field is required"]}
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    <div className="col-6">
                      <TextValidator
                        className="flex-grow-1"
                        label="Address"
                        fullWidth
                        name="ShippingAddress1"
                        type="text"
                        value={userProfile.ShippingAddress1}
                        disabled={true}
                        onChange={handleChange}
                        errorMessages={["this field is required"]}
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    <div className="col-6">
                      <TextValidator
                        className="flex-grow-1"
                        label="Email"
                        fullWidth
                        name="email"
                        type="text"
                        value={userProfile.email}
                        disabled={true}
                        onChange={handleChange}
                        errorMessages={["this field is required"]}
                        variant="outlined"
                        size="small"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <TextValidator
                    className="flex-grow-1"
                    label="Postal Code"
                    fullWidth
                    name="ShippingPostalCode"
                    type="number"
                    value={userProfile.ShippingPostalCode}
                    disabled={true}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <TextValidator
                    className="flex-grow-1"
                    label="Points Earned"
                    fullWidth
                    name="PointsEarned"
                    value={userProfile.PointsEarned}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-4">
                  <TextValidator
                    className="flex-grow-1"
                    label="Points Expired"
                    fullWidth
                    name="PointsExpired"
                    type="number"
                    value={userProfile.userProfile.PointsExpired}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-4">
                  <TextValidator
                    className="flex-grow-1"
                    label="Points Expiring"
                    fullWidth
                    name="PointsExpiring"
                    type="number"
                    value={
                      userProfile.userProfile.PointsExpiring === null
                        ? 0
                        : userProfile.userProfile.PointsExpiring
                    }
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-4">
                  <TextValidator
                    className="flex-grow-1"
                    label="Points Used"
                    fullWidth
                    name="PointsUsed"
                    type="number"
                    value={userProfile.userProfile.PointsUsed}
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div
                  className="col-12"
                  variant="outlined"
                  style={{ overflow: "unset" }}
                >
                  <Card className="p-2" style={{ overflow: "unset" }}>
                    <div className="row align-items-center">
                      <div className="col-4">
                        <TextValidator
                          className="flex-grow-1"
                          label="Points To Add"
                          fullWidth
                          name="PointsToAdd"
                          type="number"
                          value={userProfile.PointsToAdd}
                          validators={["required", "minNumber:1"]}
                          onChange={handleChange}
                          errorMessages={[
                            "this field is required",
                            "Points should be more than 0",
                          ]}
                          variant="outlined"
                          size="small"
                        />
                      </div>
                      <div className="col-6">
                        <label>ExpiryDate</label>
                        <DateTimePicker
                          onChange={onStartChange}
                          value={userProfile.ExpiryDate}
                        />
                      </div>
                      <div className="col-2 d-flex align-items-end justify-content-end">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="point-tables col-12">
                  <table>
                    <tr>
                      <th>points</th>
                      <th>ExpiryDate</th>
                      <th>Action</th>
                    </tr>
                    {props.editItem.pointCollections.map(
                      (data) =>
                        data.IsManual === true && (
                          <tr>
                            <td>{data.points}</td>
                            <td>
                              {" "}
                              {moment
                                .tz(data.orderDate, "Asia/Singapore")
                                .format("DD-MM-YYYY hh:mm A")}
                            </td>

                            <td>
                              <DeleteIcon
                                color="secondary"
                                onClick={() => deleteUserProfile(data)}
                              />
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "16px 24px" }}>
              <Button
                onClick={props.handleClose}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(EditDialog);
