import React, { useEffect, useState } from "react";
import SearchBar from "./searchBar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { connect, useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import * as adminAction from "../../../actions/authAction";
import { bindActionCreators } from "redux";
import DisplayDetailDialog from "./displayDetailDialog";
import Button from "@material-ui/core/Button";
import { Card } from "@material-ui/core";

let moment = require("moment-timezone");
const columns = [
  { id: "FBId", label: "FaceBook Id", minWidth: 70 },
  { id: "FBName", label: "FaceBook Name", minWidth: 70 },
  { id: "FullName", label: "FullName(IC)", minWidth: 70 },
  { id: "Email", label: "Email", minWidth: 70 },
  { id: "Phone", label: "Phone", minWidth: 70 },
  { id: "Type", label: "Type", minWidth: 30, align: "center" },
  { id: "Date", label: "Date", minWidth: 100, align: "center" },
  { id: "Block", label: "Block", minWidth: 30, align: "center" },
  { id: "More", label: "More Detail", minWidth: 30, align: "center" },
];

function createData(
  FullName,
  Email,
  Phone,
  Type,
  Date,
  FBId,
  FBName,
  Block,
  id
) {
  return { FullName, Email, Phone, Type, Date, FBId, FBName, Block, id };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 258px)",
  },
});

const NewUserRequest = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const adminReducers = useSelector((state) => ({
    newRegistration: state.adminReducer.newRegistration,
  }));

  useEffect(() => {
    if (
      adminReducers.newRegistration !== null &&
      adminReducers.newRegistration !== undefined
    ) {
      let records = [];
      adminReducers.newRegistration.map((singleItem) => {
        records.push(
          createData(
            singleItem.FullName,
            singleItem.Email,
            singleItem.Phone,
            singleItem.Type.toUpperCase(),
            moment
              .tz(singleItem.Date, "Asia/Singapore")
              .format("DD-MM-YYYY hh:mm A"),
            singleItem.FBId,
            singleItem.FirstName + " " + singleItem.LastName,
            singleItem.Block,
            singleItem._id
          )
        );
      });
      setRows(records);
      setPage(0);
    }
  }, [adminReducers.newRegistration]);

  const changeBlock = (id, value) => {
    props.actions.adminAction.editRegistrationUser({
      _id: id,
      Block: !value,
    });
  };

  const closeUserDetail = () => {
    setOpen(false);
  };

  const openUserDetail = (id) => {
    const findUserDetail = adminReducers.newRegistration.find(
      (data) => data._id === id
    );
    setUserDetail(findUserDetail);
    setOpen(true);
  };

  return (
    <div className="orders-layout">
      <Card className="searchEngine">
        <SearchBar />
      </Card>
      <DisplayDetailDialog
        open={open}
        closeOrders={closeUserDetail}
        title={"User's Detail"}
        data={userDetail}
      />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column, iIndex) => {
                        let value = row[column.id];
                        if (column.id === "Block") {
                          return (
                            <TableCell key={iIndex} align="center">
                              <Switch
                                checked={row.Block}
                                onChange={() => changeBlock(row.id, row.Block)}
                                name="BlockUser"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </TableCell>
                          );
                        } else if (column.id === "More") {
                          return (
                            <TableCell key={iIndex} align="center">
                              <Button
                                variant="contained"
                                key={row.id}
                                className="btn show_order"
                                color="primary"
                                onClick={() => openUserDetail(row.id)}
                              >
                                More
                              </Button>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={iIndex} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(NewUserRequest);
