import React from 'react';
import './login.css';
import ENVIRONMENT_VARIABLES from '../../../environment.config';

const CustomerLogin = () => {

    const FbLogin = () => {
        window.location.replace(ENVIRONMENT_VARIABLES.Base_API_URL + "/Oauths/Registration/facebook");
    };

    return (
        <div>

            <div className="d-flex facebook-login_registration flex-column">

                <img src={"/assets/image/ordrstartr.png"}/>
                &nbsp;

                <button className="loginBtn loginBtn--facebook" onClick={FbLogin}>
                    Login with Facebook
                </button>

                <br/>
                <div className="alert alert-warning">
                    <strong>Note:</strong> By logging in, you are agreeing to be a ordrStartr.
                </div>

            </div>

        </div>
    );

};

export default CustomerLogin;


