//Customer
export const CUSTOMER_INPROGRESS = "CUSTOMER_INPROGRESS";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_LOGIN = "CUSTOMER_FB_LOGIN";
export const CUSTOMER_LOGOUT = "CUSTOMER_FB_LOGOUT";
export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_ORDERS = "CUSTOMER_ORDERS";
export const NEW_REGISTRATION = "NEW_REGISTRATION";
export const SEARCH_NEWREGISTRATION_FILTER = "SEARCH_NEWREGISTRATION_FILTER";
export const CUSTOMER_DIRECT_COMMENT = "CUSTOMER_DIRECT_COMMENT";
export const NEW_COMMENT = "NEW_COMMENT";

// Admin
export const ADMIN_INPROGRESS = "ADMIN_INPROGRESS";
export const ADMIN_INPROGRESS_ORDER = "ADMIN_INPROGRESS_ORDER";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const LOGOUT = "LOGOUT";
export const ALL_KEYWORDS = "ALL_KEYWORDS";
export const DELETE_KEYWORDS = "DELETE_KEYWORDS";
export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const ADD_GROUPS = "ADD_GROUPS";
export const SELECTED_PAGE = "SELECTED_PAGE";
export const PAGE_LOAD_INTO_LOCAL = "PAGE_LOAD_INTO_LOCAL";
export const ADD_ORDER = "ADD_ORDER";
export const CHANGE_PAGE_STATUS = "CHANGE_PAGE_STATUS";
export const EDIT_KEYWORDS = "EDIT_KEYWORDS";
export const EDIT_KEYWORDS_QTY = "EDIT_KEYWORDS_QTY";
export const SEND_FB_MESSAGE = "SEND_FB_MESSAGE";
export const GET_USER_ORDER = "GET_USER_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const EDIT_PAGE_DETAIL = "EDIT_PAGE_DETAIL";
export const CHECK_OUT = "CHECK_OUT";
export const IMPORT_ORDER = "IMPORT_ORDER";
export const SEARCH_ORDER = "SEARCH_ORDER";
export const ADD_COUPONS = "ADD_COUPONS";
export const EDIT_COUPON = "EDIT_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_COUPON = "GET_COUPON";
export const SEARCH_ORDER_FILTER = "SEARCH_ORDER_FILTER";
export const SEARCH_ORDER_ITEMS_FILTER = "SEARCH_ORDER_ITEMS_FILTER";
export const USER_ORDERS = "USER_ORDERS";
export const SEARCH_USER_DETAIL_FILTER = "SEARCH_USER_DETAIL_FILTER";
export const SELECT_TIME_SLOT = "SELECT_TIME_SLOT";
export const CONFIRM_TIME_SLOT = "CONFIRM_TIME_SLOT";
export const GET_COUPONS = "GET_COUPONS";
export const EDIT_ALL_KEYWORDS = "EDIT_ALL_KEYWORDS";
export const ADD_LUCKY_DRAW = "ADD_LUCKY_DRAW";
export const WINNER_LIST = "WINNER_LIST";
export const CREATE_CART = "CREATE_CART";
export const SEARCH_TRANSACTIONS_FILTER = "SEARCH_TRANSACTIONS_FILTER";
export const DELIVERY_SLOTS_PAGE_DETAIL = "DELIVERY_SLOTS_PAGE_DETAIL";
export const SEARCH_PREREGISTRATION_FILTER = "SEARCH_PREREGISTRATION_FILTER";
export const EDIT_REGISTRATION_USER = "EDIT_REGISTRATION_USER";
export const EDIT_REGISTRATION_USER_WITH_TOKEN =
  "EDIT_REGISTRATION_USER_WITH_TOKEN";
export const ADD_USER_DETAILS = "ADD_USER_DETAILS";
export const GET_DELIVERY_FEES = "GET_DELIVERY_FEES";
export const EDIT_DELIVERY_FEES = "EDIT_DELIVERY_FEES";
export const DELETE_DELIVERY_FEES = "DELETE_DELIVERY_FEES";
export const ADD_DELIVERY_FEES = "ADD_DELIVERY_FEES";
export const GET_SUPPLIERS_DATES = "GET_SUPPLIERS_DATES";
export const ADD_SUPPLIER_DELIVERY_DATE = "ADD_SUPPLIER_DELIVERY_DATE";
export const DELETE_SUPPLIER_DELIVERY_DATE = "DELETE_SUPPLIER_DELIVERY_DATE";
export const GET_USER_POINT_SUMMARY_REPORT = "GET_USER_POINT_SUMMARY_REPORT";
export const SEARCH_USER_POINT_SUMMARY_REPORT =
  "SEARCH_USER_POINT_SUMMARY_REPORT";
export const EDIT_GROUPS = "EDIT_GROUPS";
export const DELETE_GROUPS = "DELETE_GROUPS";
export const ALL_GROUPS = "ALL_GROUPS";
export const GET_LIVE_SHOW = "GET_LIVE_SHOW";
export const ADD_LIVE_ORDER = "ADD_LIVE_ORDER";
export const GET_ALL_SHOW = "GET_ALL_SHOW";
export const SEARCH_LIVE_ORDER_FILTER = "SEARCH_LIVE_ORDER_FILTER";
export const GET_ALL_LIVE_POST = "GET_ALL_LIVE_POST";
export const ADD_LIVE_SHOW_GROUP = "ADD_LIVE_SHOW_GROUP";
export const GET_ALL_LIVE_SHOW_GROUP = "GET_ALL_LIVE_SHOW_GROUP";
export const DELETE_LIVE_SHOW_GROUPS = "DELETE_LIVE_SHOW_GROUPS";
export const IMPORT_COUPON = "IMPORT_COUPON";
export const EDIT_USER = "EDIT_USER";
export const UPDATE_USER_DEDUC = "UPDATE_USER_DEDUC";
export const CONTRIES = [
  "Indonesia",
  "Philippines",
  "Vietnam",
  "Thailand",
  "Myanmar (Burma)",
  "Malaysia",
  "Cambodia",
  "Laos",
  "Singapore",
  "Timor-Leste (East Timor)",
  "Brunei Darussalam",
];

export const couponTypes = [
  { name: "Only For One User", value: 0, displayValue: "onlyForOneUser" },
  { name: "Once for All Users", value: 1, displayValue: "onceforAllUsers" },
  { name: "Unlimited", value: 2, displayValue: "unlimited" },
  { name: "Specific Campaign", value: 3, displayValue: "campaign" },
  // { name: "Specific Buyer Name", value: 4, displayValue: "userName" },
  { name: "Specific Buyer Email", value: 5, displayValue: "userEmail" },
  {
    name: "By Order Amount",
    value: 6,
    displayValue: "amount",
  },
  { name: "By Order Qty", value: 7, displayValue: "qty" },
  {
    name: "New Buyer Only",
    value: 8,
    displayValue: "newBuyer",
  },
  { name: "Keyword", value: 9, displayValue: "keyword" },
];

export const discountTypes = [
  {
    name: "By Amount",
    value: "AMOUNT",
  },
  {
    name: "By Percentage",
    value: "PERCENTAGE",
  },
];
export const ADD_IMAGES = "ADD_IMAGES";
export const DELETE_IMAGES = "DELETE_IMAGES";
export const ALL_IMAGES = "ALL_IMAGES";
