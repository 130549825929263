import axios from 'axios';
import ENVIRONMENT_VARIABLES from '../environment.config';
import {
    CUSTOMER_INPROGRESS,
    CUSTOMER_SUCCESS,
    CUSTOMER_ERROR,
    CUSTOMER_LOGOUT,
    CUSTOMER_LOGIN,
    CUSTOMER_UPDATE,
    CREATE_CART,
    CUSTOMER_ORDERS,
    NEW_REGISTRATION,
    CUSTOMER_DIRECT_COMMENT,
    NEW_COMMENT
} from '../constants/actionTypes';


export const loginUser = (accessToken) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            dispatch({
                type: CUSTOMER_LOGIN,
                data: {accessToken: accessToken}
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const getHomeCall = () => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            const token = "Bearer " + localStorage.getItem('fbAccessToken');

            let orderApi = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/customerOrderList/months/3"
            };

            let UserDetailApi = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/UserDetails/customerDetail"
            };

            let PageApi = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/FbPages/PageDetail"
            };

            let Transactions = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Transactions/CustomerTransaction"
            };

            axios.all([
                axios(orderApi),
                axios(UserDetailApi),
                axios(PageApi),
                axios(Transactions)
            ]).then(axios.spread((Orders, UserDetail, FbPageDetail, Transactions) => {
                dispatch({
                    type: CUSTOMER_SUCCESS,
                    Orders: Orders.data,
                    UserDetail: UserDetail.data,
                    FbPageDetail: FbPageDetail.data,
                    Transactions: Transactions.data
                });
            })).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const updateProfileDetail = (UserProfile) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            const token = "Bearer " + localStorage.getItem('fbAccessToken');
            let api = {
                method: 'PUT',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/UserDetails",
                data: UserProfile
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: CUSTOMER_UPDATE,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const FBLogout = () => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            localStorage.removeItem('fbAccessToken');
            localStorage.clear();
            dispatch({
                type: CUSTOMER_LOGOUT
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const CreateCart = () => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            const token = "Bearer " + localStorage.getItem('fbAccessToken');
            let api = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/createNewOrder",
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: CREATE_CART,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const GetUsersOrder = (months) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            const token = "Bearer " + localStorage.getItem('fbAccessToken');
            let api = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/customerOrderList/months/" + months
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: CUSTOMER_ORDERS,
                        data: response.data,
                        months: months
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const DirectComment = (comment) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            const token = "Bearer " + localStorage.getItem('fbAccessToken');
            let api = {
                method: 'GET',
                headers: {'Authorization': token},
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Orders/directComment/" + comment
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: CUSTOMER_DIRECT_COMMENT,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const NewBasicRegistration = (data) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            let api = {
                method: 'POST',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/RegistrationRequests/systemBasicRegistration",
                data: data
            };
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: NEW_REGISTRATION,
                        data: response.data
                    });
                }
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                }
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const NewProRegistration = (data) => {
    try {
        return (dispatch) => {
            dispatch({type: CUSTOMER_INPROGRESS});
            let bodyFormData = new FormData();
            let invalidFile = null;
            ["ProofOfIdentity", "PrimaryAuthorizedUserProofOfAddress", "CompanyIncorporationDocuments",
                "CapitalTableOrganizationalChart", "PleaseUploadYourBankStatement", "ProofOfCompanyAddress",
                "CEODetails", "CFODetails"].map((singleData) => {
                if (data[singleData] !== null && data[singleData] !== undefined && invalidFile === null) {
                    bodyFormData.append(singleData, data[singleData]);
                } else {
                    if (!invalidFile)
                        invalidFile = singleData;
                }
            });
            if (invalidFile === null) {
                [
                    "FullName", "CompanyName", "Email", "Phone", "Address", "CompanyURL", "FacebookURL",
                    "FacebookID", "IdType", "IdNumber", "IDIssuingCountry", "CountryOfRegistration", "OfficialBusinessName", "StreetAddress",
                    "City", "PostalCode", "YearsInBusiness", "BusinessIncorporationNumber", "TaxNumber", "BusinessPhoneNumber", "DescriptionOfBusiness",
                    "AnnualRevenue", "AverageTransaction", "CEOFullName", "CEOResidentialAddress", "Type", "RapydType",
                    "EntityType", "Industry", "ServicesProvided", "ServiceProvided", "CompanyInfo1",
                    "CompanyInfo2", "CryptoCurrencyActivities", "GamblingActivities", "StakeInTheCompany", "PEPs", "PastFive",
                    "CEOIDIssuingCountry", "CFOFullName", "CFOResidentialAddress", "CFOIDIssuingCountry", "DateOfBirth",
                    "CEODateOfBirth", "CFODateOfBirth", "RapydGrabPay", "RapydCreditCard", "RapydPaynow"
                ].map((singleData) => {
                    bodyFormData.append(singleData, data[singleData]);
                });

                let api = {
                    method: 'POST',
                    url: ENVIRONMENT_VARIABLES.Base_API_URL + "/RegistrationRequests/systemProRegistration",
                    data: bodyFormData,
                    config: {headers: {'Content-Type': 'multipart/form-data'}}
                };
                axios(api).then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: NEW_REGISTRATION,
                            data: response.data
                        });
                    }
                }).catch((error) => {
                    if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401 || error.response.status === 501)) {
                        dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.response.data.user_msg}});
                    } else {
                        dispatch({type: CUSTOMER_ERROR, data: {error_msg: error.message.toString()}});
                    }
                });
            } else {
                dispatch({type: CUSTOMER_ERROR, data: {error_msg: invalidFile + " file is required"}});
            }
        }
    } catch (error) {
        alert(error.message.toString());
    }
};

export const addNewComment = (data) => {
    return (dispatch) => {
        dispatch({
            type: NEW_COMMENT,
            data: data
        });
    }
};
