import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";

import * as adminAction from "../../../actions/authAction";
import { Card } from "@material-ui/core";

const ImportCoupons = (props) => {
  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
    importFailCoupon: state.adminReducer.importFailCoupon,
  }));

  const [coupon, setCoupon] = useState(null);
  const [couponMismatchError, setCouponMismatchError] = useState(null);

  //Todo error list orders
  useEffect(() => {
    setCouponMismatchError(adminReducers.importFailCoupon);
  }, [adminReducers.importFailCoupon]);

  const handleForce = (data, fileInfo) => {
    setCoupon(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const ImportCoupons = () => {
    props.actions.adminAction.importCouponData(
      coupon,
      adminReducers.selectedPage.FbPageId
    );
  };

  return (
    <Card className="d-flex p-2 align-items-center gap-20">
      <CSVReader
        cssClass="react-csv-input"
        onFileLoaded={handleForce}
        parserOptions={papaparseOptions}
      />

      {coupon != null && (
        <Button
          variant="contained"
          className="mt-2"
          color="primary"
          onClick={ImportCoupons}
        >
          {" "}
          Import Coupons{" "}
        </Button>
      )}

      {couponMismatchError !== null && couponMismatchError.length > 0 && (
        <div>
          <CSVLink data={couponMismatchError} className="my-5 mt-2">
            {" "}
            Fail to Import{" "}
          </CSVLink>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ImportCoupons);
