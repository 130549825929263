import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateTimePicker from "react-datetime-picker";
import {
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import { couponTypes, discountTypes } from "../../../constants/actionTypes";
import { useSelector } from "react-redux";
import "./style.css";

let moment = require("moment-timezone");

export const AddDialog = (props) => {
  const [keyword, setKeyword] = useState(props.editKeyword);
  const [group, setGroup] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState([]);
  const [Is_Link_Keyword, setIs_Link_Keyword] = useState(false);

  const adminReducers = useSelector((state) => ({
    allGroups: state.adminReducer.allGroups,
    allKeyWords: state.adminReducer.allKeyWords,
  }));

  useEffect(() => {
    if (adminReducers.allGroups.length > 0) {
      setKeyword({ ...keyword });
      setGroup(adminReducers.allGroups);
    }
  }, [adminReducers.allGroups]);

  useEffect(() => {
    if (adminReducers.allKeyWords.length > 0) {
      setKeyword({ ...keyword });
      setFilterKeyword(adminReducers.allKeyWords);
    }
  }, [adminReducers.allKeyWords]);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    let newKeywordDetail = { ...keyword };

    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleChangeCouponType = (event) => {
    const { name, value } = event.target;
    let newKeywordDetail = { ...keyword };

    if (value === 3) {
      newKeywordDetail.couponValue = group[0]._id;
    } else if (value === 4) {
      newKeywordDetail.couponValue = null;
    } else if (value === 5) {
      newKeywordDetail.couponValue = null;
    } else if (value === 6) {
      newKeywordDetail.couponValue = 0;
    } else if (value === 7) {
      newKeywordDetail.couponValue = 0;
    } else {
      newKeywordDetail.couponValue = null;
    }

    newKeywordDetail[name] = value;
    setKeyword(newKeywordDetail);
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    let newKeywordDetail = { ...keyword };

    if (value === 3) {
      newKeywordDetail.couponValue = group[0]._id;
    } else if (value === 4) {
      newKeywordDetail.couponValue = "";
    } else if (value === 5) {
      newKeywordDetail.couponValue = "";
    } else if (value === 6) {
      newKeywordDetail.couponValue = 0;
    } else if (value === 7) {
      newKeywordDetail.couponValue = 0;
    }
    if (event.target.value !== keyword.linkedKeywordId) {
      newKeywordDetail[name] = value;
      setKeyword(newKeywordDetail);
    }
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    let newKeywordDetail = { ...keyword };

    if (value === 3) {
      couponValue = group[0]._id;
    } else if (value === 4) {
      couponValue = "";
    } else if (value === 5) {
      couponValue = "";
    } else if (value === 6) {
      couponValue = 0;
    } else if (value === 7) {
      couponValue = 0;
    }

    if (event.target.value !== keyword.couponValue) {
      newKeywordDetail[name] = value;
      setKeyword(newKeywordDetail);
    }
  };

  const handleSubmitClose = (keyword) => {
    props.handleEditSubmitClose(keyword);
  };

  const onStartChange = (date) => {
    let keywordTmp = { ...keyword };
    keywordTmp.StartDate = date;
    setKeyword(keywordTmp);
  };

  const onEndChange = (date) => {
    let keywordTmp = { ...keyword };
    keywordTmp.EndDate = date;
    setKeyword(keywordTmp);
  };

  useEffect(() => {
    if (keyword) {
      //change DateTime
      let TmpKeyword = { ...keyword };
      TmpKeyword.StartDate = new Date(TmpKeyword.StartDate);
      TmpKeyword.EndDate = new Date(TmpKeyword.EndDate);
      setKeyword({ ...TmpKeyword });
      // TmpCouponType.map((singleCouponType) => {
      //   singleCouponType.checked = false;
      //   if (keyword.CouponType === singleCouponType.value) {
      //     singleCouponType.checked = true;
      //   }
      // });
    }
    // setCouponTypes(TmpCouponType);
  }, []);

  const handleChangeLink = () => {
    setIs_Link_Keyword(!Is_Link_Keyword);
    setKeyword({ ...keyword, Is_Link_Keyword: !keyword.Is_Link_Keyword });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <ValidatorForm
          onSubmit={() => handleSubmitClose(keyword)}
          autoComplete="off"
        >
          <DialogTitle
            className="position-sticky"
            style={{ top: "0", zIndex: "100", backgroundColor: "#fff" }}
            id="form-dialog-title"
          >
            Edit Coupon
          </DialogTitle>
          <DialogContent>
            <div className="row" style={{ gridRowGap: "20px" }}>
              <div className="col-sm-12">
                <TextValidator
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Discount Name"
                  name="DiscountName"
                  value={keyword.DiscountName}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>
              <div className="col-sm-12">
                <TextValidator
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Promo Code"
                  name="PromoCode"
                  value={keyword.PromoCode}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>

              <div className="col-sm-12">
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Discount Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Discount Type"
                    id="demo-simple-select"
                    name="DiscountType"
                    value={keyword.DiscountType}
                    onChange={handleChange1}
                  >
                    {discountTypes.map((singleSupplier) => (
                      <MenuItem value={singleSupplier.value}>
                        {singleSupplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-12">
                <TextValidator
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Discount"
                  name="DiscountAmount"
                  type="number"
                  value={keyword.DiscountAmount}
                  validators={["required"]}
                  onChange={handleChange}
                  errorMessages={["this field is required"]}
                />
              </div>

              <div className="col-sm-12">
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Add Limitations
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Add Limitations"
                    id="demo-simple-select"
                    name="CouponType"
                    value={keyword.CouponType}
                    onChange={handleChangeCouponType}
                  >
                    {couponTypes.map((singleSupplier) => (
                      <MenuItem value={singleSupplier.value}>
                        {singleSupplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {keyword.CouponType === 3 && (
                <div className="col-sm-12">
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Campaign
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="coupon Value"
                      id="demo-simple-select"
                      name="couponValue"
                      value={keyword.couponValue}
                      onChange={handleChange1}
                    >
                      {group.map((singleSupplier) => (
                        <MenuItem value={singleSupplier._id}>
                          {singleSupplier.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {keyword.CouponType === 4 && (
                <div className="col-sm-12">
                  <TextValidator
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="User Name"
                    name="couponValue"
                    type="text"
                    value={keyword.couponValue}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                  />
                </div>
              )}
              {keyword.CouponType === 5 && (
                <div className="col-sm-12">
                  <TextValidator
                    size="small"
                    fullWidth
                    variant="outlined"
                    // className="flex-grow-1"
                    label="User Email"
                    name="couponValue"
                    type="email"
                    value={keyword.couponValue}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                  />
                </div>
              )}
              {keyword.CouponType === 6 && (
                <div className="col-sm-12">
                  <TextValidator
                    size="small"
                    fullWidth
                    variant="outlined"
                    // className="flex-grow-1"
                    label="amount"
                    name="couponValue"
                    type="number"
                    value={keyword.couponValue}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                  />
                </div>
              )}
              {keyword.CouponType === 7 && (
                <div className="col-sm-12">
                  <TextValidator
                    size="small"
                    fullWidth
                    variant="outlined"
                    // className="flex-grow-1"
                    label="Qty"
                    name="couponValue"
                    type="number"
                    value={keyword.couponValue}
                    validators={["required"]}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                  />
                </div>
              )}
              <br />

              {keyword.CouponType === 9 && (
                <div className="col-sm-12">
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Keyword
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="coupon Value"
                      id="demo-simple-select"
                      name="couponValue"
                      value={keyword.couponValue}
                      onChange={handleChange1}
                    >
                      {filterKeyword.map((singleSupplier) => (
                        <MenuItem value={singleSupplier._id}>
                          {singleSupplier.keyword}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <div className="col-sm-12">
                {keyword.CouponType === 9 && (
                  <div className="d-flex align-items-center">
                    <span> Want To Link Parent Keyword </span> &nbsp;
                    <Switch
                      checked={keyword.Is_Link_Keyword}
                      onChange={handleChangeLink}
                      name="PageStatus"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                )}
              </div>

              {keyword.Is_Link_Keyword === true && keyword.CouponType === 9 && (
                <div className="col-sm-12">
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Parent Keyword
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="linkedKeywordId"
                      id="demo-simple-select"
                      name="linkedKeywordId"
                      value={keyword.linkedKeywordId}
                      onChange={handleChange2}
                    >
                      {filterKeyword.map((singleSupplier) => (
                        <MenuItem value={singleSupplier._id}>
                          {singleSupplier.keyword}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <div className="col-sm-6">
                <div className="d-flex flex-column">
                  <label>Start Date</label>
                  <DateTimePicker
                    onChange={onStartChange}
                    value={keyword.StartDate}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="d-flex flex-column">
                  <label>End Date</label>
                  <DateTimePicker
                    onChange={onEndChange}
                    value={keyword.EndDate}
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};
export default AddDialog;
