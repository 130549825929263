import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

import "./style.css";
import * as adminAction from "../../../actions/authAction";

let moment = require("moment-timezone");

const SearchBar = (props) => {
  const startDayDateTime = moment()
    .tz("Asia/Singapore")
    .startOf("day")
    .format();
  const endDayDateTime = moment().tz("Asia/Singapore").endOf("day").format();
  const [search, setSearch] = useState({
    fromDate: new Date(startDayDateTime),
    toDate: new Date(endDayDateTime),
  });

  const adminReducers = useSelector((state) => ({
    selectedPage: state.adminReducer.selectedPage,
  }));

  useEffect(() => {
    if (
      adminReducers.selectedPage &&
      adminReducers.selectedPage !== null &&
      adminReducers.selectedPage !== undefined
    )
      props.actions.adminAction.getSearchTransactionsFilter(
        {},
        adminReducers.selectedPage.FbPageId.trim()
      );
  }, [adminReducers.selectedPage]);

  const onChangeStartTime = (date) => {
    const commonData = { ...search };
    commonData.fromDate = date;
    return setSearch(commonData);
  };

  const onChangeEndTime = (date) => {
    const commonData = { ...search };
    commonData.toDate = date;
    return setSearch(commonData);
  };

  const searchButton = () => {
    props.actions.adminAction.getSearchTransactionsFilter(
      search,
      adminReducers.selectedPage.FbPageId.trim()
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="d-flex align-items-baseline justify-content-between filter">
        <KeyboardDatePicker
          className="filter-box m-0"
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
          format="dd/MM/yyyy"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          className="filter-box m-0"
          margin="normal"
          id="time-picker"
          label="Start Time"
          value={search.fromDate}
          onChange={onChangeStartTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        <KeyboardDatePicker
          className="filter-box m-0"
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
          format="dd/MM/yyyy"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          className="filter-box m-0"
          margin="normal"
          id="time-picker"
          label="End Time"
          value={search.toDate}
          onChange={onChangeEndTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        <Button variant="contained" color="primary" onClick={searchButton}>
          <SearchIcon />
        </Button>
        <div className="d-flex align-items-center" style={{ width: "260px" }}>
          <label> Only Referral's Records </label>
          <Switch
            checked={props.filter}
            onChange={props.changeFilter}
            name="PageStatus"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(adminAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SearchBar);
